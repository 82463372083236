import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
@Component({
  selector: 'app-group-mr-list',
  templateUrl: './group-mr-list.component.html',
  styleUrls: ['./group-mr-list.component.css']
})
export class GroupMrListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;

  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
  }

  ngOnInit() {

  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MR_LIST + '?includeGroup=true').subscribe(res => {
      this.resData = new AppResponse(res);
      // console.log("resdata:"+this.resData);
      setTimeout(() => {
        $('#mrListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,// this line added by aishwaryaa for hb-542
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }

}
