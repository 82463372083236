import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-group-config-list',
  templateUrl: './group-config-list.component.html',
  styleUrls: ['./group-config-list.component.css'],
  providers: [NgxSpinnerService]
})
export class GroupConfigListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  groupId: string;
  editGroupConfigForm: FormGroup;
  editLogoForm: FormGroup;
  fileUploadList: string[] = [];
  imageSrc: string;
  configKey:string;
  show:boolean;
  allowNumber:boolean;
  requireAmount:boolean;
  isMinPer : boolean = false;
  invalidAmt : boolean = false;

  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private modalService: NgbModal, private commonService: CommonService,private spinner: NgxSpinnerService) {
    this.groupId = this.tokenService.getGroupId();
  }

  ngOnInit() {

    this.editGroupConfigForm = this.fb.group({
      groupConfigId: [''],
      groupMasterId: [''],
      configKey: [''],
      configValue: ['', Validators.required],
    });

    this.editLogoForm = this.fb.group({
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
    });



  }
  ngAfterViewInit(): void {

    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GROUP_CONFIG).subscribe(res => {

      this.resData = new AppResponse(res);

      setTimeout(() => {
        $('#groupConfigsDatatable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,//this line added by aishwaryaa for hb-542
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line added by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 2,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }

  openModal(targetModal, data) {
    this.show =false;
    this.isMinPer = false;
    this.invalidAmt = false;
    // console.log("this.configKey12411 ");


    this.configKey=data.configKey;

    if((this.configKey  == 'Publish To Board' || this.configKey== 'Publish To Home Owners' )){
      this.show = true;

     }else{
      this.show = false;
     }
    //  console.log("this.configKey1234 "+this.configKey );
    //  console.log("this.configKey12411 "+this.show );


     if((this.configKey  == 'LateFeeCharges' || this.configKey== 'crfine' || this.configKey== 'MinimumAmountInPercentage')){
      this.allowNumber = true;
     }else{
      this.allowNumber = false;
     }
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.editGroupConfigForm.patchValue({
      groupConfigId: data.groupConfigId,
      groupMasterId: data.groupMasterId,
      configKey: data.configKey,
      configValue: data.configValue,
    });

    if(this.configKey== 'MinimumAmountInPercentage'){
      this.isMinPer = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.editGroupConfigForm.controls; }

  get form() { return this.editLogoForm.controls; }

  // URL
  private groupsConfigAdd = environment.BASE_URL + ApplicationURIConstants.GROUP_ADD_GROUP_CONFIG + '?edit=true';

  submitted = false;

  onSubmit() {
    this.submitted = true;
    // console.log(this.editGroupConfigForm.value.configKey);
    // console.log("ISVALIDINT"+isNaN(parseInt(this.editGroupConfigForm.value.configValue)));
    // console.log("===="+this.toFixedToDecimal(this.editGroupConfigForm.value.configValue));
    if((this.editGroupConfigForm.value.configKey == 'MinimumAmountInPercentage' && (parseInt(this.editGroupConfigForm.value.configValue) > 100 || parseInt(this.editGroupConfigForm.value.configValue) <= 0)) 
    || ((this.configKey  == 'LateFeeCharges' || this.configKey== 'crfine') && (parseInt(this.editGroupConfigForm.value.configValue) > 999999 || parseInt(this.editGroupConfigForm.value.configValue) <= 0))
    || this.checkForTheExponentialValue(this.editGroupConfigForm.value.configKey,this.editGroupConfigForm.value.configValue)){
      // console.log("========")
      this.invalidAmt = true;
      return;
    }
    if (this.editGroupConfigForm.invalid) {
      console.log("invalid")
      return;
    } else {
      document.getElementById('closeModal').click()
      this.spinner.show();
      this.httpService.save(this.editGroupConfigForm.value, this.groupsConfigAdd)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            // $('#groupConfigsDatatable').DataTable().clear().destroy();

            this.submitted = false;
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.resData.message);
            
            this.spinner.hide();
           window.location.reload();
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
    // this.modalService.dismissAll();
  }

  private gaEditLogoUrl = environment.BASE_URL + ApplicationURIConstants.GROUP_CONFIG_UPLOAD_LOGO;

  // file upload data manupulated in on change evenet
  onFileSelect(event) {



    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }

    // var i = $(this).prev('label').clone();
    var fileName = event.target.files[0].name;
    // $(this).prev('label').text(fileName);

    var i = $('#uploadImage').prev('label').clone();
    $('#uploadImage').prev('label').text(fileName);

    var originalFileName = fileName.toLowerCase();
    var extension = originalFileName.substring(originalFileName.lastIndexOf('.') + 1);
    if (extension != 'jpeg' && extension != 'jpg' && extension != 'png' && extension != 'tiff' && extension != 'jfif') {

      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please provide image in JPG/JPEG/PNG/TIFF/JFIF format only.");
    } else {
      if (event.target.files[0].size > 2097152) // 10 mb for bytes.
      {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "File size must be less than 2 MB.");
      } else {

        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);

          reader.onload = () => {

            this.imageSrc = reader.result as string;

            $("#imgDiv").remove();
            $('#imageSrcDiv').append('<img src=" '+this.imageSrc +'" id="imgDiv">');
            $('#imgDiv').css("height", "200px");
            this.editLogoForm.patchValue({
              fileSource: reader.result
            });
          };
        }
      }
    }
  }
  onlogoSubmit() {
    this.submitted = true;
    if (this.editLogoForm.invalid) {
      console.log('invalid');
      return;
    } else {
      var formData = new FormData();

      // $('#imgDiv').removeAttr('src');

      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("file", this.fileUploadList[i]);
      }

      // http service method call
      this.httpService.save(formData, this.gaEditLogoUrl)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.editLogoForm.reset();
              $('#groupConfigsDatatable').DataTable().clear().destroy();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
                this.ngAfterViewInit();

                $('label[id*=uploadImageId]').html('<i class="fas fa-upload"></i> Choose File');

                 $("#imgDiv").remove();

            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
          }
        });
    }


  }


  onKeyUpForAmount(event: any) {  
    // console.log("==="+event.target.value);
    if( event.target.value != '' && (event.target.value > 999999 || event.target.value < 1 )){
       this.invalidAmt = true;
      //  console.log("if");
    }else{
       this.invalidAmt = false;
      //  console.log("else");
    }
  }

  onKeyUpForPer(event: any) {  
    if(event.target.value != '' && (event.target.value > 100 || event.target.value < 1) ){
       this.invalidAmt = true;
    }else{
       this.invalidAmt = false;
     
    }
  }

  toFixedToDecimal(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      // console.log("?????"+e);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          // console.log("++++"+x);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }


  checkForTheExponentialValue(key, value){
    if(key == 'MinimumAmountInPercentage' && (this.toFixedToDecimal(value) > 100 || this.toFixedToDecimal(value) < 1 ) ){
       return true;
    }else if((key  == 'LateFeeCharges' || key == 'crfine')&& (this.toFixedToDecimal(value) > 999999 || this.toFixedToDecimal(value) < 1 ) ){
      return true;
   }
   return false;
  }
}
