import { Component, OnInit } from '@angular/core';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { first, timeoutWith } from 'rxjs/operators';
import { timer } from 'rxjs';
import { environment } from 'projects/group-portal/src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { }

  userRegistrationForm: FormGroup;

  groupList = [];

  addreses: any = ['100', '101', '102', '103']

  ngOnInit() {
    $("#addressLine1").hide();
    $("#addressLine1Label").hide();


    var nowY = new Date().getFullYear(),
      options = "";

    // $("#vehicleYear").append(options);
    // this.httpService.get(ApplicationURIConstants.BASE_URL + ApplicationURIConstants.GROUP_LIST).subscribe(res => {
    //   this.resData = new AppResponse(res);
    //   if (this.resData.status == 200) {
    //     this.groupList = this.resData.data;
    //   } else if (this.resData.status === 403) {
    //     this.router.navigate(['home']);
    //   }
    // });



    // ("#firstName").focus();
    this.userRegistrationForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "gender": ['', Validators.required],
      "contact": ['', [Validators.required, Validators.pattern(AppRegExConstants.CONTACT_NUMBER)]],
      "addressLine1": ['', Validators.required],
      "city": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "state": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "zipCode": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
      "email": ['', [ Validators.pattern(AppRegExConstants.EMAIL)]],
    });

    $('#email').keyup(function() {
      var value = $(this).val();
      var exp = new RegExp(AppRegExConstants.EMAIL);

        if(exp.test(value)){
          $("#addressLine1Label").show();
          $("#addressLine1").show();
        } else {
          $("#addressLine1Label").hide();
          $("#addressLine1").hide();
        }
  });​
  }

  // convenience getter for easy access to form fields
  get f() { return this.userRegistrationForm.controls; }

  // URL
  private users = environment.BASE_URL + ApplicationURIConstants.USER_REGISTRATION;

  resData: AppResponse;

  submitted = false;

  onSubmit(formData: any, formDirective: FormGroupDirective): void {

    this.submitted = true;
    if (this.userRegistrationForm.invalid) {
      return;
    } else {

      this.httpService.save(this.userRegistrationForm.value, this.users)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (this.resData.status == 200) {
            if (this.resData.message == ApplicationConstants.SUCCESS_LABLE_ADD_USER) {
              this.submitted = false;
              formDirective.resetForm();
              this.userRegistrationForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, ApplicationConstants.SUCCESS_LABLE_ADD_USER);
              $('html, body').animate({ scrollTop: (0) }, 2000);
              setTimeout(() => {
                this.router.navigate(['home']);
              }, 15000);  //15s
            }
          }
          else if (this.resData.status == 401 || this.resData.status == 403) {
            this.router.navigate(['home']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }
  clickForReset() {
    $('html, body').animate({ scrollTop: (0) }, 1000);
  }

}
