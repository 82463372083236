import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  providers: [NgxSpinnerService]
})
export class EditProfileComponent implements OnInit {

  responseData: AppResponse;
  requestData: AppResponse;
  userEditForm: FormGroup;
  tokenData: String[] = [];
  intlObj: any;
  contactNum: any;
  isError: boolean = false;
  countryCode: any;
  countryCodeLength: any;
  flag: boolean;

  contactshow: boolean = true;
  userprofileinfoid: number = localStorage.getItem[""];
  resetFlag: boolean = false; //HB-426 Hevisa Patel 28-01-2021 
  @ViewChild('myInput', { static: true }) myInput: ElementRef;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private spinner: NgxSpinnerService) {
  }


  // @ViewChild('phoneInput', {static: true}) phoneInput: ElementRef; 

  ngOnInit() {



    this.userEditForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      // "contact": ['', [Validators.required]],
      "addressLine1": ['', Validators.required],
      "groupName": ['', [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
      "groupRegNumber": ['', Validators.required],
      "totalNumberOfHo": ['', Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      "userProfileInfoId": ['', Validators.required],
      "groupMasterId": ['', Validators.required]
    });
    this.userEditForm.controls["firstName"].disable();
    // this.userEditForm.controls["addressForCommunication"].disable();
    this.userEditForm.controls["lastName"].disable();
    this.userEditForm.controls["groupName"].disable();
    this.userEditForm.controls["groupRegNumber"].disable();
    this.userEditForm.controls["totalNumberOfHo"].disable();
    this.userEditForm.controls["groupMasterId"].disable();
    this.userEditForm.controls["userProfileInfoId"].disable();
    this.userEditForm.controls["addressLine1"].disable();


  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.userEditForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }


  ngAfterViewInit() {
    this.spinner.show();
    // console.log("=======>>>> ngAfterViewInit <<<<==========");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_USER_PROFILE).subscribe(res => {
      // console.log(JSON.stringify(res));
      this.responseData = new AppResponse(res);
      this.spinner.hide();
      if (res.status == 200) {
        this.fillTextfeildData();
        this.intlObj.setNumber(this.responseData.data.contact);
        this.resetFlag = true; //HB-426 Hevisa Patel 28-01-2021 
      } else if (res.status === 401) {
        //401 token related issue
        this.router.navigate(['login']);
      } else if (res.status == 403) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
        this.router.navigate(['home']);
      } else {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
      }

    });
  }
  enabledata(): void {
    this.userEditForm.controls["firstName"].enable();
    this.userEditForm.controls["lastName"].enable();
    this.userEditForm.controls["groupName"].enable();
    this.userEditForm.controls["groupRegNumber"].enable();
    this.userEditForm.controls["totalNumberOfHo"].enable();
    this.userEditForm.controls["groupMasterId"].enable();
    this.userEditForm.controls["userProfileInfoId"].enable();
    this.userEditForm.controls["addressLine1"].enable();
    this.contactshow = false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.userEditForm.controls; }

  submitted = false;

  private editUserProfile = environment.BASE_URL + ApplicationURIConstants.EDIT_USER_PROFILE;

  onSubmit() {
    console.log(this.isError);
    this.spinner.show();
    this.submitted = true;


    if(!this.isError){
      this.spinner.hide();
      return;
    }
    if (this.userEditForm.invalid) {
      console.log("----->> EditUser Invalid <<------");
console.log(this.findInvalidControls());

      // this.userEditForm.controls['contact'].setValue( this.userEditForm.value.contact);
      this.spinner.hide();
      return;
    } else {
      console.log("----->> EditUser Valid <<------")
      // console.log(JSON.stringify(this.userEditForm.value));
      var formData = new FormData();
      formData.append("contact", this.intlObj.getNumber());
      formData.append("userProfileInfoId", this.userEditForm.controls['userProfileInfoId'].value);
      formData.append("firstName", this.userEditForm.controls['firstName'].value);
      formData.append("lastName", this.userEditForm.controls['lastName'].value);
      formData.append("addressLine1", this.userEditForm.controls['addressLine1'].value);
      formData.append("groupName", this.userEditForm.controls['groupName'].value.trim());
      formData.append("groupRegNumber", this.userEditForm.controls['groupRegNumber'].value);
      formData.append("groupMasterId", this.userEditForm.controls['groupMasterId'].value);
      formData.append("totalNumberOfHo", this.userEditForm.controls['totalNumberOfHo'].value);

      console.log("----->> EditUser Valid <<------")
      // console.log("-------" + JSON.stringify(formData));
      this.httpService.save(formData, this.editUserProfile)
        .subscribe((resp) => {
          this.requestData = new AppResponse(resp);
          this.spinner.hide();
          if (this.requestData.status == 200) {
            this.tokenData = JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL));
            var saPortal = {
              session: []
            };
            saPortal.session.push({
              [ApplicationConstants.TOKEN_KEY]: this.tokenData[0][ApplicationConstants.TOKEN_KEY],
              [ApplicationConstants.USER_PROFILE_INFO_ID_KEY]: this.tokenData[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY],
              [ApplicationConstants.EMAIL_KEY]: this.tokenData[0][ApplicationConstants.EMAIL_KEY],
              [ApplicationConstants.FULL_NAME_KEY]: this.userEditForm.controls['firstName'].value + " " + this.userEditForm.controls['lastName'].value,
              [ApplicationConstants.GROUP_ID_KEY]: this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
              [ApplicationConstants.URL_TOKENS_LIST_KEY]: this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
              [ApplicationConstants.GROUP_NAME]: this.userEditForm.controls['groupName'].value,
              [ApplicationConstants.AUTHORITIES_KEY]: this.tokenData[0][ApplicationConstants.AUTHORITIES_KEY]
            });
            window.localStorage.setItem(ApplicationConstants.GROUP_PORTAL, JSON.stringify(saPortal.session));
            this.submitted = false;
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
            setTimeout(() => {
              this.router.navigate(['home']);
            }, 5000);
          } else {
            console.log(this.requestData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
          }
        });
    }
  }

  resetData(): void {
    this.ngAfterViewInit();
  }

  fillTextfeildData() {
    if (this.responseData.data != null) {
      // console.log(this.responseData.data);
      //modified by aishwaryaa for hb-813 17/02/2022
		// this.intlObj.setNumber(this.responseData.data.contact);
	
    var cont = this.intlObj.getNumber();
      //HB-426 Hevisa Patel 28-01-2021 Start
      $("#phoneInput").val();
      //code end 
      this.myInput.nativeElement.blur();
      //HB-426 Hevisa Patel 28-01-2021 End

      // console.log("---"+this.countryCode);
      // console.log("[[]]]]"+(this.responseData.data.contact).substr(3) + "" +(this.countryCode).length);
      //  var cont;
      //  if(typeof(this.countryCode) !== 'undefined'){
      //   console.log("---if");
      //   cont = (this.responseData.data.contact).substr(((this.countryCode).length + 1));

      //  }
      //  else
      //   cont = (this.responseData.data.contact).substr(2);


      this.userEditForm = this.formBuilder.group({
        "firstName": [this.responseData.data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "lastName": [this.responseData.data.lastName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        //"contact": [cont,[Validators.required]],
        "addressLine1": [this.responseData.data.addressLine1, Validators.required],
        "groupName": [this.responseData.data.name, [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
        "groupRegNumber": [this.responseData.data.groupregistrationnum, [Validators.required]],
        "totalNumberOfHo": [this.responseData.data.registeredhousecount, [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
        "userProfileInfoId": [this.responseData.data.userprofileinfoid, [Validators.required]],
        "groupMasterId": [this.responseData.data.groupmasterid, Validators.required],
      });
      this.intlObj.setNumber(this.responseData.data.contact);

      $("#phoneInput").focus();
      this.myInput.nativeElement.blur();
      this.resetFlag = true;
      this.isError=true;
      // this.contactNum = this.responseData.data.contact;
   
     
    }


    // const phone = '+917878747416'
    // this.userEditForm.setValue({'contact': phone});
    // this.phoneInput.nativeElement.dispatchEvent(
    //   new KeyboardEvent('keyup', { bubbles: true })
    // );




    //   $(document).on('keyup', "#phoneInput input[type='text']",function () {
    //     console.log("onkeyup");
    //     new KeyboardEvent('keyup', { bubbles: true })
    // });

  }

  onKeyup(event) {
    // console.log(event)
  }

  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
    this.countryCode = obj.dialCode;
  }

  // code modified by aishwaryaa for HB-852
  onCountryChange(country: any) {
    // console.log("=======" + country.dialCode);
    this.countryCode = country.dialCode;
    if (this.flag)
      this.intlObj.setNumber('');
    else
      this.flag = true;

  }
// code end 
  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
  }

  checkForError(obj) {
    this.resetFlag = false;
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else
        return false;
    }
    return true;

  }

  // code added by gopal for adding changed password button start on 06/12/2021
  changePassword() {
    this.router.navigate(['change-password', JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]],{skipLocationChange: true});
  }
  // code added by gopal for adding changed password button end


}
