import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AppResponse } from 'src/app/model/AppResponse';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { Crisp } from "crisp-sdk-web";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public appResponse: AppResponse;
  public totalOutstendingOfHo = 0;
  public totalPaidOfHo = 0 ;
  public totalPendingOfHovalue:any;
  public totalPaidOfHovalue:any;

  ngAfterViewInit(): void {
  }

  // tslint:disable-next-line: max-line-length
  constructor(private httpService: HttpTransactionService, private tokenService: TokenStorageService, private router: Router ,private commonService: CommonService) {


  }

  ngOnInit() {

  

    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.HOME).subscribe(res => {

      this.appResponse = new AppResponse(res);
    
      console.log(this.appResponse);
      
      
      if(this.appResponse.data.totalPendingOfHo !=null){
        this.totalPendingOfHovalue=this.appResponse.data.totalPendingOfHo;
        this.totalPendingOfHovalue = this.totalPendingOfHovalue.toFixed(2);
     
        }
        if(this.appResponse.data.totalPaidOfHo !=null){
          this.totalPaidOfHovalue=this.appResponse.data.totalPaidOfHo;
          this.totalPaidOfHovalue = this.totalPaidOfHovalue.toFixed(2);
          }
      
      // console.log(res.status)

      if (res.status === 401) {
        // 401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        // 403 URL not accessible
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.appResponse.errors}`);
      } else if (res.status === 200) {

        this.mrPieChartData = [this.appResponse.data.openMr, this.appResponse.data.resolvedMr];
        this.crPieChartData = [this.appResponse.data.openCr, this.appResponse.data.resolvedCr];

        this.mrPieChartData = [this.appResponse.data.apprvdMr, this.appResponse.data.pendingMr, this.appResponse.data.ackMr, this.appResponse.data.pendAppMr, this.appResponse.data.rejMr];
        this.crPieChartData = [this.appResponse.data.pendCr, this.appResponse.data.ackCr, this.appResponse.data.reslvCr, this.appResponse.data.clsCr];
        
        // console.log(this.appResponse)

        this.totalOutstendingOfHo = this.appResponse.data.totalPendingOfHo;
        this.totalPaidOfHo = this.appResponse.data.totalPaidOfHo;
      
        //this.totalMr = Math.abs(this.appResponse.data.totalMr);
        //this.totalCr = Math.abs(this.appResponse.data.totalCr);
      }
    });
  }

 // Pie
  // Maintenance request Start
  public mrPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

  public mrPieChartLabels: Label[] = ['Approved', 'Pending', 'Acknowledged ', 'Pending Approval ', 'Rejected'];
  public mrPieChartData: number[] = [0, 0];//=  [this.appResponse.data.acceptedMr, this.appResponse.data.rejectedMr];
  public mrPieChartType: ChartType = 'pie';
  public mrPieChartLegend = true;
  public mrPieChartPlugins = [pluginDataLabels];
  public mrPieChartColors = [
    {
      // tslint:disable-next-line: max-line-length
      backgroundColor: ['rgba(196, 154, 199, 1)', 'rgba(239, 168, 200, 1)', 'rgba(244, 234, 143, 1)', 'rgba(182, 226, 132, 1)', 'rgba(108, 217, 169, 1)', 'rgba(93, 185, 207, 1)'],
    },
  ];
  // Maintenance request End

  // Compliance request Start
  // Pie
  public crPieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: () => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          const label = null;
          return label;
        },
      },
    }
  };

  public crPieChartLabels: Label[] = ['Pending', 'Acknowledged', 'Resolved', 'Closed'];
  public crPieChartData: number[] = [0,0];//= [this.appResponse.data.acceptedMr, this.appResponse.data.resolvedCr];
  public crPieChartType: ChartType = 'pie';
  public crPieChartLegend = true;
  public crPieChartPlugins = [pluginDataLabels];
  public crPieChartColors = [
    {
      backgroundColor: ['rgba(230, 246, 157, 1)','rgba(170, 222, 167, 1)', 'rgba(100, 194, 166, 1)', 'rgba(45, 135, 187, 1)'],
    },
  ];
  // Compliance request End

}

