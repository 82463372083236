import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
var verification = new FormData();
@Component({
  selector: 'app-phoneverify',
  templateUrl: './phoneverify.component.html',
  styleUrls: ['./phoneverify.component.css']
})
export class PhoneverifyComponent implements OnInit {

  pnverify:FormGroup;
  appResponse: AppResponse;
  resData: AppResponse;
  phoneverify;
  buttonCheck:boolean=false;
  constructor( private formBuilder: FormBuilder, private router: Router, private applicationUtils : ApplicationUtils, private authService: AuthService, private tokenService: TokenStorageService, private route: ActivatedRoute ,private http:HttpClient,private httpService: HttpTransactionService,private commonService: CommonService) { }

  ngOnInit() {
    this.pnverify = this.formBuilder.group({
      "verify": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]]
    });
   this.phoneverify= JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0]["isPhoneVerified"];
   if(this.phoneverify ==1){
    this.router.navigate(['home']);
   }

}
  
getotp(){
  this.buttonCheck =true;
  this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_OTP).subscribe(data => {
    // console.log(data);

    if(data.status==200){
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${data.message}`);
      // this.pnverify.controls['verify'].setValue(data.data);
    }
    
  })
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else
       return false;
  }
  return true;

}
  onSubmit() {

    if(!this.pnverify.controls['verify'].value.trim()){
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Provide OTP");
      this.pnverify.controls['verify'].setValue("");
      return;
    }

    if(!this.pnverify.controls['verify'].value){
      this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Provide OTP");
      return;
    }

    verification.delete("otp");
   
    
    verification.append("otp",this.pnverify.controls['verify'].value);
  

    this.httpService.save(verification,environment.BASE_URL + ApplicationURIConstants.VERIFY_OTP).subscribe(data => {
      // console.log(data);
      if(data.status ==200 ){
        this.router.navigate(['home']);
      }    else{
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${data.message}`);
      }
    }
      );

      // console.log(this.pnverify.controls['verify'].value);
      

      // this.spinner.hide();
      // console.log(data);
      // this.resData = new AppResponse(data);
      // console.log(this.resData);

      // if (data.status === 401) {
      //   this.tokenService.clearSession();
      //   this.router.navigate(['login']);
      // } else if (data.status === 403) {
      //   //  this.router.navigate(['home']);
      // } else if (data.status === 200) {

      //   this.appResponse = new AppResponse(data);
      //   console.log(JSON.stringify(this.appResponse));
      //   if (this.appResponse) {
      //     this.userPayForm.controls['amount'].setValue(this.appResponse.data.price);
      //   }
      // }
    // });

  
    

  }
  // code added by aishwaryaa for HB-906
  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }

}
