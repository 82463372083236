// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    env:"",
    refreshurl:"https://group.hybridhoa.com/#/group-portal/subscription?payment=success",
    stripekey:"pk_live_51KSxn9H0DoI2v68DC8mSnyIeAo77IFvmcOuA43JcjC874ZG15up2CbaurxZ2CsRx5odrjckjCYPh0xMlrxkMqhto00QjPpL9jp",

    BASE_URL: 'https://api.hybridhoa.com/hhoa-group-portal/group-portal',// it always should bre [dev server ip] :8081/hhoa-group-portal/group-portal and production should be true

    // Router Authentication
    WHITE_LISTED_DOMAINS: 'https://api.hybridhoa.com/hhoa-group-portal/group-portal',
    BLACK_LISTED_ROUTERS: 'https://api.hybridhoa.com/hhoa-group-portal/group-portal/signin',
    ReCaptcha:'6LcAkS0hAAAAAGP74Ew5fU4l1xwWkfPi8k9abiEP',
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
