//  Author of this file is Gopal 
import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
// import { environment } from 'projects/user-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/group-portal/src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-audit-view-mr',
  templateUrl: './audit-view-mr.component.html',
  styleUrls: ['./audit-view-mr.component.css'],
  // Datapipe added by aishwaryaa for hb-823 21/02/2022
  providers: [NgxSpinnerService,DatePipe]
})
export class AuditViewMrComponent implements OnInit {


  maintenanceRequestId: string;
  // maintenanceRequest: MaintenanceRequest;
  appResponse: AppResponse;
  resData: AppResponse;
  requestDocumentId: string;
  documentName: string;
  fileNameContentType: string;
  fileNameExtension: string;
  reviewResData : AppResponse;
  map = new Map();
  isGULoggedIn:string;
isBMLoggedIn: String;
isArcLoggedIn: String;
isHOLoggedIn: String;
isMCLoggedIn: String;

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService, 
    private router: Router, 
    private tokenService: TokenStorageService ,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST + this.maintenanceRequestId).subscribe(data => {
      // console.log( data );
      if (data.status == 200) {
        this.appResponse = new AppResponse(data);
       

        for(var i=0; i<this.appResponse.data.documentName.length; i++){
          // console.log("docName:"+this.appResponse.data.documentName[i])
          this.map.set(this.appResponse.data.documentName[i].substr(14),this.appResponse.data.documentName[i]);
         }

      }else if (data.status === 401) {
        this.spinner.hide();
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        this.spinner.hide();
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.LIST_MR_REVIEW + this.maintenanceRequestId +'?getAssignee=false').subscribe(res => {
      this.spinner.hide();
      // console.log(res)
      if (res.status === 200) {
        this.reviewResData = new AppResponse(res);
        // console.log( this.reviewResData );
      }else if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        //this.router.navigate(['home']);
      }
    });

    if(localStorage.getItem("isGULoggedIn") == "true"){

      this.isGULoggedIn = 'true';
      } else if(localStorage.getItem("isBMLoggedIn") == "true"){
      
      this.isBMLoggedIn = 'true';
      } else if(localStorage.getItem("isArcLoggedIn") == "true"){
      
      this.isArcLoggedIn = 'true';
      } else if(localStorage.getItem("isHOLoggedIn") == "true" ){
      
      this.isHOLoggedIn = 'true';
      } else if(localStorage.getItem("isMCLoggedIn") == "true" ){
      
      this.isMCLoggedIn = 'true';
      }

  }
  // get the document of byte array.
  getDocument(documentName: string) {
       this.httpService.get(environment.BASE_URL +
       ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
         this.resData = new AppResponse(data);

         //Start the code for Base64 to byte[]
         const byteCharacters = atob(this.resData.data);

         const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
           byteNumbers[i] = byteCharacters.charCodeAt(i);
         }

        let byteArray = new Uint8Array(this.resData.data);

         const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
           const byteCharacters = atob(b64Data);
           const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
             const slice = byteCharacters.slice(offset, offset + sliceSize);

             const byteNumbers = new Array(slice.length);
             for (let i = 0; i < slice.length; i++) {
               byteNumbers[i] = slice.charCodeAt(i);
             }

            const byteArray = new Uint8Array(byteNumbers);
             byteArrays.push(byteArray);
           }

           const blob = new Blob(byteArrays, { type: contentType });
           return blob;
         };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
         //End the code for Base64 to byte[]

        //Save the file
         FileSaver.saveAs(blob, documentName);
       });
  }

generatePdf() {
    if (this.appResponse.status === 200) {
      pdfMake.createPdf(this.exportToPdf()).open(); 
    }  
  }

  exportToPdf() {
    // ...
    return {
      content: [
        //...
        {
          text: 'Maintenance Request Report',
          bold: true,
          fontSize: 17,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'View Maintenance Request',
          style: 'header'
        },    
        this.getMrViewObject(),
        
        {
          text: 'Review Maintenance Request',
          style: 'header'
        },
        this.getMrReviwTableObject(),      
      ],

      info: {
        title: 'Maintenance Request Report',
        author:  'Report',
        subject: 'Report',
        keywords: 'Request Report',
      },
      styles: {
        header: {
          fontSize: 15,
          bold: true,
          margin: [0, 20, 0, 10]         
        },
        name: {
          fontSize: 16,
          bold: true
        },
        tableHeader: {
          bold: true,
        }        
      }
    };
  }

  getMrViewObject(){
    if( this.appResponse.data != null ){    
        return {          
            columns: [
              {
                ul: [
                  'What needs attention? : ' + this.appResponse.data.description + '\n \n' +
                  'Requested Date Time : ' + this.datePipe.transform(this.appResponse.data.createDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
                  'Is this issue urgent? : ' + this.appResponse.data.urgent + '\n \n' +
                  'Created By : ' + this.appResponse.data.fullName + '\n \n' +                 
                  'Document Name : ' + this.appResponse.data.documentName.map(dc => {
                    return [ dc.substr(14)  ];
                  }) 
                  
                ]
              },
              {
                ul: [
                  'More Information : ' + this.appResponse.data.moreInfo + '\n \n' +
                  'Updated Date Time : ' + this.datePipe.transform(this.appResponse.data.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
                  'Is this issue causing a threat to personal safety? : ' + this.appResponse.data.safetyThreat + '\n \n' +
                  'Status  : ' + this.appResponse.data.status + '\n \n' +
                  'ByLaws Topic : ' + this.appResponse.data.byLawsTopic 
                ]
              }
            ],       
        }
    }
  }

  getMrReviwTableObject() {
    if( this.appResponse.data.reviewList != null ){
      return {
        table: {
          widths: ['*', '*', '*', '*','*','*'],
          body: [
            [{
              text: 'Commented By',
              style: 'tableHeader'
            },
            {
              text: 'Role',
              style: 'tableHeader'
            },
            {
              text: 'Revie Status',
              style: 'tableHeader'
            },
            {
              text: 'Review Comments',
              style: 'tableHeader'
            },
            {
              text: 'Review Date',
              style: 'tableHeader'
            }
            ],                
            // ...this.reviewResData.data.map(mr => {
            //   return [ mr.firstName+''+mr.lastName, mr.role ,mr.reviewOutCome,
            //           mr.reviewComments, this.datePipe.transform(mr.reviewDateTime, 'MM/dd/yyyy')];
            // })         
          ]
        }
      };
    }    
  }


}
