import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppResponse } from 'src/app/model/AppResponse';
import { environment } from 'projects/group-portal/src/environments/environment';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';


@Component({
  selector: 'app-shared-document-list',
  templateUrl: './shared-document-list.component.html',
  styleUrls: ['./shared-document-list.component.css'],
  providers: [NgxSpinnerService]
})
export class SharedDocumentListComponent implements OnInit {

  resData: AppResponse;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router,
    private commonService: CommonService, private tokenService: TokenStorageService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
      this.spinner.show();
  }

  ngAfterViewInit(): void {
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_DOCUMENT_BY_GROUP_CURRENT_DATA).subscribe(res => {
      // console.log("2");
      this.spinner.hide();
      //console.log(JSON.stringify(res));
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
      
        // console.log(JSON.stringify(res));
        this.resData = new AppResponse(res);
        setTimeout(() => {
         
          $('#sdListDataTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            stateSave: true,//this line added by aishwaryaa for hb-542
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 5,
              orderable: false
            },
          ]
          });
        }, 200);
        if (res.status === 401) {
          // console.log("401");
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          // console.log("403");
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          this.router.navigate(['home']);
        }
      }
    });

  }

}
