import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { Router } from "@angular/router";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";
import * as $ from "jquery";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import { environment } from "projects/group-portal/src/environments/environment";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-add-payment-config",
  templateUrl: "./add-payment-config.component.html",
  styleUrls: ["./add-payment-config.component.css"],
  providers: [NgxSpinnerService],
})
export class AddPaymentConfigComponent implements OnInit {
  [x: string]: any;
  dueRequestForm: FormGroup;
  dueRequestFormForSingleUSer: FormGroup;
  dueRequestFormCustomType: FormGroup;
  customDueTypeEnabled: Boolean = false;
  // code changed by vasu for HB-1270
  Allhomes: Boolean = false;
  Allhomes2: Boolean = false;
  // code changed by vasu for HB-1270 
  resData: AppResponse;
  submitted = false;
  private addGroupDue =
    environment.BASE_URL + ApplicationURIConstants.ADD_GROUP_DUE;

  flag: boolean;
  rongHo: boolean;
  fileUploadList: string[] = [];
  responseData: AppResponse;
  public min = new Date();
  public max = new Date();
  showamounterr: boolean = false;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ByLawsList: any[] = [];
  homeAddressList = [];
  homesList: any[] = [];
  isChecked: Boolean;
  // code changed by vasu for HB-1270
  isSingleUserVisible: Boolean=false;
   isAllUserVisible: Boolean=false; 
// code changed by vasu for HB-1270

  filteredOptions: Observable<String[]>;
  filteredHomes: Observable<String[]>;

  byLawsTopic = new FormControl("");
  homeAddress = new FormControl("", [Validators.required]);
  // code changed by vasu for HB-1270
  ForSingleHomeowners= new FormControl("");
  ForAllHomeowners=    new FormControl("");
  // code changed by vasu for HB-1270
  ngOnInit() {
    //this.min.setDate(this.min.getDate()-1);
    var date = new Date();
    var datetemp = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    var datetempmax = new Date(
      date.getFullYear() + 2,
      date.getMonth(),
      date.getDate()
    );

    this.max = datetempmax;
    this.min = datetemp;
    this.spinner.show();
    

    this.dueRequestForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      amount: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(AppRegExConstants.DUE_AMOUNT),
        ],
      ],
      homeProfileId: [""],
      dueDate: ["", [Validators.required]],
      dueFrequency: ["", [Validators.required]],
      dueType: ["", [Validators.required]],
      description: [],
      isForSingleUser: [""],
      userProfileInfoId: [""],
      customDueTypeName: [""],
      isEditable:[true]
    });

    this.dueRequestFormForSingleUSer = this.formBuilder.group({
      homeAddress: ["", [Validators.required]],
      HoDetails: [""],
    });

    this.dueRequestFormCustomType = this.formBuilder.group({
      customDueTypeNameInput: ["", [Validators.required]],
    });

    $("#amount").bind("cut copy paste", function (e) {
      e.preventDefault();
    });
  }

  get dues() {
    return this.dueRequestForm.controls;
  }

  get duesForSingleUSer() {
    return this.dueRequestFormForSingleUSer.controls;
  }

  get duesForCustomDueType() {
    return this.dueRequestFormCustomType.controls;
  }
  amountchange($event) {
    console.log($event.target.value.length);

    // if ($event.target.value.length > 8) {
    //   console.log("false");
    //   this.showamounterr = true;
    // } else {
    //   this.showamounterr = false;
    // }

    if ($event.target.value == 0) {
      this.showamounterr = true;
    }
    if ($event.target.value.length == 0) {
      this.showamounterr = false;
    }
  }

  // ngAfterViewInit(): void {
  //   this.TableInitialise();
  // }

  ngAfterViewInit(){
    console.log("inside after view init");
    
    this.httpService
      .get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_HOMES)
      .subscribe((res) => {
        //console.log(JSON.stringify(res));
        this.spinner.hide();
        this.responseData = new AppResponse(res);
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          if (res.status == 200) {
            //  console.log("============="+this.responseData.data)
            for (
              let index = 0;
              index < this.responseData.data.length;
              index++
            ) {
              this.homesList.push(this.responseData.data[index]);
              this.homeAddressList.push(
                this.responseData.data[index].addressline1
              );
            }
            //  console.log("===="+this.homesList);
            //  console.log("======="+this.homeAddressList);
            //  this.homeAddressList = this.responseData.data.homeOwnerList;
          } else if (res.status === 401) {
            //401 token related issue
            this.router.navigate(["login"]);
          } else if (res.status == 403) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${res.errors}`
            );
            this.router.navigate(["home"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.responseData.errors}`
            );
          }
        }
      });

    this.filteredHomes = this.homeAddress.valueChanges.pipe(
      startWith(""),
      map((value) => (value.length >= 3 ? this._filterForHomes(value) : []))
    );
  }
  onSubmit() {
    console.log(this.dueRequestForm.value);
    console.log(this.dueRequestForm.controls["isForSingleUser"].value);

    
    if(!this.dueRequestForm.controls["homeProfileId"].value && (this.dueRequestForm.controls["dueType"].value =="CUSTOMDUE" || this.dueRequestForm.controls["dueType"].value =="PASTDUES")){
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        "Please Provide Home Address"
      );
      this.ngAfterViewInit();
return;
    }
    // code changed by vasu for HB-1270
    if(!this.dueRequestForm.controls["homeProfileId"].value && this.dueRequestForm.controls["dueType"].value =="SPECIALASSESMENT" && this.dueRequestForm.controls["isForSingleUser"].value =="SPECIALASSESMENT" &&this.dueRequestForm.controls["isForAllUser"].value){
      // code changed by vasu for HB-1270
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        "Please Provide Home Address"
        
      );  
      
      this.ngAfterViewInit();
return;
    }


    console.log(this.dueRequestForm.controls["amount"].value);
    if (this.dueRequestForm.controls["amount"].value.length > 8) {
      console.log("false");
      this.showamounterr = true;
      return;
    } else {
      this.showamounterr = false;
    }
    this.submitted = true;
    // console.log("FORM:"+JSON.stringify(this.dueRequestForm.value));
    if (this.dueRequestForm.invalid || this.showamounterr) {
      const controls = this.dueRequestForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log("Invalid" + name);
        }
      }
      return;
    } else {
      this.spinner.show();
      if (this.dueRequestForm.controls["dueType"].value == "CUSTOMDUE") {
        this.dueRequestForm.controls["customDueTypeName"].setValue(
          this.dueRequestFormCustomType.controls["customDueTypeNameInput"].value
        );
      }

      // http service method call
      this.httpService
        .save(this.dueRequestForm.value, this.addGroupDue)
        .subscribe((res) => {
          console.log(res);

          this.spinner.hide();
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.dueRequestForm.reset();
              $("#dueType").prop("selectedIndex", 0);
              $("#dueFrequency").prop("selectedIndex", 0);

              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                `${this.resData.message}`
              );
              setTimeout(() => {
                this.router.navigate(["groupDues"]);
              }, 10000);
            } else if (res.status == 401) {
              this.router.navigate(["login"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        });
    }
  }

  selectHoAddressChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.homesList.length; i++) {
      if (this.homesList[i].homeprofileid == selectedHome.homeprofileid) {
        this.dueRequestForm.controls["homeProfileId"].setValue(
          this.homesList[i].homeprofileid
        );
        this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(
          this.homesList[i].firstname + " " + this.homesList[i].lastname
        );
        this.dueRequestForm.controls["userProfileInfoId"].setValue(
          this.homesList[i].userProfileInfoId
        );
      }
    }
  }

  private _filterForHomes(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.homesList.filter((option, index, self) => 
      index === self.findIndex((t) => (
        t.homeprofileid === option.homeprofileid && t.addressline1 === option.addressline1
      )) && option.addressline1.toLowerCase().includes(filterValue)
    );
  }
  displayFn(home: any): string {
    //console.log("disply ->> " + home.addressline1)
    if (home.homeprofileid == undefined) {
      //this.homeAddress.setErrors({ invalid: true });
      return;
    } else {
      return home.addressline1;
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;

      // console.log("insdie on key up for Home address");
      
      this.homeAddress.setErrors({ required: true });
    } else {
      // console.log("insdie on else");
      this.rongHo = false;
      for (var i = 0; i < this.homesList.length; i++) {
        // console.log("insdie for ");
        if (this.homesList[i].addressline1 == event.target.value) {

          // console.log("insdie for if ");
          this.dueRequestForm.controls["homeProfileId"].setValue(
            this.homesList[i].homeprofileid
          );
          this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(
            this.homesList[i].firstname + " " + this.homesList[i].lastname
          );
          this.dueRequestForm.controls["userProfileInfoId"].setValue(
            this.homesList[i].userProfileInfoId
          );
          this.homeAddress.setErrors(null);
          this.rongHo = false;
        } else {

          // console.log("insdie for else");
          this.dueRequestForm.controls["homeProfileId"].setValue(null);
          this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(null);
          this.dueRequestForm.controls["userProfileInfoId"].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;
         
        }
      }
    }
  }

  // code changed by vasu for HB-1270
  changeFunction(value:any) {
 console.log(value);
  if ($("#isSingleUser").is(":checked")) {
    this.isChecked = true;
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
  }
  else{
    
    this.isChecked = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(false);
  }

    // if ($("#isSingleUser").is(":checked")) {
    //   console.log(value);
    //   this.isChecked = true;
    //   this.dueRequestForm.controls["isForSingleUser"].setValue(true);
    // }
    // else if ($("#isAllUser").is(":checked")) {
    //   console.log(value);
    //   this.isChecked = true;
    //   this.dueRequestForm.controls["isForAllUser"].setValue(false);
    // }
    // else {
    //   this.isChecked = false;
    //   this.dueRequestForm.controls["isForSingleUser"].setValue(false);
    // }

  }
   
  refresh():void{
  window.location.reload();
  }
// code changed by vasu for HB-1270

  changeType(event: any) {
    this.ngAfterViewInit();
    this.customDueTypeEnabled = false;
    this.isChecked = false;
    this.isSingleUserVisible = false;
    this.dueRequestForm.controls["isForSingleUser"].setValue(false);

    this.dueRequestForm.controls["userProfileInfoId"].setValue(null);
    this.dueRequestForm.controls["homeProfileId"].setValue(null);
    this.dueRequestForm.controls["customDueTypeName"].setValue(null);
    this.homeAddress.setValue(null);
    this.dueRequestFormForSingleUSer.controls["homeAddress"].setValue(null);
    this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(null);
    this.dueRequestFormCustomType.controls["customDueTypeNameInput"].setValue(
      null
    );
    this.homeAddress.setErrors(null);
    this.rongHo = false;

    $("#dueFrequency").prop("disabled", false);
    $("#dueFrequency").val("");
   
    if (event.target.value == "PASTDUES") {
      this.isChecked = true;
      this.isSingleUserVisible = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
    } else if (event.target.value == "CUSTOMDUE") {
      this.isChecked = true;
      this.isSingleUserVisible = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
    } else if (event.target.value == "FINE") {
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
    } else if (event.target.value == "SPECIALASSESMENT") {
      this.customDueTypeEnabled = true;
      this.isSingleUserVisible = true;
      // code changed by vasu for HB-1270
      this.Allhomes=true;
      this.Allhomes2=false;
        this.homeAddress.setErrors(null);
        // code changed by vasu for HB-1270
      this.dueRequestForm.controls["dueFrequency"].setValue("");
    }else if (event.target.value == "REGULARDUE") {
      
      this.dueRequestForm.controls["dueFrequency"].setValue("");
    }
  }
}
