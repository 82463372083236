import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';

@Component({
  selector: 'app-view-reimburse-due',
  templateUrl: './view-reimburse-due.component.html',
  styleUrls: ['./view-reimburse-due.component.css']
})
export class ViewReimburseDueComponent implements OnInit {

  groupDueId : string;
  appResponse: AppResponse;
  resData: AppResponse;


  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {}

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.groupDueId = params.id;
    });
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_SINGLE_REIMBURSE_DUE + this.groupDueId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }


}
