import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-export-homes',
  templateUrl: './export-homes.component.html',
  styleUrls: ['./export-homes.component.css']
})
export class ExportHomesComponent implements OnInit {
  resData: AppResponse;
  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) { }

  ngOnInit() {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.HOME_OWNERS_LIST).subscribe(res => {
      if (res.status === 200) {
        this.resData = new AppResponse(res);
        console.log(this.resData)
        console.log("this is console testing")
        //console.log("RESDATA:" + JSON.stringify(this.resData));

        setTimeout(() => {
          $('#exportHome').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            stateSave: true,//this line added by aishwaryaa for hb-542
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],// this line modified by aishwaryaa for hb-542
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 2,
              orderable: false
            },
            ]
          });
        }, 200);
      
      } else if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
      console.log("this is console testing outside if")
    });




  }


  TableInitialise() {
    $('#exportHome').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
       // Added by Hema shanker HB-675 on 25/11/2021 Start
       stateSave: true,
       // Added by Hema shanker HB-675 on 25/11/2021 End
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
     // Modified by Hema shanker HB-675 on 25/11/2021 Start
     lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
     // Modified by Hema shanker HB-675 on 25/11/2021 End
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  
  TableInitialiseexport() {
    $('#exportHome').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'HomeOwers Details.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    $('#exportHome').dataTable().fnDestroy();
    setTimeout(() => {
      this.TableInitialiseexport();
      // this.spinner.hide();
    }, 2000);
    let element = document.getElementById('exportHome');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'][8] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
