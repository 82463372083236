import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';

declare let $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  captchaResponse: string;
  token: String;
  appResponse: AppResponse;
  resData: AppResponse;
  resetPassword : Boolean;
  private resetPass = environment.BASE_URL + ApplicationURIConstants.RESET_PASSWORD;

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

    constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private applicationUtils : ApplicationUtils, private formBuilder: FormBuilder, private commonService: CommonService) {

     }

    resetPwdForm: FormGroup;

    ngOnInit() {

      $(document).ready(() => {
        $('body').css('background-color', '#eee');
      });

      this.activatedRoute.params.subscribe(params => {
        this.token = params.token;
      });
      // tslint:disable-next-line: max-line-length
      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.TOKEN + this.token).subscribe(data => {
        this.appResponse = new AppResponse(data);
            this.resetPwdForm = this.formBuilder.group({
           "newPassword": ['', [Validators.required, Validators.pattern(AppRegExConstants.NEW_PASSWORD)]],
            "confirmPassword": ['', [Validators.required, Validators.pattern(AppRegExConstants.NEW_PASSWORD)]],
            "token":[this.appResponse.data.token,[]],
            "userLoginId":[this.appResponse.data.userLoginId,[]],
            "tokenId":[this.appResponse.data.tokenId,[]]
           });
        //console.log("==="+((Date.now() - Date.parse(this.appResponse.data.createDateTime))/60000));
        // console.log(((Date.now() - Date.parse(this.appResponse.data.createDateTime))/60000) > 1440);
        if(((Date.now() - Date.parse(this.appResponse.data.createDateTime))/60000) > 1440 || !this.appResponse.data.isActive){
          this.resetPassword = false;
        }else{
          this.resetPassword = true;
        }

        if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(['home']);
        }
      });

    }

    get f() { return this.resetPwdForm.controls; }

    submitted = false;
    onSubmit() {
      this.submitted = true;

      if (this.resetPwdForm.invalid) {
        return;
      } else {

    this.httpService.save(this.resetPwdForm.value, this.resetPass)
      .subscribe((res) => {
        if(res != undefined){
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
            this.resetPwdForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 2000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['login']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
      }
    }


    togglePassword(id, passId){
      var passwordInput = document.getElementById(passId);
      var passid = document.getElementById(id);
     
      // console.log("pass"+passwordInput);
    
          if (passwordInput.getAttribute('type')  == 'password') {
              passwordInput.setAttribute('type','text');
              $(passid).toggleClass("fa-eye fa-eye-slash");
          } else {
            passwordInput.setAttribute('type','password');
            $(passid).toggleClass("fa-eye fa-eye-slash");
            
          }
  
      
  }
  


}