import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppRegExConstants } from '../../utils/AppRegExConstants';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-external-occupation-list',
  templateUrl: './external-occupation-list.component.html',
  styleUrls: ['./external-occupation-list.component.css'],
  providers: [NgxSpinnerService]
})
export class ExternalOccupationListComponent implements OnInit {
  [x: string]: any;

  occupationList:any[] = [];
  addOccupationFormGroup: FormGroup;
  editOccupationFormGroup: FormGroup;
  requirecategory: boolean=false;
  deleteResData: any;
  dataTable: any;
  editresData:any;
  addresData: any;
  resData: any;
  submitted:boolean=false;

  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  @ViewChild('occ', { static: true }) occ: ElementRef;
  @ViewChild('editocc', { static: true }) editocc: ElementRef;


  constructor(private httpService: HttpTransactionService,
     private router: Router,
     private commonService: CommonService,
     private tokenService: TokenStorageService,
     private spinner: NgxSpinnerService,

     private formBuilder:FormBuilder) {}





     ngOnInit() {





      this.addOccupationFormGroup = this.formBuilder.group({
        'occupation': ['', [Validators.required,Validators.pattern(AppRegExConstants.OCCUPATION)]]
      });
      this.editOccupationFormGroup = this.formBuilder.group({
        'occupation': ['', [Validators.required,Validators.pattern(AppRegExConstants.OCCUPATION)]],
        'occupationId': ['',[Validators.required]]

      });


   
    }



    openEditOccupation(cat,id){



     console.log(cat  + id);


this.editOccupationFormGroup.controls['occupation'].setValue(cat);

this.editOccupationFormGroup.controls['occupationId'].setValue(id);
    }


    


    addOccupation(){
      this.submitted = true;
if(this.addOccupationFormGroup.invalid){
  return;
}
this.occ.nativeElement.value='';
      console.log(this.addOccupationFormGroup.controls['occupation'].value);

      this.httpService.save(this.addOccupationFormGroup.value,environment.BASE_URL+ ApplicationURIConstants.ADD_OCCUPATION)
        .subscribe((res) => {
          console.log(res);


          if(res != undefined){
            this.addresData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted=false;
              $('#addOccupationTable').dataTable().fnDestroy();

              $('.close').click();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.addresData.message}`);
              setTimeout(() => {
              this.ngAfterViewInit();
              }, 2000);  //15s
            } else if(res.status == 401){
              this.router.navigate(['logout']);
            }else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.addresData.message}`);
            }
          }else{
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });

    }


    editOccupation() {
      this.submitted=true;
this.editocc.nativeElement.value='';
      if (this.editOccupationFormGroup.invalid || this.requirecategory) {
        console.log("occupation")
        return;

      }else {
        // console.log("========>" + JSON.stringify(this.editOccupationFormGroup.value))
        this.httpService.save(this.editOccupationFormGroup.value, environment.BASE_URL + ApplicationURIConstants.EDIT_OCCUPATION)
          .subscribe((res) => {
            // console.log(res);
            // console.log("INSIDE THE FIRST");
            if (res != undefined) {

              this.editresData = new AppResponse(res);
              console.log("RESPONSE:" + JSON.stringify(this.editresData));
              if (res.status == 200) {
                // added by gopal for HB-548 on 07/12/2021 start
                $('#addOccupationTable').dataTable().fnDestroy();

                console.log("INSIDE THE SECOND");
                $('.close').click();
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.editresData.message}`);

                setTimeout(() => {
                  this.ngOnInit();                  
                  this.ngAfterViewInit();
                }, 2000);  //15s
              } else if (res.status == 401) {
                // console.log("INSIDE THE THIRD");
                this.router.navigate(['login']);
              } else {
                // console.log("INSIDE THE FOURTH");
               if(this.editresData.errors !=null){
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.editresData.errors}`);
               }else{
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.editresData.message}`);
               }
              //  HB-1093 added by vignesh start on 7/10/2022 
               setTimeout(() => {
                this.ngOnInit();
                
              }, 2000);  //15s
              //  HB-1093 added by vignesh end on 7/10/2022 
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.editresData.errors}`);
            }
          });
      }


    }


    private deleteOccupation= environment.BASE_URL + ApplicationURIConstants.DELETE_OCCUPATION;
    public deleteOccupattionData(data: any) {
      console.log("DATA:" + data);
      this.editOccupationFormGroup.controls['occupationId'].setValue(data);
      if (confirm("Are you sure you want to delete this Occupation ?")) {


        this.httpService.save(this.editOccupationFormGroup.value,this.deleteOccupation)
          .subscribe((res) => {
            this.deleteResData = new AppResponse(res);
            console.log(res);
            if (this.deleteResData.status == 200) {
              //this.submitted = false;
              $('#addOccupationTable').dataTable().fnDestroy();

              $('.close').click();
              // console.log("MSG:" + this.deleteResData.message)
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.deleteResData.message);

              setTimeout(() => {
                this.ngAfterViewInit();
              }, 3000);

            } else {
              // console.log(this.deleteResData.errors)
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.deleteResData.errors}`);
            }
          });
      }
    }


    ngAfterViewInit(): void {

      // console.log("1");
      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_OCCUPATION).subscribe(res => {
        console.log(res);
        // console.log(JSON.stringify(res));
        this.spinner.hide();
        if(res == undefined){
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        }else{
          this.resData = new AppResponse(res);
          console.log(this.resData);
          setTimeout(() => {
            $('#addOccupationTable').DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
              stateSave: true,//this line added by aishwaryaa for hb-542
              /*"ordering": true,*/
              order: [],
              language: {
                zeroRecords: 'No records found.',
              },
              lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],// this line modified by aishwaryaa for hb-542
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [{
                targets: 2,
                orderable: false
              },
            ]
            });
          }, 200);
          if (res.status === 401) {
            // console.log("401");
            //401 token related issue
            this.tokenService.clearSession();
            this.router.navigate(['login']);
          } else if (res.status === 403) {
            //403 URL not accessible
            // console.log("403");
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            this.router.navigate(['home']);
          }
        }

      });      

    }
  // fillgroupUserDueId(data) {
  //   this.editOccupationFormGroup.reset();



  // }



}
