import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';


import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppRegExConstants } from '../../utils/AppRegExConstants';

@Component({
  selector: 'app-add-renter',
  templateUrl: './add-renter.component.html',
  styleUrls: ['./add-renter.component.css']
})
export class AddRenterComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  isOnRent: boolean;
  updateIsOnRentResData: AppResponse;
  show: boolean = true;
  renterInformationForm: FormGroup;
  fileUploadList: string[] = [];
  submitted = false;
  errorss: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  public shows = false;
  resDataRenter: AppResponse;
  homeProfileId: string;
  createdBy: string;
  editable: boolean;
  errorFlag: boolean = true;
  errorFlagLeaseEndDate: boolean = true;




  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  public minDateOfBirth = new Date(1925, 12, 1, 0, 0);
  public maxDateOfBirth = new Date(2005, 12, 1, 0, 0);
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public maxLeaseEndDate = new Date(2119, 12, 1, 0, 0);
  public minLeaseEndDateTemp;

  isError: boolean = true;
  countryCode: any;
  intlobj: any;
  flag: boolean = false;
  checkEmptyFlag: boolean = true;

  @ViewChild('myInput', { static: false }) myInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.homeProfileId = params.id;
    });

    var date = new Date();

    var datetempmax = new Date(date.getFullYear() + 99, date.getMonth(), date.getDate());

    this.max = datetempmax;

    this.renterInformationForm = this.formBuilder.group({
      'leaseStartDate': ['', [Validators.required, Validators.maxLength(10)]],
      'leaseEndDate': ['', [Validators.required]],
      'firstName': ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      'email': ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      'lastName': ['', [Validators.required,Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      'contact': ['', []],
      'dateOfBirth': ['',],
      'importantdocuments': [''],
    });

    var d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    this.maxDateOfBirth = d;

    var minDateVal = new Date();
    // added by gopal for HB - 541  start on 14 / 12 / 2021
    minDateVal.setFullYear(minDateVal.getFullYear() - 1);
    this.min = minDateVal;
    this.minLeaseEndDate = minDateVal;

  }

  clearleaseStartDateValue() {
    this.renterInformationForm.controls['leaseStartDate'].setValue(null);
  }
  clearleaseEndDateValue() {
    this.renterInformationForm.controls['leaseEndDate'].setValue(null);
  }
  cleardateOfBirthValue() {
    this.renterInformationForm.controls['dateOfBirth'].setValue(null);
  }


  get renterInfo() {
    return this.renterInformationForm.controls;
  }
  private isOnRentURL = environment.BASE_URL + ApplicationURIConstants.IS_ON_RENT;


  // addrenter
  private renterInformationUrl = environment.BASE_URL + ApplicationURIConstants.RENTER_INFORMATION_ADD_BY_GA;



  onSubmit() {
    this.submitted = true;
    if (this.renterInformationForm.invalid || (!this.isError && !this.checkEmptyFlag)) {
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.renterInformationForm.value))
      return;
    }
    else {

      this.isValidDate = this.validateDates(this.renterInformationForm.controls['leaseStartDate'].value, this.renterInformationForm.controls['leaseEndDate'].value);
      // console.log("ADDRENTERINFO:" + JSON.stringify(formData));
      var formData = new FormData();

      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("importantdocuments", this.fileUploadList[i]);
      }
      formData.append("leaseStartDate", this.renterInformationForm.controls['leaseStartDate'].value);
      formData.append("leaseEndDate", this.renterInformationForm.controls['leaseEndDate'].value);
      formData.append("firstName", this.renterInformationForm.controls['firstName'].value);
      formData.append("lastName", this.renterInformationForm.controls['lastName'].value);
      formData.append("dateOfBirth", this.renterInformationForm.controls['dateOfBirth'].value);
      formData.append("contact", this.intlobj.getNumber());
      // formData.append("contact", this.renterInformationForm.controls['contact'].value);
      formData.append("email", this.renterInformationForm.controls['email'].value);
      formData.append("homeProfileId", this.homeProfileId);
      //  formData.append("createdBy",this.createdBy);


      // console.log("RENTERINFOINSI:" + JSON.stringify(formData));
      if (this.isValidDate) {
        // console.log("FORMDATA:" + JSON.stringify(this.renterInformationForm.value));
        this.httpService.save(formData, this.renterInformationUrl)
          .subscribe((res) => {

            if (res != undefined) {
              this.resDataRenter = new AppResponse(res);
              // console.log("RENTERINFOINSI:" + JSON.stringify(formData));

              if (res.status == 200) {
                this.renterInformationForm.reset();
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resDataRenter.message}`);
                setTimeout(() => {
                  // this.show = false;
                  // this.ngAfterViewInit();

                  this.router.navigate(['homes']);

                }, 2000);  //12s
              } else if (res.status == 401) {
                this.router.navigate(['logout']);
              }
              else {
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resDataRenter.errors}`);
              }
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }
          });
      }
    }

  }

  onFileSelect(event) {
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) <= (sDate)) {
      // this.shows = true;
      this.errorss = { isError: true, errorMessage: 'Lease End Date should be greater then Lease Start Date.' };
      setTimeout(function () {
        // this.shows = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    }

    return this.isValidDate;
  }

  onChange(data) {
    if (data !== null) {
      this.errorFlag = false;
      this.editable = true;
      this.minLeaseEndDateTemp = data;
      var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(), this.minLeaseEndDateTemp.getMonth(), this.minLeaseEndDateTemp.getDate() + 1);
      // console.log("////////" + datetemp);
      this.minLeaseEndDate = datetemp;
      this.renterInformationForm.controls['leaseEndDate'].setValue(null);
      // console.log("---" + JSON.stringify(data));

    } else {
      this.errorFlag = true;
      this.renterInformationForm.controls['leaseEndDate'].setValue(null);
      this.editable = false;
    }

  }

  onChangeLeaseEnd(data) {
    if (data !== null) {
      this.errorFlagLeaseEndDate = false;
    } else {
      this.errorFlagLeaseEndDate = true;
    }
  }

  test() {
    this.errorFlag = false;
  }

  testLend() {
    this.errorFlagLeaseEndDate = false;
  }

  onCountryChange(country: any) {
    // console.log("=======" + country.dialCode);
    this.countryCode = country.dialCode;
    if (this.flag)
      this.intlobj.setNumber('');
    else
      this.flag = true;

  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
  }

  telInputObject(obj) {
    // console.log(obj);
    this.intlobj = obj;
    this.flag = true;
  }

  checkForError(obj) {
    if (this.intlobj.getNumber() == '')
      this.checkEmptyFlag = true;
    else
      this.checkEmptyFlag = false;
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else
        return false;
    }
    return true;

  }



}