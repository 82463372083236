import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
//import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-general-user',
  templateUrl: './add-general-user.component.html',
  styleUrls: ['./add-general-user.component.css']
})
export class AddGeneralUserComponent implements OnInit {

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router,private tokenService: TokenStorageService) { }

  userRegistrationForm: FormGroup;
  guUserForm: FormGroup;
  isOccupationShow : boolean;
  occupationList:any[] = [];
  addOccupationFormGroup: FormGroup;
  groupList = [];
  isError : boolean = true;
  countryCode : any;
  intlobj : any;
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  // separateDialCode = false;
	// SearchCountryField = SearchCountryField;
	// TooltipLabel = TooltipLabel;
	// CountryISO = CountryISO;
	// preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	// phoneForm = new FormGroup({
	// 	phone: new FormControl(undefined, [Validators.required])
	// });

	// changePreferredCountries() {
	// 	this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	// }


  ngOnInit() {

    this.countryCode = 91;

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_DATA_MR).subscribe(res => {

      this.resData = new AppResponse(res);

      this.occupationList = this.resData.data.occupations;


      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });

    // ("#firstName").focus();
    this.userRegistrationForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "contact": ['', [Validators.required]],
      "email": ['', [ Validators.required,Validators.pattern(AppRegExConstants.EMAIL)]],
      "role" : ['', [Validators.required]],
      "externaluseroccupationId" : [''],


    });

    this.addOccupationFormGroup = this.formBuilder.group({
      'occupation': ['', [Validators.required]],

    });

    this.guUserForm = this.formBuilder.group({
      "externaluseroccupationId" : ['',[Validators.required]]
    })


  }

  // convenience getter for easy access to form fields
  get f() { return this.userRegistrationForm.controls; }

  // URL
  private users = environment.BASE_URL + ApplicationURIConstants.ADD_EXTERNAL_USER;
  private addoccupation = environment.BASE_URL+ ApplicationURIConstants.ADD_OCCUPATION;

  resData: AppResponse;

  submitted = false;


  addOccupation(){
    console.log(this.addOccupationFormGroup.value);

    this.httpService.save(this.addOccupationFormGroup.value, this.addoccupation)
      .subscribe((res) => {
        console.log(res);

        if(res != undefined){
          this.resData = new AppResponse(res);
          if (res.status == 200) {

            $('.close').click();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
            this.ngOnInit();
            }, 2000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['logout']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.message}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });

  }

  onSubmit(){
    if (this.userRegistrationForm.invalid || !this.isError) {
      return;
    } else {
      this.userRegistrationForm.controls['contact'].setValue(this.intlobj.getNumber());
      this.userRegistrationForm.controls['externaluseroccupationId'].setValue(this.guUserForm.value.externaluseroccupationId);
      this.httpService.save(this.userRegistrationForm.value, this.users)
      .subscribe((res) => {
        if(res != undefined){
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
            this.userRegistrationForm.reset();
            $("#role").prop('selectedIndex', 0);
             $("#externaluseroccupation").prop('selectedIndex', 0);
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['externaluser-list']);
            }, 5000);  //15s
          } else if(res.status == 401){
            this.router.navigate(['logout']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
    }


  }

  clickForReset() {
    $('html, body').animate({ scrollTop: (0) }, 1000);
  }

  changeType(event: any){

    if(event.target.value == 'general user'){
      this.isOccupationShow = true;
      $("#externaluseroccupation").prop('selectedIndex', 0);
    }else{
      this.isOccupationShow = false;
      this.guUserForm.controls['externaluseroccupationId'].setValue('')    
    }
  }

  // test(){
  //   console.log(this.userRegistrationForm.value);
  //   console.log("===="+this.countryCode);
  // }

  // telInputObject(obj) {
  //   console.log("==="+obj);

  // }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;

  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

telInputObject(obj) {
  // console.log(obj);
  this.intlobj = obj;
}

checkForError(obj){

  this.myInput.nativeElement.blur();
  $("#phoneInput").focus();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else
       return false;
  }
  return true;

}

}
