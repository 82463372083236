import { Component, OnInit } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { ApplicationURIConstants } from 'projects/group-portal/src/app/utils/ApplicationURIConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'projects/group-portal/src/environments/environment';

@Component({
  selector: 'app-by-laws-list',
  templateUrl: './by-laws-list.component.html',
  styleUrls: ['./by-laws-list.component.css']
})
export class ByLawsListComponent implements OnInit {

   dataTable: any;
  resData: AppResponse;
  editReqData: AppResponse;
  editByLawForm: FormGroup;
  deleteResData: AppResponse;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router,private commonService: CommonService, private tokenService: TokenStorageService) {
  }

  ngOnInit() {
   this.editByLawForm = this.formBuilder.group({
    "byLawsTopic": ['', Validators.required],
    "description": ['', Validators.required],
    "byLawsType": ['', Validators.required],
    "byLawsId": ['', Validators.required],
    "sendbylaw":[false]
    });

  }


  ngAfterViewInit(): void {
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.BY_LAWS).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resData = new AppResponse(res);
      // console.log(JSON.stringify(this.resData.data));
      setTimeout(() => {

        $('#byLawsDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,//this line added by aishwaryaa for HB-542
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
        ]
        });
      }, 200);
      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });

  }

  public onChange(data: any) {

    this.editByLawForm = this.formBuilder.group({
      "byLawsId": [data.byLawsId, Validators.required],
      "byLawsTopic": [data.byLawsTopic, Validators.required],
      "description": [data.description, Validators.required],
      "byLawsType": [data.byLawsType, Validators.required],
      "sendbylaw":[data.sendbylaw]
    });

  }

  get bylaws() { return this.editByLawForm.controls; }

  private editByLaw = environment.BASE_URL + ApplicationURIConstants.EDIT_BY_LAW

  submitted = false;
  onSubmit() {
    this.submitted = true;
    if (this.editByLawForm.invalid) {
      console.log("invalid" + JSON.stringify(this.editByLawForm.value))
      return;
    } else {

      // console.log("BYLAWSFORM:" + JSON.stringify(this.editByLawForm.value));

       this.httpService.save(this.editByLawForm.value,  this.editByLaw)
        .subscribe((res) => {
          this.editReqData = new AppResponse(res);
          if (this.editReqData.status == 200) {
            this.submitted = false;
            $('.close').click();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.editReqData.message);

           /*setTimeout(() => {
              this.router.navigate(['home']);
            }, 15000);*/

            //location.reload();
            $('#byLawsDataTable').dataTable().fnDestroy();
            setTimeout(() => {
              this.ngAfterViewInit();
            }, 1000);


          } else {
            // console.log(this.editReqData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.editReqData.errors}`);
          }
        });
    }
    //   this.modalService.dismissAll();
  }

  private deletByLaw = environment.BASE_URL + ApplicationURIConstants.DELETE_BYLAW ;

  public deleteByLaw(data: any){
    // console.log("DATA:"+data);
   if(confirm("Are you sure you want to delete this bylaw ?")){
    this.httpService.update(data, this.deletByLaw + data)
    .subscribe((res) => {
      this.deleteResData = new AppResponse(res);
      if (this.deleteResData.status == 200) {
        //this.submitted = false;
        // console.log("MSG:"+this.deleteResData.message)
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.deleteResData.message);
        $('#byLawsDataTable').dataTable().fnDestroy();
        setTimeout(() => {
         // location.reload();
         //$('#byLawsDataTable').dataTable().fnDestroy();
         this.ngAfterViewInit();
         }, 100);

       // location.reload();

      } else {
        // console.log(this.deleteResData.errors)
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.deleteResData.errors}`);
      }
    });
   }
}
}
