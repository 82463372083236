//  Author of this file is Gopal 
import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from 'ngx-spinner';

import { style } from '@angular/animations';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-audit-view-cr',
  templateUrl: './audit-view-cr.component.html',
  styleUrls: ['./audit-view-cr.component.css'],
  providers: [NgxSpinnerService,DatePipe]
})
export class AuditViewCrComponent implements OnInit {

 
  complianceRequestId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  resDataOfUser:AppResponse;
  fileNameContentType: string;


  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService,    
    private commonService: CommonService, 
    private router: Router,
    private tokenService: TokenStorageService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.complianceRequestId = params.id;
    });

    // tslint:disable-next-line: max-line-length
    // console.log("complianceRequestId -> " + this.complianceRequestId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + "/" + this.complianceRequestId).subscribe(data => {
      

      this.spinner.hide();
     
      if (data.status === 200) {
        this.appResponse = new AppResponse(data);
        // console.log(this.appResponse)
   
      }else if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        //this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        // this.router.navigate(['home']);
      } 
    });

  
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));

        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

  generatePdf() {
    if (this.appResponse.status === 200) {
      pdfMake.createPdf(this.exportToPdf()).open(); 
    }  
  }

  exportToPdf() {
    // ...
    return {
      content: [
        //...
        {
          text: 'HybridHOA Complaince Violations Forms',
          bold: true,
          fontSize: 17,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
      
        {
          text: 'Home Owners Association is committed to help protect and preserve the overall environment of our community. One of the tasks of the Associations is to respond to resident concerns. In keeping with this Reporting community concerns is anonymous. The Rules Enforcement Committee will make every attempt possible to resolve all issues, but please allow allotted time depending on the offense \n',
         
        },  
        {
          text: 'Claimant \n',
          style:'header'
         
        },   
        this.getCrViewObject(),
        
        {
          text: 'Action taken thus far to resolve the matter',
          style: 'header'
        },
        this.getCrReviwTableObject(),
        {
          text: '\n\n',
       
        },
        // {
        //   text: 'Linked Compliance Request',
        //   style: 'header'
        // },
        // this.getLinkedCrObject(),
        this.getCrSign(),

        {
          text: '\n\nThis form is intended to provide the Ledbridge Vista Homeowners Association Board of Directors with advice on residents of the Subdivision that are not in compliance with the covenants. community rules and regulations approved and adopted by the Association. The form must is on an advisory capacity to the Board of Directors and is not to be used as a notification to the alleged violator or to assume any action to be taken by the Board of Directors',
          
        },
      ],
      info: {
        title: 'HybridHOA Complaince Violations Forms',
        author:  'Report',
        subject: 'Report',
        keywords: 'Request Report',
      },
      styles: {     
        header: {
          fontSize: 15,
          bold: true,
          margin: [0, 20, 0, 10]          
        },
        name: {
          fontSize: 16,
          bold: true
        },
        tableHeader: {
          bold: true,
        },
        ulborder:{
          decorationStyle:'none'
        }
        
      }
    };
  }

  getCrViewObject(){
    if( this.appResponse.data.complianceRequest != null ){    
        return {          
            columns: [
              {
                ul: [
                  'Name : ' + this.appResponse.data.complianceRequest.createdbyName + '\n \n' +
                  'Address : ' + this.appResponse.data.complianceRequest.addressForCommunication + '\n \n' +
                  'ByLaws Topic : ' + this.appResponse.data.complianceRequest.byLawsTopic + '\n \n' +
                  'Address  of Violation: '+this.appResponse.data.complianceRequest.createdToaddress+'\n \n'+
                  'Violation : ' + this.appResponse.data.complianceRequest.description + '\n \n' 
                  // 'Category : ' + this.appResponse.data.complianceRequest.category + '\n \n' +
                  // 'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
                  // 'Created For : ' + this.appResponse.data.complianceRequest.createdToName + '\n \n' +
                  
                  // 'Fine Amount : '+ this.appResponse.data.complianceRequest.fineAmount
                ],
                style:'ulborder'
              },
              {
                ul: [
                  'Date/Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.createdDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' 
                 
                  // 'Status : ' + this.appResponse.data.complianceRequest.status + '\n \n' +
                 
                  // 'Document Name : ' + this.appResponse.data.complianceRequest.listRequestDocument.map(dc => {
                  //   return [ dc.documentname.substr(14) ];
                  // }) + '\n \n' +
                  // 'Dute Date : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.dueDate, 'MM/dd/yyyy')
                ],
                style:'ulborder'
              }
            ],       
        }
    }
  }

  getCrSign(){
    if( this.appResponse.data.complianceRequest != null ){    
        return {          
            columns: [
              {
                ul: [
                  'Signature : ' 
                  // 'Address : ' + this.appResponse.data.complianceRequest.createdToaddress + '\n \n' +
                  // 'ByLaws Topic : ' + this.appResponse.data.complianceRequest.byLawsTopic + '\n \n' +
                  // 'Address  of Violation: '+this.appResponse.data.complianceRequest.createdToaddress+'\n \n'+
                  // 'Violation : ' + this.appResponse.data.complianceRequest.description + '\n \n' 
                  // 'Category : ' + this.appResponse.data.complianceRequest.category + '\n \n' +
                  // 'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
                  // 'Created For : ' + this.appResponse.data.complianceRequest.createdToName + '\n \n' +
                  
                  // 'Fine Amount : '+ this.appResponse.data.complianceRequest.fineAmount
                ],
                style:'ulborder'
              },
              {
                ul: [
                  'Date/Time : ' 
                 
                  // 'Status : ' + this.appResponse.data.complianceRequest.status + '\n \n' +
                 
                  // 'Document Name : ' + this.appResponse.data.complianceRequest.listRequestDocument.map(dc => {
                  //   return [ dc.documentname.substr(14) ];
                  // }) + '\n \n' +
                  // 'Dute Date : ' + this.datePipe.transform(this.appResponse.data.complianceRequest.dueDate, 'MM/dd/yyyy')
                ],
                style:'ulborder'  
              }
            ],       
        }
    }
  }

  getCrReviwTableObject() {
    if( this.appResponse.data.reviewCrList != null ){
      return {
        table: {
          widths: ['*', '*', '*', '*','*'],
          body: [
            [{
              text: 'Assigned Datetime',
              style: 'tableHeader'
            },
            {
              text: 'Assigned To',
              style: 'tableHeader'
            },
            {
              text: 'Reviewoutcome',
              style: 'tableHeader'
            },
            {
              text: 'Review Datetime',
              style: 'tableHeader'
            },
            {
              text: 'Review Comments',
              style: 'tableHeader'
            }
            ],                
            ...this.appResponse.data.reviewCrList.map(cr => {
              return [this.datePipe.transform( cr.assignedDateTime, 'MM/dd/yyyy hh:mm:ss a'), cr.fullName,
                cr.reviewOutCome, this.datePipe.transform( cr.reviewDateTime, 'MM/dd/yyyy hh:mm:ss a\n\n'), cr.reviewComments];
            })         
          ]
        }
      };
    }    
  }

  getLinkedCrObject(){
    if( this.appResponse.data.linkCompliance != null ){
      return {
        columns: [
          {
            ul: [
              'Created By : ' + this.appResponse.data.linkCompliance.createdbyName + '\n \n' +
              'Category : ' + this.appResponse.data.linkCompliance.category + '\n \n' +
              'Audit Date Time : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.auditDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              'Created For : ' + this.appResponse.data.linkCompliance.createdToName + '\n \n' +
              'Description : ' + this.appResponse.data.linkCompliance.description + '\n \n' +
              'Is Fine Applicable : ' + this.appResponse.data.linkCompliance.fineApplicable + '\n \n' +
              'Fine Amount : '+ this.appResponse.data.linkCompliance.fineAmount
            ]
          },
          {
            ul: [
              'Created Date Time : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.createdDateTime, 'MM/dd/yyyy hh:mm:ss a') + '\n \n' +
              'ByLaws Topic : ' + this.appResponse.data.linkCompliance.byLawsTopic + '\n \n' +
              'Status : ' + this.appResponse.data.linkCompliance.status + '\n \n' +
              'Address : ' + this.appResponse.data.linkCompliance.createdToaddress + '\n \n' +
              'Document Name : ' + this.appResponse.data.linkCompliance.listRequestDocument.map(dc => {
                return [ dc.documentname.substr(14) ];
              }) + '\n \n' +
              'Dute Date : ' + this.datePipe.transform(this.appResponse.data.linkCompliance.dueDate, 'MM/dd/yyyy')
             
            ]
          }
        ],
      }
    }   
  }


}
