import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-list-reimburse-dues',
  templateUrl: './list-reimburse-dues.component.html',
  styleUrls: ['./list-reimburse-dues.component.css']
})
export class ListReimburseDuesComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  editReimbursementFormGroup: FormGroup;
  dueAmount: number = 0;
  requireAmount: boolean=false;

  //declaring invalidAmt for HB-589 fix by Gopal
  invalidAmt;

  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
  }

  ngOnInit() {

    this.editReimbursementFormGroup = this.fb.group({
      'reimburseComment': ['', [Validators.required]],
      'wavedOffAmount': ['', [Validators.required]],
      'groupUserDueId': ['', [Validators.required]],
      'amount': [''],
      'groupDueId': ['']
    });
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_REIMBURSE_DUES).subscribe(res => {
      this.resData = new AppResponse(res);
      // console.log("resdata:" + this.resData);
      setTimeout(() => {

        $('#mrListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,//this line added by aishwaryaa for hb-542
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 6,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });


  }

  //HB-589 fix by gopal start
  onKeyUpForAmount(event: any) {
    // console.log("===" + event.target.value);
    if (event.target.value != '' && (event.target.value > this.dueAmount || event.target.value < 1)) {
      this.invalidAmt = true;
      this.requireAmount=true;
      // console.log("if");
    } else {
      this.invalidAmt = false;
      this.requireAmount=false;
      // console.log("else");
    }
  }
  //HB-589 fix by gopal end 
  get reimbursement() { return this.editReimbursementFormGroup.controls; }

  // checkData(data){
  //   if(data.dueType == 'FINE'){
  //     this.router.navigate(['/viewcr', data.complianceRequestId]);
  //   }
  // }
  editReimbursement() {
    // console.log(JSON.stringify(this.editReimbursementFormGroup.value));
    if (this.editReimbursementFormGroup.invalid || this.requireAmount) {
      console.log("Invalid")
      return;
    } else {
      // console.log("========>" + JSON.stringify(this.editReimbursementFormGroup.value))
      this.httpService.update(this.editReimbursementFormGroup.value, environment.BASE_URL + ApplicationURIConstants.EDIT_REIMBURSEMENT)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // added by gopal for HB-548 on 07/12/2021 start
              $('#mrListDataTable').dataTable().fnDestroy();
              // added by gopal for HB-548 on 07/12/2021 end
              // console.log("INSIDE THE SECOND");
              $('.close').click();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.ngAfterViewInit();
              }, 2000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  fillgroupUserDueId(data) {
    this.editReimbursementFormGroup.reset();
    this.dueAmount = Math.abs(data.amount);
    console.log(data.amount);
    

    this.editReimbursementFormGroup.controls['groupUserDueId'].setValue(data.groupUserDueId);
    this.editReimbursementFormGroup.controls['amount'].setValue(-data.amount);
    this.editReimbursementFormGroup.controls['groupDueId'].setValue(data.groupDueId);
    this.editReimbursementFormGroup.controls['wavedOffAmount'].setValue(data.wavedOffAmount);
    this.editReimbursementFormGroup.controls['reimburseComment'].setValue(data.reimburseComment);
  }
}
