import { Component, OnInit } from "@angular/core";
import { formatDate } from "@angular/common";

import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { AppResponse } from "src/app/model/AppResponse";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { ApplicationURIConstants } from "projects/group-portal/src/app/utils/ApplicationURIConstants";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";
import { environment } from "projects/group-portal/src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ApplicationUtils } from "src/app/utils/ApplicationUtils";
import { debug, isFunction } from "util";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
import Swal from "sweetalert2";
// tslint:disable-next-line:no-duplicate-imports

import { throwToolbarMixedModesError } from "@angular/material";
var formDatahalf = new FormData();
var formDatafull = new FormData();
var formDatapaymentSetting = new FormData();
var formAmount = new FormData();
declare var Stripe: any;
var elements;

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
  providers: [
    NgxSpinnerService,

    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    // },

    // {provide: MAT_DATE_FORMATS, useValue: MYpdate_FORMATS},
  ],
})
export class SubscriptionComponent implements OnInit {
  userPayForm: FormGroup;

  card: FormGroup;
  halfcard: FormGroup;
  appResponse: AppResponse;
  resData: AppResponse;
  minimumAmountOfTotalAmount: number = 0;
  specialAssesmentCount: number = 0;
  requireAmount: boolean;
  showpaybtn: boolean = false;
  showupdatebtn: boolean = false;
  stripe: any;
  cards: any;

  // public min = new Date(2019, 12, 1, 0, 0);
  // public max = new Date();
  public minDateOfBirth = new Date(1925, 12, 1, 0, 0);
  public maxDateOfBirth = new Date(2005, 12, 1, 0, 0);

  firstDueId: any;
  map = new Map();
  mapOfBack = new Map();
  invalidMaxAmt: boolean;
  paymentSetting: FormGroup;
  paymentgate: FormGroup;

  showsuccessmessage: boolean = false;
  showdangermessage: boolean = false;
  alertmessage: any;

  paymentMessage: any;
  showpaymentsuccessmessage: boolean = false;
  showpaymentdangermessage: boolean = false;

  paygateupdateMessage: any;
  showpaygateupdatedangermessage: boolean = false;
  showpaygateupdatesuccessmessage: boolean = false;

  updateMessage: any;
  showupdatesuccessmessage: boolean = false;
  showupdatedangermessage: boolean = false;

  paymentTypeMessage: any;
  showpymentTypesuccessmessage: boolean = false;
  showpymentTypedangermessage: boolean = false;
  client_secret: any;
  elements: any;
  paymentstatus: string;

  todaydate: any;
  validatedate: any;
  montherror: boolean;
  yearerror: boolean;
  requirederror: boolean;
  duedate: string;
  hidepaytn: boolean = false;
  taxpercentage: any;
  taxamount: number;
  totalamount: any;
  cardinfo: any;
  taxinfo: any;
  subinfo: any;
  orgType: string;
  dedicatedphone: any;
  webhosting: any;
  virtualarc: any;
  virtualmanager: any;
  basiccheck: boolean;
  virtualmanagercheck: boolean;
  virtualarccheck: boolean;
  webhostingcheck: boolean;
  dedicatedcheck: boolean;
  premiumvirtualarc: any;
  premiumvirtualarccheck: boolean;
  domainaddonmonth: any;
  finalamount: number;
  currentmonth: any;
  domaindate: any;
  currentdate: Date;
  getmonth: any;
  finaldomainmonth: number;
  currentmonthdate: number;

  constructor(
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenService: TokenStorageService,
    private aroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private applicationUtils: ApplicationUtils
  ) {}

  ngOnInit() {
    this.aroute.queryParams.subscribe((params) => {
      this.paymentstatus = params["payment"];
      console.log(params);
    });
    console.log(this.paymentstatus);
    this.orgType = "";

    if (this.paymentstatus == "success") {
      this.savepaymentid();
    }
    // console.log("started")
    this.spinner.show();
    this.userPayForm = this.formBuilder.group({
      amount: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
    });

    // this.card = this.formBuilder.group({
    //   "card": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
    //   "month": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
    //   "year": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
    //   "cvv": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]]
    // });

    this.halfcard = this.formBuilder.group({
      month: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      year: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
    });

    this.paymentSetting = this.formBuilder.group({
      paymenttype: [""],
    });

    this.paymentgate = this.formBuilder.group({
      orgType: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      ssn: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(4),
        ],
      ],
      website: ["", [Validators.pattern(AppRegExConstants.WEBSITE)]],
      hoadesc: ["", [Validators.required, this.noWhitespaceValidator]],
      routingnumber: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(9),
        ],
      ],
      accountnumber: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.maxLength(17),
          Validators.minLength(17),
        ],
      ],
      legalfn: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
        ],
      ],
      legalln: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
        ],
      ],
      legalrole: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
          Validators.minLength(2),
        ],
      ],
    });

    this.userPayForm.controls["amount"].disable();
    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.GET_GROUP_SUBSCRIPTION_DETAILS
      )
      .subscribe((data) => {
        this.spinner.hide();
        // console.log(data);
        this.resData = new AppResponse(data);
        console.log(data.data);

        console.log(data.data[1]);

        // this.taxamount = data.data[1];

        this.subinfo = data.data[0];
        this.cardinfo = data.data[2];
        if (data.status === 401) {
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //  this.router.navigate(['home']);
        } else if (data.status == 200) {
          console.log(data);
          // console.log(data.data[0].dedicatedPhone);
          // console.log(data.data[0].webhosting);
          // console.log(data.data[0].virtualArc);
          // console.log(data.data[0].virtualManager);

          this.dedicatedphone = data.data[0].dedicatedPhone;
          if (this.dedicatedphone == "1") {
            this.dedicatedcheck = true;
            console.log(this.dedicatedcheck);
          } else {
            this.dedicatedcheck = false;
            console.log(this.dedicatedcheck);
          }
          this.webhosting = data.data[0].webhosting;
          if (this.webhosting == "1") {
            this.webhostingcheck = true;
            console.log(this.webhostingcheck);
          } else {
            this.webhostingcheck = false;
            console.log(this.webhostingcheck);
          }
          this.virtualarc = data.data[0].virtualArc;

          if (this.virtualarc == "1") {
            this.virtualarccheck = true;
            console.log(this.virtualarccheck);
          } else {
            this.virtualarccheck = false;
            console.log(this.virtualarccheck);
          }
          this.virtualmanager = data.data[0].virtualManager;
          if (this.virtualmanager == "1") {
            this.virtualmanagercheck = true;
            console.log(this.virtualmanagercheck);
          } else {
            this.virtualmanagercheck = false;
            console.log(this.virtualmanagercheck);
          }
          this.premiumvirtualarc = data.data[0].premiumVirtualarc;

          if (this.premiumvirtualarc == "1") {
            this.premiumvirtualarccheck = true;
            console.log(this.premiumvirtualarccheck);
          } else {
            this.premiumvirtualarccheck = false;
            console.log(this.premiumvirtualarccheck);
          }

          console.log(this.dedicatedphone);
          console.log(this.webhosting);
          console.log(this.virtualarc);
          console.log(this.virtualmanager);
          // this.editPayment()
          this.editPaymentGate();

          this.paymentchange(this.subinfo.paymentType);

          this.todaydate = formatDate(new Date(), "yyyy-MM-dd", "en_US");
          //  formatDate(this.subinfo.endPeriod,'yyyy-MM-dd','en_US');
          console.log(this.todaydate + "today");
          this.duedate = formatDate(
            this.subinfo.endPeriod,
            "yyyy-MM-dd",
            "en_US"
          );
          console.log(
            formatDate(this.subinfo.endPeriod, "yyyy-MM-dd", "en_US") + "dataaa"
          );

          if (
            this.subinfo.paymentType == "Manual" ||
            this.subinfo.paymentType == "manual"
          ) {
            if (this.todaydate >= this.duedate) {
              console.log("---today is greater----");
              console.log("---duedate is greater-----");

              this.hidepaytn = false;
            } else {
              console.log("---duedate is greater-----");

              this.hidepaytn = true;
            }
          }

          console.log(this.subinfo.pricePayable);
          console.log(this.subinfo.domainRegistrationdate);

          this.domainaddonmonth = this.subinfo.domainRegistrationdate;

          this.domaindate = new Date(this.domainaddonmonth);
          this.getmonth = this.domaindate.getMonth();

          this.finaldomainmonth = Number(this.getmonth) + 1;

          // console.log(this.finaldomainmonth + "finaldomainmonth");
          //  console.log(this.getmonth + "getmonth");
          //   console.log(this.domaindate + "domaindate");
          this.currentdate = new Date();

          this.currentmonth = this.currentdate.getMonth();

          this.currentmonthdate = Number(this.currentmonth) + 1;

          // console.log(this.currentmonthdate + "currentmonth");

          this.totalamount = Number(this.subinfo.pricePayable);

          // this.totalamount =
          // Number(this.subinfo.pricePayable) + Number(this.taxamount);
          // console.log(this.totalamount + "total amount");

          this.finalamount = Number(this.totalamount) - 20;

          //  console.log(this.finalamount + "finalamount amount");

          if (this.finaldomainmonth == this.currentmonthdate || true) {
            // console.log("insdie total amount if ");

            this.userPayForm.controls["amount"].setValue(
              this.totalamount.toFixed(2)
            );
          } else {
            // console.log("insdie final amount else ");

            this.userPayForm.controls["amount"].setValue(
              this.finalamount.toFixed(2)
            );
          }

          // this.totalamount = Number(this.subinfo.pricePayable);
          // console.log(this.totalamount + " total amount");

          // this.userPayForm.controls["amount"].setValue(
          //   this.totalamount.toFixed(2)
          // );

          // if (this.appResponse) {

          //   console.log(this.taxamount);
          //   console.log(this.subinfo.pricePayable);

          //   this.totalamount=Number(this.subinfo.pricePayable) + this.taxamount;
          //   console.log(this.totalamount + " total amount");

          //   this.userPayForm.controls['amount'].setValue(this.totalamount.toFixed(3));

          // }
        }
      });

    this.maxDateOfBirth = new Date();
    this.maxDateOfBirth.setFullYear(this.maxDateOfBirth.getFullYear() - 13);
  }

  cleardateOfBirthValue() {
    this.paymentgate.controls["dob"].setValue(null);
  }

  addpaymentgateway() {
    this.paymentgate.controls["dob"].setValue(null);
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  monthchanged($event) {
    this.requirederror = false;
    console.log($event.target.value);

    if ($event.target.value > 12) {
      this.montherror = true;
    } else {
      this.montherror = false;
    }
    if ($event.target.value == "") {
      this.montherror = false;
    }

    if ($event.target.value == 0) {
      this.montherror = true;
    }
  }

  yearchanged($event) {
    this.requirederror = false;
    console.log($event.target.value);

    this.todaydate = new Date();

    console.log(this.todaydate.getFullYear() + 10);
    if (
      this.todaydate.getFullYear() <= $event.target.value &&
      $event.target.value < this.todaydate.getFullYear() + 10
    ) {
      if (this.halfcard.controls["month"].value < this.todaydate.getMonth()) {
        this.yearerror = true;
      } else {
        this.yearerror = false;
      }
      this.yearerror = false;
    } else {
      this.yearerror = true;
    }
    if ($event.target.value == "") {
      this.yearerror = false;
    }

    if ($event.target.value == 0) {
      this.yearerror = true;
    }
  }

  deletecard() {
    if (
      confirm(
        "Are you sure you want to delete this card information? A delay/missed subscription payment may effect your access to admin portal"
      )
    ) {
      // console.log(id);
      this.httpService
        .get(environment.BASE_URL + ApplicationURIConstants.DELETE_CARDS)
        .subscribe((data) => {
          console.log(data);
          this.paymentTypeMessage = "Card Deleted Successfully";
          this.showpymentTypesuccessmessage = true;
          this.ngOnInit();
        });
    }
  }

  editcard() {
    console.log("edit");
  }

  savepaymentid() {
    console.log("inside payment id ");

    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.ADD_PAYMENT_CARD +
          "?payment=" +
          this.paymentstatus
      )
      .subscribe((data) => {
        console.log(data);
        this.router.navigate(["subscription"]);
        this.ngOnInit();
        this.paymentMessage = "Card Details Added Successfully";
        this.showpaymentsuccessmessage = true;
        setTimeout(() => {
          this.showpaymentsuccessmessage = false;
        }, 2000);
      });
  }
  createelement() {
    console.log("inside fun" + this.client_secret);
    console.log("inside fun" + this.cards);

    const { error } = this.stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element

      elements,
      confirmParams: {
        return_url: environment.refreshurl,
      },
    });

    // console.log(response + "gopal");

    console.log(error == undefined);

    if (error || error == undefined) {
      const messageContainer = document.querySelector("#error-message");
      messageContainer.textContent = error.message;
    } else {
      console.log("success");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.paymentMessage = "Card Details Added Successfully";
      this.showpaymentsuccessmessage = true;
    }

    // this.stripe.handleCardSetup(
    //   this.client_secret,this.cards,{
    //     payment_method_data: {

    //       billing_details: {
    //         name: 'Gopal',
    //       },
    //     },
    //   }
    // ).then(function(result) {
    //   console.log(result);
    //   if (result.error) {
    //     debug(result.error.message);
    //     console.log("error");

    //     // Display error.message in your UI.
    //   } else {
    //     debug('Setup succeeded');
    //     debug('Setup Intent Payment Method: ' + result.setupIntent.payment_method);
    //     // The setup has succeeded. Display a success message.
    //     // createCustomer(result.setupIntent.payment_method);
    //   }
    // }).catch(function(result){
    //   console.log(result);

    // });
  }

  addpaymentcard() {
    this.httpService
      .get(environment.BASE_URL + ApplicationURIConstants.ADD_PAYMENT_CARDS)
      .subscribe((data) => {
        console.log(data.data);

        this.client_secret = data.data.client_secret;
        this.stripe = new Stripe(environment.stripekey);
        // var elements = this.stripe.elements();
        // this.cards = elements.create("card");
        // this.cards.mount("#card-element");

        const options = {
          clientSecret: this.client_secret,
          // Fully customizable with appearance API.
          // appearance: {/*...*/},
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
        elements = this.stripe.elements(options);

        // Create and mount the Payment Element
        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");
      });
  }
  paymentchange(event) {
    console.log(event);
    if (this.subinfo.paymentType == event) {
      this.showupdatebtn = true;
    } else {
      this.showupdatebtn = false;
    }
  }

  ngAfterViewInit(): void {}

  get f() {
    return this.userPayForm.controls;
  }
  get c() {
    return this.card.controls;
  }
  get hc() {
    return this.halfcard.controls;
  }
  get pg() {
    return this.paymentgate.controls;
  }

  submitted = false;
  onSubmit() {
    if (this.subinfo.paymentId == null) {
      this.paymentMessage = "Payment Card is not added to the account";

      this.showpaymentdangermessage = true;

      setTimeout(() => {
        this.showpaymentdangermessage = false;
      }, 2000);
      return;
    }

    if (!this.resData.data[0].paymentType) {
      this.paymentMessage = "Payment Type is not selected";

      this.showpaymentdangermessage = true;
      return;
    }
    if (
      this.subinfo.paymentType == "Recurring" ||
      this.subinfo.paymentType == "recurring"
    ) {
      this.paymentMessage =
        "Payment Type is recurring if you want to pay now, Please change the payment type";

      this.showpaymentdangermessage = true;
      setTimeout(() => {
        this.showpaymentdangermessage = false;
      }, 2000);

      return;
    }

    if (
      confirm(
        "Tax amount plus an additional charge of 2.9% + 30 cents for payment processing will be added."
      )
    ) {
      formAmount.delete("amount");
      formAmount.append("amount", this.userPayForm.controls["amount"].value);

      this.httpService
        .save(
          formAmount,
          environment.BASE_URL + ApplicationURIConstants.MAKE_PAYMENT
        )
        .subscribe((data) => {
          console.log(data);

          if (data.status == 200) {
            this.paymentMessage = data.message;
            this.showpaymentsuccessmessage = true;
            this.showpaymentdangermessage = false;
            window.location.reload();
          } else {
            this.paymentMessage = data.message;
            this.showpaymentsuccessmessage = false;
            this.showpaymentdangermessage = true;
          }
        });
    }
  }

  // ssnumber($event){
  //   console.log($event.target.value.length);

  //   if($event.target.value.length=='0'){
  //     this.showssnerr=true;
  //     console.log(this.showssnerr);

  //   }else{
  //     this.showssnerr=false;
  //     console.log(this.showssnerr);

  //   }
  // }

  //     conform(){
  //   if(this.paymentgate.controls['accountnumber'].value==this.paymentgate.controls['conformnumber'].value){
  // this.conformaccount=true;
  //   }else{
  //     this.conformaccount=false;
  //   }
  // }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.paymentgate.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onPaymentSettingSubmit() {
    // this.findInvalidControls();

    formDatapaymentSetting.delete("paymenttype");

    formDatapaymentSetting.append(
      "paymenttype",
      this.paymentSetting.controls["paymenttype"].value
    );

    console.log(formDatapaymentSetting.get("paymenttype"));

    this.httpService
      .save(
        formDatapaymentSetting,
        environment.BASE_URL + ApplicationURIConstants.ADD_PAYMENT_TYPE
      )
      .subscribe((data) => {
        console.log(data);
        if (data.status == 200) {
          console.log(data.message);

          this.paymentTypeMessage = data.message;
          this.showpymentTypesuccessmessage = true;
          this.showpymentTypedangermessage = false;
          setTimeout(() => {
            this.showpymentTypesuccessmessage = false;
            this.showpymentTypedangermessage = false;
          }, 2000);
          console.log(this.paymentTypeMessage + "message");
          console.log(this.showpymentTypesuccessmessage + "show");

          this.ngOnInit();
          window.location.reload();
        } else {
          this.paymentTypeMessage = data.message;
          this.showpymentTypesuccessmessage = false;
          this.showpymentTypedangermessage = true;
        }
      });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onCardhalfSubmit() {
    if (this.montherror || this.yearerror) {
      return;
    }
    if (
      this.halfcard.controls["month"].value == "" ||
      this.halfcard.controls["year"].value == ""
    ) {
      this.requirederror = true;
      return;
    } else {
      this.requirederror = false;
    }

    formDatahalf.delete("month");
    formDatahalf.delete("year");
    formDatahalf.delete("fullupdate");
    formDatahalf.delete("monthyear");

    formDatahalf.append("month", this.halfcard.controls["month"].value);
    formDatahalf.append("year", this.halfcard.controls["year"].value);
    formDatahalf.append("fullupdate", "0");
    formDatahalf.append("monthyear", "1");

    this.httpService
      .save(
        formDatahalf,
        environment.BASE_URL + ApplicationURIConstants.ADD_PAYMENT_CARD
      )
      .subscribe((data) => {
        // console.log(data);
        if (data.status == 200) {
          this.updateMessage = data.message;
          this.showupdatesuccessmessage = true;
          this.showupdatedangermessage = false;
          this.ngOnInit();
          window.location.reload();
        } else {
          this.updateMessage = data.message;
          this.showupdatesuccessmessage = false;
          this.showupdatedangermessage = true;
          this.ngOnInit();
          window.location.reload();
        }
      });

    // console.log(formDatahalf.get['year']);
    // console.log(this.halfcard.value);
  }
  // resetForm(){
  //   // this.paymentgate.get('orgtype').reset()
  //   // this.paymentgate.get('dob').reset()
  //   // this.paymentgate.get('ssn').reset()
  //   // this.paymentgate.get('hoadesc').reset()
  //   // this.paymentgate.get('routingnumber').reset()
  //   this.paymentgate.get('accountnumber').reset()

  // }

  // finaldate(){
  //   var dateToday = new Date();
  //   var month = dateToday.getMonth() - 18;
  //   var day = dateToday.getDate();
  //   var year = dateToday.getFullYear();

  //   var maxDate = year + '-' + month + '-' + day;
  // }

  paymentGateSubmit() {
    this.spinner.show();

    if (!this.paymentgate.controls["hoadesc"].value.trim()) {
      Swal.fire("Please provide valid HOA Description");
      this.spinner.hide();
      return;
    }

    if (this.paymentgate.invalid) {
      console.log("invalid");
      Swal.fire("Please check the details");
      this.spinner.hide();

      return;
    }

    console.log(this.findInvalidControls());

    this.paymentgate.controls["legalrole"].setValue(
      this.paymentgate.controls["legalrole"].value.trim()
    );
    this.paymentgate.controls["legalln"].setValue(
      this.paymentgate.controls["legalln"].value.trim()
    );
    this.paymentgate.controls["legalfn"].setValue(
      this.paymentgate.controls["legalfn"].value.trim()
    );
    this.paymentgate.controls["hoadesc"].setValue(
      this.paymentgate.controls["hoadesc"].value.trim()
    );

    console.log("inside submit");
    console.log(this.paymentgate.value);
    this.httpService
      .save(
        this.paymentgate.value,
        environment.BASE_URL + ApplicationURIConstants.ADD_PAYMENT_GATEWAY
      )
      .subscribe((res) => {
        console.log(this.resData);

        if (res != undefined) {
          this.resData = new AppResponse(res);
          //  console.log();

          console.log(this.resData);

          if (this.resData.status == 200) {
            this.spinner.hide();

            console.log(this.resData);

            // console.log(res.data.message);
            // log

            this.submitted = false;

            //  this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            // //  this.router.navigate(['subscription']);

            if (this.resData.status == 200) {
              this.spinner.hide();

              setTimeout(() => {
                Swal.fire("Payment Gateway information Updated successfully");
              }, 3000);
              // this.paygateupdateMessage = this.resData.message;
              // this.showpaygateupdatesuccessmessage  = true;
              // this.showpaygateupdatedangermessage = false
              this.ngOnInit();
              // window.location.reload();
            } else {
              this.spinner.hide();
              Swal.fire("Payment Gateway information failed to Update");
              //  this.paygateupdateMessage = this.resData.message;
              //  this.showpaygateupdatesuccessmessage  = false;
              //  this.showpaygateupdatedangermessage = true
              this.ngOnInit();
              //  window.location.reload();
            }
          } else if (res.status == 401) {
            this.spinner.hide();

            this.router.navigate(["login"]);
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.message}`
            );
          }
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.message}`
          );
        }
      });
  }

  // editPayment(){

  //   this.paymentgate =this.formBuilder.group({
  //     // this.paymentgate.controls['firstName'].setValue(this.appResponse.data.firstName)

  //   })
  // }

  // Code added by aishwaryaa for hb-1222

  editPaymentGate() {
    console.log(this.subinfo.dob);
    let date = new Date(this.subinfo.dob);
    let timezoneOffset = date.getTimezoneOffset();
    date = new Date(date.getTime() + timezoneOffset * 60 * 1000);

    this.paymentgate = this.formBuilder.group({
      orgType: [this.subinfo.orgtype, [Validators.required]],
      dob: [date, [Validators.required]],
      ssn: [
        this.subinfo.ssn,
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(4),
        ],
      ],
      website: [
        this.subinfo.website,
        [Validators.pattern(AppRegExConstants.WEBSITE)],
      ],
      hoadesc: [
        this.subinfo.hoadesc,
        [Validators.required, this.noWhitespaceValidator],
      ],
      routingnumber: [
        this.subinfo.routingnumber,
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      accountnumber: [
        this.subinfo.accountnumber,
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      legalfn: [
        this.subinfo.legalFn,
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
        ],
      ],
      legalln: [
        this.subinfo.legalLn,
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
        ],
      ],
      legalrole: [
        this.subinfo.legalRole,
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.OCCUPATION),
          this.noWhitespaceValidator,
        ],
      ],
    });
  }

  // code end
}
