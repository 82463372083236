import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Observable } from 'rxjs';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-reimbursement',
  templateUrl: './add-reimbursement.component.html',
  styleUrls: ['./add-reimbursement.component.css']
})
export class AddReimbursementComponent implements OnInit {

  fromGroup: FormGroup;
  applyReimbursementFormGroup: FormGroup;
  resData: any;
  responseData: any;
  rongHo: boolean;
  homeAddressList = [];
  filteredHomes: Observable<String[]>;
  homeAddress = new FormControl('', [Validators.required]);
  dueAmount :number = 0;
  requireAmount : boolean;

  constructor(private httpService: HttpTransactionService,
    private router: Router,
    private commonService: CommonService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_HOME_LIST_BY_GROUP).subscribe(res => {
      //console.log(JSON.stringify(res));
      this.responseData = new AppResponse(res);
      // console.log(this.responseData.data);
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE,
          ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        if (res.status == 200) {
          //console.log(JSON.stringify(this.responseData.data.bylawsList));
          this.homeAddressList = this.responseData.data;

        } else if (res.status === 401) {
          //401 token related issue
          this.router.navigate(['login']);
        } else if (res.status == 403) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
          //this.router.navigate(['home']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
        }
      }
    });

    this.fromGroup = this.formBuilder.group({
      'homeProfileId': ['', [Validators.required]],
      'HoDetails': [''],
      'userProfileInfoId': ['', [Validators.required]],
    });

    this.applyReimbursementFormGroup = this.formBuilder.group({
      'reimburseComment': ['', [Validators.required]],
      'wavedOffAmount' : ['', [Validators.required]],
      'groupUserDueId' : ['', [Validators.required]],
      'amount':[''],
      'groupDueId': ['']
    });


    this.filteredHomes = this.homeAddress.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filterForHomes(value) : [])
      );
  }

  ngAfterViewInit(): void {
    this.TableInitialise();
  }

  get reimbursement() { return this.applyReimbursementFormGroup.controls; }

  private _filterForHomes(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.homeAddressList.filter(option => option.addressline1.toLowerCase().includes(filterValue));
  }

  displayFn(home: any): string {
    if (home.homeprofileid == undefined) {
      //this.homeAddress.setErrors({ invalid: true })
      return;
    } else {
      return home.addressline1;
    }
  }

  selectHoAddressChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.homeAddressList.length; i++) {
      if (this.homeAddressList[i].homeprofileid == selectedHome.homeprofileid) {
        this.fromGroup.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
        this.fromGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
          this.homeAddressList[i].lastname);
        this.fromGroup.controls['userProfileInfoId'].setValue(this.homeAddressList[i].userProfileInfoId);
      }
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;
      this.homeAddress.setErrors({ 'required': true });
    } else {
      this.rongHo = false;
      for (var i = 0; i < this.homeAddressList.length; i++) {
        if (this.homeAddressList[i].addressline1 === event.target.value) {

          this.fromGroup.controls['homeProfileId'].setValue(this.homeAddressList[i].homeprofileid);
          this.fromGroup.controls['userProfileInfoId'].setValue(this.homeAddressList[i].userProfileInfoId);
          this.fromGroup.controls['HoDetails'].setValue(this.homeAddressList[i].firstname + " " +
            this.homeAddressList[i].lastname);

          this.homeAddress.setErrors(null);
          this.rongHo = false;
        } else {
          this.fromGroup.controls['homeProfileId'].setValue(null);
          this.fromGroup.controls['HoDetails'].setValue(null);
          this.fromGroup.controls['userProfileInfoId'].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;
        }
      }
    }
  };

  onSubmit() {
    // console.log(JSON.stringify(this.fromGroup.value));

    if (this.fromGroup.invalid) {
      console.log("invalid")
      return;
    } else {
      console.log("Valid")

      this.httpService.save(this.fromGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_LIST_ALL_USERDUES)
        .subscribe((res) => {

          // console.log(res);

          if (res != undefined) {
            this.resData = new AppResponse(res);

            if (res.status == 200) {

              $('#dueListTable').dataTable().fnDestroy();
              setTimeout(() => {

                this.TableInitialise();
              }, 2000);  //3s

            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              $('#dueListTable').dataTable().fnDestroy();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });

    }
  }

  addReimbursement(){
    // console.log(JSON.stringify(this.applyReimbursementFormGroup.value));
    if (this.applyReimbursementFormGroup.invalid || this.requireAmount) {
      console.log("Invalid")
      return;
    } else {
      // console.log("========>"+JSON.stringify(this.applyReimbursementFormGroup.value))
      this.httpService.update(this.applyReimbursementFormGroup.value, environment.BASE_URL + ApplicationURIConstants.APPLY_REIMBURSEMENT)
      .subscribe((res) => {
        // console.log("INSIDE THE FIRST");
        if (res != undefined) {
          this.resData = new AppResponse(res);
          // console.log("RESPONSE:" + JSON.stringify(this.resData));
          if (res.status == 200) {
            // console.log("INSIDE THE SECOND");
            $('.close').click();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['reimbursedDues']);
            }, 2000);  //15s
          } else if (res.status == 401) {
            // console.log("INSIDE THE THIRD");
            this.router.navigate(['login']);
          } else {
            // console.log("INSIDE THE FOURTH");
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
    }
  }

  fillgroupUserDueId(data){
    this.dueAmount = Math.abs(data.amount);
    this.applyReimbursementFormGroup.controls['groupUserDueId'].setValue(data.groupUserDueId);
    this.applyReimbursementFormGroup.controls['amount'].setValue(-data.amount);
    this.applyReimbursementFormGroup.controls['groupDueId'].setValue(data.groupDueId);
  }

  TableInitialise() {
    $('#dueListTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  onKeyUpForAmount(event: any) {  
    if( event.target.value > this.dueAmount ){
      this.applyReimbursementFormGroup.setErrors({ 'invalid' : true});
     //this.applyReimbursementFormGroup.setErrors({ invalid: true });
     this.requireAmount = true;     
     this.reimbursement.wavedOffAmount.setErrors({'invalid' : true});
     
    }else{
     this.applyReimbursementFormGroup.setErrors(null);
     this.requireAmount = false;
     
    }
  }
  
}
