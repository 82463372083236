import { Component, OnInit } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AppResponse } from 'src/app/model/AppResponse';

import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { NgxSpinnerService } from "ngx-spinner";

import { Router, RouterEvent, NavigationStart,
  NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { environment } from 'projects/group-portal/src/environments/environment';

@Component({

  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.css'],
  providers: [NgxSpinnerService]
})


export class FaqAddComponent implements OnInit {

  faqForm: FormGroup;
  public Editor = ClassicEditor;
  appResponse: AppResponse;
  resData: AppResponse;
  content: any;
  discontent: any;

  public showOverlay = true;

constructor(private router: Router,private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService,
  private tokenService: TokenStorageService ,private spinner: NgxSpinnerService) {


   this.faqForm = this.formBuilder.group({
    "content": ['']
   });
 }

  ngOnInit() {

    // this.spinner.show();

    // this.httpService.get(environment.BASE_URL + ApplicationURIConstants.FAQ).subscribe(data => {
    //   this.appResponse = new AppResponse(data);

    //   console.log(JSON.stringify(data));

    //   this.spinner.hide();
    //   if( data.status === 200 && this.appResponse.data != null ){
    //       this.faqForm = this.formBuilder.group({
    //       "content": this.appResponse.data.content
    //      });
    //   }else{
    //     this.faqForm = this.formBuilder.group({
    //       "content": ''
    //      });
    //   }

    //  if (data.status === 401) {
    //     //401 token related issue
    //     this.tokenService.clearSession();
    //     this.router.navigate(['login']);
    //   } else if (data.status === 403) {
    //     //403 URL not accessible
    //     this.router.navigate(['home']);
    //   }
    // });


  }

  ngAfterViewInit(): void {
    this.spinner.show();

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.FAQ).subscribe(data => {
      this.appResponse = new AppResponse(data);

      // console.log(JSON.stringify(data));

      this.spinner.hide();
      if( data.status === 200 && this.appResponse.data != null ){
          this.faqForm = this.formBuilder.group({
          "content": this.appResponse.data.content
         });
      }else{
        this.faqForm = this.formBuilder.group({
          "content": ''
         });
      }

     if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });


  }
  public onChange({ editor }: ChangeEvent) {
    this.content = editor.getData();
    $("#submit").attr("disabled", false);
  }

  onSubmit() {
    this.httpService.save(this.faqForm.value, environment.BASE_URL + ApplicationURIConstants.SAVE_FAQ)
      .subscribe((res) => {
        if (res != undefined) {
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
             this.ngAfterViewInit();
            }, 2000);  //15s
          } else if (res.status == 401) {
            this.router.navigate(['login']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });

  }
}

ClassicEditor.create( document.querySelector( '#editor1' ) )
    .then( editor => {
        console.log( Array.from( editor.ui.componentFactory.names() ) );
    } )
    .catch( error => {
        console.error( error );
    } );

