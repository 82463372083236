//  Author of this file is Gopal
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppResponse } from 'src/app/model/AppResponse';
import { environment } from 'projects/group-portal/src/environments/environment';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-audit-sd',
  templateUrl: './audit-sd.component.html',
  styleUrls: ['./audit-sd.component.css'],
  providers: [NgxSpinnerService]
})
export class AuditSdComponent implements OnInit {
  reportFormGroup: FormGroup;
  resData: any;
  maxTodate: any = new Date();
  public minDate = new Date(2019, 12, 1, 0, 0);
  errorss: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  public show = false;
  currentDate=new Date();
  tempdate=new Date();
  data:any;

  firstDay = new Date(this.currentDate.setMonth(this.currentDate.getMonth()-1));
 filteredData=[];
  enablebtn: boolean=false;
  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router,
    private commonService: CommonService, private tokenService: TokenStorageService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.spinner.show();

    this.reportFormGroup = this.formBuilder.group({
      'startDate': ['', [Validators.required]],
      'endDate': ['', [Validators.required]]
    });
  
    // console.log(this.firstDay)
    this.reportFormGroup.controls['startDate'].setValue(this.firstDay);
    this.reportFormGroup.controls['endDate'].setValue(new Date());



  }

   ngAfterViewInit(): void {
    $('#sdListDataTable').dataTable().fnDestroy();


    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_DOCUMENT_BY_GROUP_CURRENT_DATA).subscribe(res => {

      this.spinner.hide();

      // let selectedMembers = members.filter(m => {
      //   if ( m.date > start && m.date < end) {
      //     return m;
      //   }

      //   });

      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {


        this.resData = new AppResponse(res);
        // console.log(this.resData);
        // console.log(formatDate(this.firstDay,'yyy-MM-dd','en-US') >= this.resData.data[16].createdDateTime);


         this.filteredData = this.resData.data.filter(m => {
        if ( m.createdDateTime >= formatDate(this.firstDay,'yyy-MM-dd','en-US') && m.createdDateTime <= formatDate(new Date(),'yyyy/MM/dd','en-US')) {
          return m;
        }

        });

        // console.log(this.filteredData);

        $('#sdListDataTable').dataTable().fnDestroy();
        setTimeout(() => {
          this.TableInitialise();
          this.spinner.hide();
        }, 2000);

        if (res.status === 401) {
          // console.log("401");
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          // console.log("403");
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          this.router.navigate(['home']);
        }
      }
    });


  }


  onSubmit() {

    if(this.reportFormGroup.controls['startDate'].value > this.reportFormGroup.controls['endDate'].value){
      this.enablebtn=true;
      // console.log(this.enablebtn);
      return;
      
    }else{
      this.enablebtn=false;
      // console.log(this.enablebtn);

    }
     
    console.log(this.reportFormGroup.controls['startDate'].value);
    console.log(this.reportFormGroup.controls['endDate'].value);
    $('#sdListDataTable').dataTable().fnDestroy();
    $("#messageBox").empty();
    // $("#sdListDataTable").empty();
    // this.spinner.show();
    if (this.reportFormGroup.invalid) {
      console.log("invalid")
      // this.spinner.hide();
      return;
    } else {
      // console.log(this.reportFormGroup.value);
      this.isValidDate = this.validateDates(this.reportFormGroup.controls['startDate'].value, this.reportFormGroup.controls['endDate'].value);
// console.log(this.isValidDate)
      if (this.isValidDate) {


        this.filteredData = this.resData.data.filter(m => {
          if ( m.createdDateTime >= formatDate(this.reportFormGroup.controls['startDate'].value,'yyy-MM-dd','en-US') && m.createdDateTime <= formatDate(this.reportFormGroup.controls['endDate'].value,'yyyy/MM/dd','en-US')) {
            return m;
          }

          });
          if (this.filteredData.length == 0) {

            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "No Record found");

            setTimeout(() => {
                // this.ngOnInit();

               },2000 );



        }        


          // console.log(this.filteredData);

            if (this.filteredData!=null) {


              $('#sdListDataTable').dataTable().fnDestroy();
              this.TableInitialise();

              setTimeout(() => {
              //   this.TableInitialise();

               }, );

              } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
            }

      } else {
        this.spinner.hide();
      }

    }
  }

  // mindate(){
  //   if(this.reportFormGroup.controls['startDate'].value > this.reportFormGroup.controls['endDate'].value){
  //     this.enablebtn=true;
  //     console.log(this.enablebtn);
      
  //   }else{
  //     this.enablebtn=false;
  //     console.log(this.enablebtn);

  //   }
  // }

  TableInitialise() {
    $('#sdListDataTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,
      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'To Date should be greater than From Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    } else if ((sDate != null && eDate != null) && (sDate) > (eDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'From Date should be less than To Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  saveData(){
    if (this.resData.status == 200) {
      localStorage.setItem("reportFormGroup", JSON.stringify(this.reportFormGroup.value));
      localStorage.setItem("requestReportData", JSON.stringify(this.resData));
    }
  }



}
