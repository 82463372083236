import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { AppRoutingModule } from './utils/app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './shared/pages/nav/nav.component';
import { HoListComponent } from './components/ho-list/ho-list.component';
import { GroupConfigListComponent } from './components/group-config-list/group-config-list.component';
import { HeaderComponent } from './shared/pages/header/header.component';
import { FooterComponent } from './shared/pages/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './auth-token/AuthGuard ';
import { ApplicationURIConstants } from './utils/ApplicationURIConstants';
import { ApplicationConstants } from './utils/ApplicationConstants';
import { JwtModule } from '@auth0/angular-jwt';
import { httpInterceptorProviders } from './auth-token/auth-interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { HoViewComponent } from './components/ho-view/ho-view.component';
import { HoPaymentListComponent } from './components/ho-payment-list/ho-payment-list.component';
import { HoPaymentViewComponent } from './components/ho-payment-view/ho-payment-view.component';
import { ByLawsAddComponent } from './components/by-laws-add/by-laws-add.component';
import { ByLawsListComponent } from './components/by-laws-list/by-laws-list.component';
import { ByLawsViewComponent } from './components/by-laws-view/by-laws-view.component';
import { AddPaymentConfigComponent } from './components/add-payment-config/add-payment-config.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AlertModule } from 'ngx-bootstrap';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserAddComponent } from './components/user-add/user-add.component';
import { HomeownerAddComponent } from './components/homeowner-add/homeowner-add.component';
import { FaqAddComponent } from './components/faq-add/faq-add.component';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomesListComponent } from './components/homes-list/homes-list.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { GroupMrListComponent } from './components/group-mr-list/group-mr-list.component';
import { ComplianceRequestListComponent } from './components/compliance-request-list/compliance-request-list.component';
import { GroupMrViewComponent } from './components/group-mr-view/group-mr-view.component';
import { GroupCrViewComponent } from './components/group-cr-view/group-cr-view.component';
import { SharedDocumentListComponent } from './components/shared-document-list/shared-document-list.component';
import { SharedDocumentArchivedListComponent } from './components/shared-document-archived-list/shared-document-archived-list.component';
import { SdViewComponent } from './components/sd-view/sd-view.component';
import { GroupDueListComponent } from './components/group-due-list/group-due-list.component';
import { GroupDueViewComponent } from './components/group-due-view/group-due-view.component';
import { ExternalUserListComponent } from './components/external-user-list/external-user-list.component';
import { ViewExternalUserComponent } from './components/view-external-user/view-external-user.component';
import { AuditListMrComponent } from './components/audit-list-mr/audit-list-mr.component';
import { AuditListCrComponent } from './components/audit-list-cr/audit-list-cr.component';
import { AuditListSdComponent } from './components/audit-list-sd/audit-list-sd.component';
import { AddGeneralUserComponent } from './components/add-general-user/add-general-user.component';
import { ListReimburseDuesComponent } from './components/list-reimburse-dues/list-reimburse-dues.component';
import { AddReimbursementComponent } from './components/add-reimbursement/add-reimbursement.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { EditExternalUserComponent } from './components/edit-external-user/edit-external-user.component';
import { ViewReimburseDueComponent } from './components/view-reimburse-due/view-reimburse-due.component';
import { EditPaymentConfigComponent } from './components/edit-payment-config/edit-payment-config.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { AuditComponent } from './components/audit/audit.component';
import { AuditViewCrComponent } from './components/audit-view-cr/audit-view-cr.component';
import { AuditViewMrComponent } from './components/audit-view-mr/audit-view-mr.component';
import { AuditSdComponent } from './components/audit-sd/audit-sd.component';
import { AuditSdViewComponent } from './components/audit-sd-view/audit-sd-view.component';
import { AuditSdHistoryComponent } from './components/audit-sd-history/audit-sd-history.component';
// added by hema shanker for HB-434 on 06/12/2021 Start
import { SdArchivedViewComponent } from './components/sd-archived-view/sd-archived-view.component';
// added by hema shanker for HB-434 on 06/12/2021 End
//added by aishwaryaa for hb-563 12/08/2021
import { EditAllocatedHomeComponent } from './components/edit-allocated-home/edit-allocated-home.component';
import { ViewAllocatedHomeComponent } from './components/view-allocated-home/view-allocated-home.component';
///code end
//////added by aishwaryaa for hb-467
import { AddRenterComponent } from './components/add-renter/add-renter.component';
// added by gopal for HB-480 start on 13/12/2021
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuditsdviewComponent } from './components/auditsdview/auditsdview.component';
import { PhoneverifyComponent } from './components/phoneverify/phoneverify.component';
// code added by aishwaryaa for hb-921
import { ExportHomesComponent } from './components/export-homes/export-homes.component';
import { ExternalOccupationListComponent } from './components/external-occupation-list/external-occupation-list.component';
import { SubscriptionHistoryComponent } from './components/subscription-history/subscription-history.component';
import { GroupEmailVerificationComponent } from './components/group-email-verification/group-email-verification.component';
// code end
// added by gopal for HB-480 end
///// code end
const providers = [
  HttpTransactionService,
  AuthGuard,
  httpInterceptorProviders,
  { provide: APP_BASE_HREF, useValue: ApplicationConstants.APP_BASE_HREF_GROUP_PORTAL }
]

export function tokenGetter() {
  return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.TOKEN_KEY]
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HoListComponent,
    GroupConfigListComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    HoViewComponent,
    HoPaymentListComponent,
    HoPaymentViewComponent,
    ByLawsAddComponent,
    ByLawsListComponent,
    ByLawsViewComponent,
    AddPaymentConfigComponent,
    ResetPasswordComponent,
    UserAddComponent,
    HomeownerAddComponent,
    FaqAddComponent,
    ForgotPasswordComponent,
    HomesListComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    GroupMrListComponent,
    ComplianceRequestListComponent,
    GroupMrViewComponent,
    GroupCrViewComponent,
    SharedDocumentListComponent,
    SharedDocumentArchivedListComponent,
    SdViewComponent,
    GroupDueListComponent,
    GroupDueViewComponent,
    ExternalUserListComponent,
    ViewExternalUserComponent,
    AuditListMrComponent,
    AuditListCrComponent,
    AuditListSdComponent,
    AddGeneralUserComponent,
    ListReimburseDuesComponent,
    AddReimbursementComponent,
    EditExternalUserComponent,
    ViewReimburseDueComponent,
    EditPaymentConfigComponent,
    SubscriptionComponent,
    // added by gopal for HB-474 start
    AuditComponent,
    AuditViewCrComponent,
    AuditViewMrComponent,
    AuditSdComponent,
    AuditSdViewComponent,
    AuditSdHistoryComponent,
    // added by gopal for HB-474 end
    // added by hema shanker for HB-434 on 06/12/2021 Start
    SdArchivedViewComponent,
    // added by hema shanker for HB-434 on 06/12/2021 End
    //added by aishwaryaa for hb-563 12/08/2021
    EditAllocatedHomeComponent,
    ViewAllocatedHomeComponent,
    ///////////////code end
    ////////////added by aishwaryaa for hb-467
    AddRenterComponent,
    AuditsdviewComponent,
    PhoneverifyComponent,
    // code added by aishwaryaa for hb-921
    ExportHomesComponent,
     //////code end
    // code added by hari HB-1015,1016
    ExternalOccupationListComponent,
     SubscriptionHistoryComponent,
     GroupEmailVerificationComponent
    //////code end
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    CKEditorModule,
    AlertModule,
    // added by gopal for HB-480 start on 13/12/2021
    NgIdleKeepaliveModule.forRoot(),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.WHITE_LISTED_DOMAINS],
        blacklistedRoutes: [environment.BLACK_LISTED_ROUTERS]
      }
    }),
    NgxCaptchaModule,
    ChartsModule,
    NgbModule,
    MatAutocompleteModule,
    MatInputModule,
    NgxSpinnerModule,
    Ng2TelInputModule
  ],
  providers: providers,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

@NgModule({})
export class GroupPortalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}

