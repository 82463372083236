//  Author of this file is Gopal
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Crisp } from "crisp-sdk-web";
declare let $: any;
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {


  showFiller = false;
  sidesize: any;
  rulerSizeClose: number = 180;
  rulerSizeOpen: number = 230;
  activeColor: string = "'red'";
  normalColor: string = "'pink'";
  initial: String;
  ///////////////////////////
  static: boolean = true;
  crstate: boolean = false;
  sdstate: boolean = false;
  nostate: boolean = false;
  nostatic: boolean = false;
  public sideNavState: boolean = false;
  public subCrOpen: boolean = false;
  public subSdOpen: boolean = false;
  public subNotOpen: boolean = false;
  public resview: boolean = false;
  public subOCCOpen: boolean = false;

  public profileopen: boolean = false;
  //////////////////////////
  nshover: boolean = false;
  @Output() messageEvent = new EventEmitter();
  constructor(private location: Location, public tokenService: TokenStorageService, private router: Router) { }
  userName: string;

  home: String;
  home_list: String;
  ho_list: String;
  external_user: String;
  group_config_list: String;
  list_all_mr: String;
  get_list_of_compliance_request: String;
  bylaws_list: String;
  view_faq: String;
  get_list_of_document_by_group: String;
  get_user_profile: String;
  homes = false
  add_general_user: String;
  list_due: String;
  list_reimbursed_due: String;
  group_subscription: String;//HB-655 Priya

  sendTitle(title: string) {
    this.messageEvent.emit(title);
  }

  //////////////////////////////////////////////
  isStatic() {
    this.static = !this.static
    this.nostatic = !this.nostatic

  }
  togglecr() {
    this.crstate = !this.crstate
  }
  togglesd() {
    this.sdstate = !this.sdstate
  }
  toggleno() {
    this.nostate = !this.nostate
  }
  //////////////////////////////////////////

  sinenavOpen() {
    this.sideNavState = !this.sideNavState
    // console.log(this.sideNavState)
    this.profileopen = this.profileopen
  }
  subComplainopen() {
    this.subCrOpen = !this.subCrOpen
  }
  subComplainclose() {
    this.subCrOpen = this.sideNavState
  }
  subSdopen() {
    this.subSdOpen = !this.subSdOpen
    // console.log(this.subSdOpen)
  }
  subSdClose() {
    this.subSdOpen = this.sideNavState
    // console.log(this.subSdOpen)
  }
  subNotopen() {
    this.subNotOpen = !this.subNotOpen
    // console.log(this.subNotOpen)
  }
  subNotclose() {
    this.subNotOpen = this.sideNavState
  }
  subOCCopen() {
    this.subOCCOpen = this.subOCCOpen
  }
  subOCCclose() {
    this.subNotOpen = this.sideNavState
  }

  toggleprofile() {
    this.profileopen = !this.profileopen
    // console.log(this.profileopen)
  }
  /////////////////////////////////////////

  ngOnInit() {
    this.userName = this.tokenService.getuserName();


    this.getShortName();
    $(document).ready(() => {

      if ($(window).width() < 521) {
        $('.sidebar').toggleClass('toggled');
        $('.sidebar .collapse').collapse('hide');
      }


      $(window).resize(() => {
        if ($(window).width() < 521) {
          $('.sidebar').toggleClass('toggled');
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $('#sidebarToggle, #sidebarToggleTop').on('click', () => {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $(window).resize(() => {
        if ($(window).width() < 768) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
          // tslint:disable-next-line: one-variable-per-declaration
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });

      $('.nav-link.active-link').closest('li.parent').addClass('active');

      var route = this.router.url;
      var splitRoute = route.split('/');
      if (splitRoute[1] === 'change-password') {
        $('#cpId').addClass('active');
      }

    });

    // var header = document.getElementById("accordionSidebar");
    // var btns = header.getElementsByClassName("nav-item");
    // for (var i = 0; i < btns.length; i++) {
    //   btns[i].addEventListener("click", function () {
    //     var current = document.getElementsByClassName("active");
    //     current[0].className = current[0].className.replace(" active", "");
    //     this.className += " active";
    //   });
    // }


    $(function () {
      $('#accordionSidebar > li').click(function (e) {
        e.stopPropagation();
        var $el = $('ul', this);
        $('#accordionSidebar > li > ul').not($el).slideUp();
        $el.stop(true, true).slideToggle(400);
      });
      $('#accordionSidebar > li > ul > li').click(function (e) {
        e.stopImmediatePropagation();
      });
    });
  }

  getShortName() {
    this.initial = this.userName.split(' ').map(n => n[0]).join('');
    // console.log(this.initial);
  }

  ngAfterViewInit(): void {

    var routerUrl = this.router.url;

    this.home = this.tokenService.getUrlTokenByKey('HOME');
    this.home_list = this.tokenService.getUrlTokenByKey('HOME_LIST');
    this.ho_list = this.tokenService.getUrlTokenByKey('HO_LIST');
    this.external_user = this.tokenService.getUrlTokenByKey('EXTERNAL_USER');

    if (this.home_list != null || this.ho_list != null || this.external_user != null) {
      this.homes = true;
    }

    this.group_config_list = this.tokenService.getUrlTokenByKey('GROUP_CONFIG_LIST');
    this.list_all_mr = this.tokenService.getUrlTokenByKey('LISTALL_MR');
    this.get_list_of_compliance_request = this.tokenService.getUrlTokenByKey('GET_LIST_OF_COMPLIANCE_REQUEST');
    this.bylaws_list = this.tokenService.getUrlTokenByKey('BYLAWS_LIST');
    this.view_faq = this.tokenService.getUrlTokenByKey('VIEW_FAQ');
    this.get_list_of_document_by_group = this.tokenService.getUrlTokenByKey('GET_LIST_OF_DOCUMENT_BY_GROUP');
    this.get_user_profile = this.tokenService.getUrlTokenByKey('GET_USER_PROFILE');
    this.add_general_user = this.tokenService.getUrlTokenByKey('ADD_EXTERNAL_USER');
    this.list_due = this.tokenService.getUrlTokenByKey('LIST_DUE');
    this.list_reimbursed_due = this.tokenService.getUrlTokenByKey('GET_ALL_REIMBURSED_DUES');
    this.group_subscription = this.tokenService.getUrlTokenByKey('GROUP_SUBSCRIPTION'); //HB-655 Priya

  }

  changePassword() {
    this.router.navigate(['change-password', this.tokenService.getuserProfileId()]);
  }

  doLogout() {

    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    
    this.router.navigate(['login']);
    Crisp.chat.hide()

  }

}

