import { Component, OnInit } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Router } from '@angular/router';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { ApplicationURIConstants } from 'projects/group-portal/src/app/utils/ApplicationURIConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-ho-list',
  templateUrl: './ho-list.component.html',
  styleUrls: ['./ho-list.component.css'],
  providers: [NgxSpinnerService]
})
export class HoListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  tempSelectedItems = [];
  tempSelectedRoles = [];
  ddl = [];
  ExternalBm:AppResponse;
  ExternalBmcount:any;
  editRoleForm: FormGroup;
  deleteHomeOwnerFormGroup: FormGroup;// added by aishwaryaa
  selectedEmail = String;
  l = 0;
  mess;// added by aishwaryaa
  isHOSelected : boolean = false;

   // added by Hema shanker HB-523 on 06/12/2021 Start
  BMcount:number=0;
  oldBM:number=0;
   // added by Hema shanker HB-523 on 06/12/2021 End
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private fb: FormBuilder, private commonService: CommonService) {
  }


  ngOnInit() {

    this.dropdownList = [
      // { rolemasterid: 1, rolename: 'SYSTEM ADMIN' },
      //{ roleMasterId: 2, roleName: 'GROUP ADMIN' },
      { roleMasterId: 3, roleName: 'BOARD MEMBER'},
      { roleMasterId: 4, roleName: 'ARC MEMBER' },
      // { roleMasterId: 5, roleName: 'MANAGEMENT COMPANY' },
      { roleMasterId: 6, roleName: 'HOME OWNER' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'roleMasterId',
      textField: 'roleName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  
    this.editRoleForm = this.fb.group({
      userProfileInfoId: [''],
      roles: [[], Validators.required],
    });
/////////////added by aishwaryaa bug-564 start///////////////////
    this.deleteHomeOwnerFormGroup = this.fb.group({
      'userProfileInfoId': ['', [Validators.required]],
    });
      /////////////added by aishwaryaa bug-564 start///////////////////
  }

  public onChange(data: any) {
    this.tempSelectedItems = [];
    let k = 0;
    this.ddl = data.roles;

    for (let i = 0; i < this.ddl.length; i++) {
      for (let j = 0; j < this.dropdownList.length; j++) {
        if (this.ddl[i] == this.dropdownList[j].roleName) {
          this.tempSelectedItems[k++] = this.dropdownList[j];
        }
      }
    }
    
    this.selectedItems = this.tempSelectedItems;
    this.selectedEmail = data.email;
    // console.log("selected items:"+this.selectedItems);
    this.editRoleForm.patchValue({
      userProfileInfoId: data.userProfileInfoId,
    });


    $("#HOName").val(data.firstName + " " + data.lastName);
    // $("#userProfileInfoId").val(data.userProfileInfoId);

  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.EXTERNAL_BM).subscribe(res => {
      this.ExternalBm = new AppResponse(res)
    this.ExternalBmcount=this.ExternalBm.data.length;
    
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.HOME_OWNERS).subscribe(res => {
      this.resData = new AppResponse(res);
      // console.log("-----"+JSON.stringify(this.resData));
       // added by Hema shanker HB-523 on 06/12/2021 Start
      for(let i in (this.resData.data))
      {
        if((this.resData.data[i].roles[0]=="BOARD MEMBER" || this.resData.data[i].roles[1]=="BOARD MEMBER" || this.resData.data[i].roles[2]=="BOARD MEMBER"))
        {
          this.oldBM=this.oldBM+1
       
        }
      }
      this.oldBM=this.oldBM+this.ExternalBmcount;
      this.BMcount=this.oldBM
      // added by Hema shanker HB-523 on 06/12/2021 End
      setTimeout(() => {
        $('#example').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,//this line added by aishwaryaa for hb-542
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],// this line modified by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }
  //////////////////////// added by aishwaryaa BUG-564 START//////////////
    fillFormGroupData( data ){
      // console.log(data);
  
      this.deleteHomeOwnerFormGroup = this.fb.group({
        'userProfileInfoId': [data.userProfileInfoId, [Validators.required]],
      });
    }
    //////////////////////////////BUG-564 END/////////////////////
  
  get f() { return this.editRoleForm.controls; }

  private editRole = environment.BASE_URL + ApplicationURIConstants.EDIT_ROLE

  submitted = false;
  onSubmit() {
    this.submitted = true;
    if (this.editRoleForm.invalid) {
      console.log("invalid")
      confirm("Please Select at least any one Role")
      return;
    } else {
       // added by Hema shanker HB-523 on 06/12/2021 Start
      this.tempSelectedRoles = this.editRoleForm.value.roles;
      for(var i=0; i<this.tempSelectedRoles.length; i++){
        if(this.tempSelectedRoles[i].roleMasterId == 3){
          this.BMcount=this.BMcount+1;
        }
      }
      // added by Hema shanker HB-523 on 06/12/2021 End
      //  console.log("----"+JSON.stringify(this.editRoleForm.value));
       this.tempSelectedRoles = this.editRoleForm.value.roles;
       //HB-262 Hevisa Patel 28-01-2021 - Start
       for(var i=0; i<this.tempSelectedRoles.length; i++){
         if(this.tempSelectedRoles[i].roleMasterId == 6)
          this.isHOSelected = true;
       }
       if(!this.isHOSelected){
        this.tempSelectedRoles.push({ roleMasterId: 6, roleName: 'HOME OWNER' });
       }
        //HB-262 Hevisa Patel 28-01-2021 - End
      //  console.log("---------"+JSON.stringify(this.editRoleForm.value));
       this.httpService.save(this.editRoleForm.value,  this.editRole)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
             $('.close').click();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.resData.message);
            $('#example').dataTable().fnDestroy();
            setTimeout(() => {
              // added by Hema shanker HB-523 on 06/12/2021 Start
              this.oldBM=0;
              this.BMcount=0;
              // added by Hema shanker HB-523 on 06/12/2021 End
              this.ngAfterViewInit();
            }, 1000);

           // location.reload();



          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
    //   this.modalService.dismissAll();
  }
  /////////////////////////////////ADDED BY AISHWARYAA BUG-564 START//////////////////
onDeleteSubmit(){
  // console.log(JSON.stringify(this.deleteHomeOwnerFormGroup.value));
    //this.spinner.show();
    if (this.deleteHomeOwnerFormGroup.invalid) {
      console.log("invalid")
     // this.spinner.hide();
      return;
    } else {
      $('.close').click();
      this.httpService.save(this.deleteHomeOwnerFormGroup.value, environment.BASE_URL + ApplicationURIConstants.DELETE_EXTERNAL_USER )
        .subscribe((res) => {
          if (res != undefined) {
           // this.resData = new AppResponse(res);
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {
              this.mess="HomeOwner Deleted Sucessfully !"
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE,this.mess);
             
              $('#example').dataTable().fnDestroy(); 
              setTimeout(() => {
                this.ngAfterViewInit();
              },200);  //2s
            } else if (res.status == 401) {
             // this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              //this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
            }
          } else {
            //this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }


  }
////////////////////////////added by aishwaryaa BUG-564 ENDED////////////////

}


