import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppResponse } from 'src/app/model/AppResponse';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
declare let $: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  captchaResponse: string;
  resData: AppResponse;
  flag : boolean = false;

  private forgetPassword = environment.BASE_URL + ApplicationURIConstants.FORGET_PASSWORD;

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  constructor(private router: Router, private formBuilder: FormBuilder, private tokenService: TokenStorageService, private applicationUtils : ApplicationUtils,private httpService: HttpTransactionService, private commonService: CommonService) {

    }

    forgetPwdForm: FormGroup;

    ngOnInit() {
      $(document).ready(() => {
        $('body').css('background-color', '#eee');
      });
      this.forgetPwdForm = this.formBuilder.group({
        "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      });
      this.addRecaptchaScript();
    }

    get f() { return this.forgetPwdForm.controls; }

    submitted = false;
    onSubmit() {
      if(this.captchaResponse == null){
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Fill all the details.");
      }
      if(this.flag){
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Captcha has expired. Please check the checkbox again.");
      }
      this.submitted = true;
      if (this.forgetPwdForm.invalid) {
        return;
      } else {

        if(this.captchaResponse == null){
          return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Fill all the details.");

        }

        if(this.flag){
          return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Captcha has expired. Please check the checkbox again.");
        }

        this.httpService.save(this.forgetPwdForm.value, this.forgetPassword)
        .subscribe((res) => {
          if(res != undefined){
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.forgetPwdForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['login']);
              }, 2000);  //15s
            } else if(res.status == 401){
              this.router.navigate(['login']);
            }else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          }else{
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });

      }
    }


    renderReCaptcha() {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : environment.ReCaptcha,
        'callback': (response) => {
          $("#messageBox").empty();
          this.flag = false;
          // console.log(response);
          this.captchaResponse = response;
      },
       'expired-callback': () => {
         this.flag = true;
        }
   
    });
    
    }

    addRecaptchaScript() {

      window['grecaptchaCallback'] = () => {
        this.renderReCaptcha();
      }

      (function(d, s, id, obj){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'recaptcha-jssdk', this));

    }

}
