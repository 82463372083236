import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupConfigListComponent } from '../components/group-config-list/group-config-list.component';
import { LoginComponent } from '../components/login/login.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AuthGuard } from '../auth-token/AuthGuard ';
import { HoListComponent } from '../components/ho-list/ho-list.component';
import { HoViewComponent } from '../components/ho-view/ho-view.component';
import { HoPaymentListComponent } from '../components/ho-payment-list/ho-payment-list.component';
import { HoPaymentViewComponent } from '../components/ho-payment-view/ho-payment-view.component';
import { ByLawsListComponent } from '../components/by-laws-list/by-laws-list.component';
import { ByLawsViewComponent } from '../components/by-laws-view/by-laws-view.component';
import { ByLawsAddComponent } from '../components/by-laws-add/by-laws-add.component';
import { AddPaymentConfigComponent } from '../components/add-payment-config/add-payment-config.component';
import { EditPaymentConfigComponent } from '../components/edit-payment-config/edit-payment-config.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { UserAddComponent } from '../components/user-add/user-add.component';
import { HomeownerAddComponent } from '../components/homeowner-add/homeowner-add.component';
import { FaqAddComponent } from '../components/faq-add/faq-add.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { HomesListComponent } from '../components/homes-list/homes-list.component';
import { EditProfileComponent } from '../components/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { GroupMrListComponent } from '../components/group-mr-list/group-mr-list.component';
import { ComplianceRequestListComponent } from '../components/compliance-request-list/compliance-request-list.component';
import { GroupMrViewComponent } from '../components/group-mr-view/group-mr-view.component';
import { GroupCrViewComponent } from '../components/group-cr-view/group-cr-view.component';
import { SharedDocumentListComponent } from '../components/shared-document-list/shared-document-list.component';
import { SharedDocumentArchivedListComponent } from '../components/shared-document-archived-list/shared-document-archived-list.component';
import { SdViewComponent } from '../components/sd-view/sd-view.component';
import { GroupDueListComponent } from '../components/group-due-list/group-due-list.component';
import { GroupDueViewComponent } from '../components/group-due-view/group-due-view.component';
import { ExternalUserListComponent } from '../components/external-user-list/external-user-list.component';
import { ViewExternalUserComponent } from '../components/view-external-user/view-external-user.component';
import { AuditListMrComponent } from '../components/audit-list-mr/audit-list-mr.component';
import { AuditListCrComponent } from '../components/audit-list-cr/audit-list-cr.component';
import { AuditListSdComponent } from '../components/audit-list-sd/audit-list-sd.component';
import { AddGeneralUserComponent } from '../components/add-general-user/add-general-user.component';
import { ListReimburseDuesComponent } from '../components/list-reimburse-dues/list-reimburse-dues.component';
import { AddReimbursementComponent } from '../components/add-reimbursement/add-reimbursement.component';
import { EditExternalUserComponent } from '../components/edit-external-user/edit-external-user.component';
import { ViewReimburseDueComponent } from '../components/view-reimburse-due/view-reimburse-due.component';
import { SubscriptionComponent } from '../components/subscription/subscription.component';
import { AuditComponent } from '../components/audit/audit.component';
import { AuditSdComponent } from '../components/audit-sd/audit-sd.component';
import { AuditSdHistoryComponent } from '../components/audit-sd-history/audit-sd-history.component';
import { AuditViewMrComponent } from '../components/audit-view-mr/audit-view-mr.component';
import { AuditSdViewComponent } from '../components/audit-sd-view/audit-sd-view.component';
import { AuditViewCrComponent } from '../components/audit-view-cr/audit-view-cr.component';
// added by hema shanker for HB-434 on 06/12/2021 Start
import { SdArchivedViewComponent } from '../components/sd-archived-view/sd-archived-view.component';
// added by hema shanker for HB-434 on 06/12/2021 End
//added by aishwaryaa for hb-563 12/08/2021
import { ViewAllocatedHomeComponent } from '../components/view-allocated-home/view-allocated-home.component';
import { EditAllocatedHomeComponent } from '../components/edit-allocated-home/edit-allocated-home.component';
//code end
//added by aishwaryaa for hb-563
import { AddRenterComponent } from '../components/add-renter/add-renter.component';
import { PhoneverifyComponent } from '../components/phoneverify/phoneverify.component';
///code end
// code added by aishwaryaa for hb-921
import { ExportHomesComponent } from '../components/export-homes/export-homes.component';
import { ExternalOccupationListComponent } from '../components/external-occupation-list/external-occupation-list.component';
import { SubscriptionHistoryComponent} from '../components/subscription-history/subscription-history.component';

// code end
import { GroupEmailVerificationComponent } from '../components/group-email-verification/group-email-verification.component';


const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'ho-list', component: HoListComponent, canActivate: [AuthGuard] },
  { path: 'view-HomeOwner/:id', component: HoViewComponent, canActivate: [AuthGuard] },
  { path: 'ga-config-list', component: GroupConfigListComponent, canActivate: [AuthGuard] },
  { path: 'HO-payment-list', component: HoPaymentListComponent, canActivate: [AuthGuard] },
  { path: 'view-HoPayment/:id', component: HoPaymentViewComponent, canActivate: [AuthGuard] },
  { path: 'byLaws-list', component: ByLawsListComponent, canActivate: [AuthGuard] },
  { path: 'bylaws-view/:id', component: ByLawsViewComponent, canActivate: [AuthGuard] },
  { path: 'bylaws-add', component: ByLawsAddComponent, canActivate: [AuthGuard] },
  { path: 'add-payment-config', component: AddPaymentConfigComponent, canActivate: [AuthGuard] },
  { path: 'edit-payment-config/:id', component: EditPaymentConfigComponent },
  { path: 'view-HoPayment', component: HoPaymentViewComponent, canActivate: [AuthGuard] },
  { path: 'ga-config-list', component: GroupConfigListComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'user-add', component: UserAddComponent },
  { path: 'homeowner-add', component: HomeownerAddComponent },
  { path: 'faq', component: FaqAddComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'tokens/:token', component: ResetPasswordComponent },
  { path: 'homes', component: HomesListComponent },
  { path: 'change-password/:id', component: ChangePasswordComponent },
  { path: 'maintenanceRequests', component: GroupMrListComponent },
  { path: 'compliance-request', component: ComplianceRequestListComponent },
  { path: 'viewcr/:id', component: GroupCrViewComponent },
  { path: 'maintenanceRequest/:id', component: GroupMrViewComponent },
  { path: "shared-document-list", component: SharedDocumentListComponent },
  { path: "shared-document-archived-list", component: SharedDocumentArchivedListComponent },
  { path: 'viewshareddocument/:id', component: SdViewComponent },
  { path: 'groupDues', component: GroupDueListComponent },
  { path: 'groupDue/:id', component: GroupDueViewComponent },
  { path: 'externaluser-list', component: ExternalUserListComponent },
  { path: 'viewexternaluser/:id', component: ViewExternalUserComponent },
  { path: 'maintenanceRequestHistory/:id', component: AuditListMrComponent, canActivate: [AuthGuard] },
  { path: 'complianceRequestHistory/:id', component: AuditListCrComponent, canActivate: [AuthGuard] },
  { path: 'sharedDocumentHistory/:id', component: AuditListSdComponent, canActivate: [AuthGuard] },
  { path: 'addGeneralUser', component: AddGeneralUserComponent },
  { path: 'reimbursedDues', component: ListReimburseDuesComponent },
  { path: 'addreimbursment', component: AddReimbursementComponent },
  { path: 'editExternalUser/:id', component: EditExternalUserComponent },
  { path: 'externalOccupationList', component: ExternalOccupationListComponent },

  { path: 'reimburseDues/:id', component: ViewReimburseDueComponent },
  { path: 'subscription', component: SubscriptionComponent },

  // added by gopal for HB-474 start
  { path: 'audit', component: AuditComponent },
  { path: 'auditSd', component: AuditSdComponent },
  { path: 'auditSdView/:id', component: AuditSdViewComponent },
  { path: 'auditSdHistory/:id', component: AuditSdHistoryComponent },
  { path: 'auditViewCr/:id', component: AuditViewCrComponent },
  { path: 'auditViewMr/:id', component: AuditViewMrComponent },
  { path: 'auditSdView/:id', component: AuditSdViewComponent },
  // added by gopal for HB-474 end
  // added by hema shanker for HB-434 on 06/12/2021 Start
  { path: 'viewarchivedshareddocument/:id', component: SdArchivedViewComponent },
  // added by hema shanker for HB-434 on 06/12/2021 End
  //added by gopal for aishwaryaa's bug for hb-563 12/08/2021 start
  { path: 'view-allocated-home/:id', component: ViewAllocatedHomeComponent },
  { path: 'edit-allocated-home/:id', component: EditAllocatedHomeComponent },
  /// code end
  ///added by aishwaryaa for hb-467
  { path: 'add-renter/:id', component: AddRenterComponent },
  {path: 'phoneverify', component:PhoneverifyComponent},
  //code end
  // code added by aishwaryaa for hb-921
  {path:'export-homes',component:ExportHomesComponent},
  {path:'subscriptionhistory',component:SubscriptionHistoryComponent},
  // code end
  {path:'verifytokens/:token',component:GroupEmailVerificationComponent},
  { path: '**', component: LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
