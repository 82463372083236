import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from 'projects/group-portal/src/app/utils/ApplicationURIConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-view-allocated-home',
  templateUrl: './view-allocated-home.component.html',
  styleUrls: ['./view-allocated-home.component.css']
})
export class ViewAllocatedHomeComponent implements OnInit {

  homeProfileId : string;
  appResponse: AppResponse;
  resData: AppResponse;
  renterresponse:AppResponse;
  userProfileInfoId:string;
  detailedOriginalDoc:any = [];
  tempdata=[];
  fileNameContentType: string;
  fileNameExtension: string;
  map = new Map();
  isOnRentDisable: boolean;//added by aishwaryaa for hb-467
  showrenterinfo: boolean;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.userProfileInfoId = params.id;
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.HOME_OWNER +this.userProfileInfoId ).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log(this.appResponse);
      // console.log(this.appResponse.data.homeProfileId[0]);
      // console.log(this.appResponse.data.email);
      if(this.appResponse.status=200){
        this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_RENTER_INFO_HO + "/" + this.userProfileInfoId).subscribe(data => {

          console.log(data);
this.renterresponse=new AppResponse(data);
console.log(this.renterresponse.data.length);
if(this.renterresponse.data.length==0){

  this.showrenterinfo=false;

}else{

  this.showrenterinfo=true;
}

 
for(var i=0; i<this.renterresponse.data.documentName.length; i++){
  // console.log("docName:"+this.appResponse.data.documentName[i])
  this.map.set(this.renterresponse.data.documentName[i].substr(14),this.renterresponse.data.documentName[i]);
}
if(this.renterresponse.data.originalDoc){
 
  for(let i=0; i<this.renterresponse.data.originalDoc.length; i++) {
    this.detailedOriginalDoc.push({
      "docUploadedBy": this.renterresponse.data.docUploadedBy[i],
      "originalDoc": this.renterresponse.data.originalDoc[i],
      "createdOn" : this.renterresponse.data.createdOn[i]
    });
    
  }
}

          // if(this.renterresponse.status=200){

          // }
          
          // this.appResponse = new AppResponse(data);
          // // console.log("data"+JSON.stringify(this.appResponse.data))
        
          // for(var i=0; i<this.appResponse.data.documentName.length; i++){
          //   // console.log("docName:"+this.appResponse.data.documentName[i])
          //   this.map.set(this.appResponse.data.documentName[i].substr(14),this.appResponse.data.documentName[i]);
          // }
          // if(this.appResponse.data.originalDoc){
           
          //   for(let i=0; i<this.appResponse.data.originalDoc.length; i++) {
          //     this.detailedOriginalDoc.push({
          //       "docUploadedBy": this.appResponse.data.docUploadedBy[i],
          //       "originalDoc": this.appResponse.data.originalDoc[i],
          //       "createdOn" : this.appResponse.data.createdOn[i]
          //     });
              
          //   }
          // }
          // // console.log("detailedOriginalDoc:"+JSON.stringify(this.detailedOriginalDoc));
     
    
          // if (data.status === 401) {
          //   //401 token related issue
          //   this.tokenService.clearSession();
          //   this.router.navigate(['login']);
          // } else if (data.status === 403) {
          //   //403 URL not accessible
          //   this.router.navigate(['home']);
          // }
        });
      }
      
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible 
        this.router.navigate(['home']);
      }
    });

      // Added by aishwaryaa for HB-467
    if(this.appResponse.data.email != null){
      this.isOnRentDisable =true;
      
     }
     else{
       this.isOnRentDisable=false;
     }
    //  code end 
  }

  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
    ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
      this.resData = new AppResponse(data);

      //Start the code for Base64 to byte[]
      const byteCharacters = atob(this.resData.data);

      const byteNumbers = new Array(byteCharacters.length);
       for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

     let byteArray = new Uint8Array(this.resData.data);

      const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

       for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

         const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

     const blob = b64toBlob(this.resData.data, this.fileNameContentType);
      //End the code for Base64 to byte[]

     //Save the file
      FileSaver.saveAs(blob, documentName);
    });
}

}
