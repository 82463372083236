import { Component, OnInit } from '@angular/core';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {
  }
  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }

}
