import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown-angular7/multiselect.model';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import * as $ from 'jquery';
@Component({
  selector: 'app-edit-external-user',
  templateUrl: './edit-external-user.component.html',
  styleUrls: ['./edit-external-user.component.css']
})
export class EditExternalUserComponent implements OnInit {
  
  userProfileInfoId : string;
  appResponse: AppResponse;
  resData: AppResponse;
  userRegistrationForm: FormGroup;
  isError : boolean = true;
  countryCode : any;
  intlobj : any;
  flag : boolean = false;
  isOccupationShow : boolean;
  occupationList:any[] = [];
  guUserForm: FormGroup;
  submitted = false;
  mailFlag : boolean = false;
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router,private formBuilder: FormBuilder, private tokenService: TokenStorageService,  private commonService: CommonService) { }

  ngOnInit() {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_DATA_MR).subscribe(res => {

      this.resData = new AppResponse(res);

      this.occupationList = this.resData.data.occupations;


      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });


    this.userRegistrationForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      //"contact": ['', []],
      "email": ['', [ Validators.required,Validators.pattern(AppRegExConstants.EMAIL)]],
      "role" : ['', [Validators.required]],
      "externaluseroccupationId" : [''],
     

    });
   
    this.activatedRoute.params.subscribe(params => {
      this.userProfileInfoId = params.id;
    });
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_EXTERNAL_USER+'/' + this.userProfileInfoId).subscribe(data => {
      this.appResponse = new AppResponse(data);
       this.fillTextData();
       this.intlobj.setNumber(this.appResponse.data.contact);
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

    this.guUserForm = this.formBuilder.group({
      "externaluseroccupationId" : ['',[Validators.required]]
    })

  }

  

  get f() { return this.userRegistrationForm.controls; }

  get g() { return this.guUserForm.controls;}

  fillTextData(){
    this.intlobj.setNumber(this.appResponse.data.contact);
    this.userRegistrationForm = this.formBuilder.group({
      "firstName": [this.appResponse.data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": [this.appResponse.data.lastName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
     // "contact": [this.appResponse.data.contact, [Validators.required]],
      "email": [this.appResponse.data.email, [ Validators.required,Validators.pattern(AppRegExConstants.EMAIL)]],
      "role" : [this.appResponse.data.roles, [Validators.required]],
     // "externaluseroccupationId" : [this.appResponse.data.externaluseroccupationId],

    });
   
    this.intlobj.setNumber(this.appResponse.data.contact);
    if(this.appResponse.data.roles == 'GENERAL USER'){
      // console.log(";;;;;"+JSON.stringify(this.appResponse.data));
      this.isOccupationShow = true;
      this.guUserForm = this.formBuilder.group({
        "externaluseroccupationId" : [this.appResponse.data.externalUserOccuptionId,[Validators.required]]
      })
    }
    
  }

 onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
    this.intlobj.setNumber('');
    else
    this.flag = true;
    
  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

telInputObject(obj) {
  // console.log(obj);
  this.intlobj = obj;
  this.flag = true;
}

checkForError(obj){

  this.myInput.nativeElement.blur();
  $("#phoneInput").focus();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else   
       return false;
  }
  return true;

}

changeType(event: any){

  if(event.target.value == 'GENERAL USER'){
    this.isOccupationShow = true;
  }else{
    this.isOccupationShow = false;
  }
}

private users = environment.BASE_URL + ApplicationURIConstants.EDIT_EXTERNAL_USER;

onSubmit(){
  // console.log("=="+this.isOccupationShow);
  // console.log("--"+this.guUserForm.invalid);
  if (this.userRegistrationForm.invalid  || (this.isOccupationShow && this.guUserForm.invalid) || !this.isError) {
     return;
   } else {
    // console.log("---"+this.intlobj.getNumber());
     //this.userRegistrationForm.controls['contact'].setValue(this.intlobj.getNumber());
     //this.userRegistrationForm.controls['externaluseroccupationId'].setValue(this.guUserForm.value.externaluseroccupationId);
    //this.userRegistrationForm.controls['userProfileInfoId'].setValue(this.userProfileInfoId);
     var formData = new FormData();
    //  console.log("--"+this.appResponse.data.email);
    //  console.log("--"+this.userRegistrationForm.value.email);
    if((this.appResponse.data.email != this.userRegistrationForm.controls['email'].value) || (this.appResponse.data.roles !== this.userRegistrationForm.controls['role'].value))
        formData.append("flag","true");
     else
         formData.append("flag","false");

      
      formData.append("contact",this.intlobj.getNumber());
      formData.append("userProfileInfoId",this.userProfileInfoId);
      formData.append("firstName",this.userRegistrationForm.controls['firstName'].value);
       formData.append("lastName",this.userRegistrationForm.controls['lastName'].value);
       
       if(this.userRegistrationForm.controls['role'].value == 'GENERAL USER'){
        formData.append("externaluseroccupationId",this.guUserForm.value.externaluseroccupationId);
     
       }
       formData.append("email",this.userRegistrationForm.controls['email'].value);
       formData.append("role",this.userRegistrationForm.controls['role'].value);
         
       
     this.httpService.save(formData, this.users)
      .subscribe((res) => {
        if(res != undefined){
         this.resData = new AppResponse(res);
          if (res.status == 200) {
            this.submitted = false;
           
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
            setTimeout(() => {
              this.router.navigate(['externaluser-list']);
            }, 2000);  //15s
         } else if(res.status == 401){
           this.router.navigate(['logout']);
          }else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
        }
      });
   }


}
}
