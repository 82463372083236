
import { ApplicationURIConstants as BaseApplicationURIConstants } from 'src/app/utils/ApplicationURIConstants';

export class ApplicationURIConstants extends BaseApplicationURIConstants {


  // Base Url
  static readonly BASE_URL = 'http://localhost:9090/group-portal';
  static readonly TEST_BASE_URL = 'http://192.168.1.184:9090/group-portal';

  // group configs
  static readonly GROUP_CONGIGS = '/groupConfigs';
  static readonly HOME_OWNERS = '/homeOwners';
  static readonly EXTERNAL_BM  = '/externalBm';
  static readonly HOME_OWNER = '/homeOwners/';
  static readonly HO_PAYMENTS = '/hoPayments';
  static readonly HO_PAYMENT = '/hoPayments/';
  static readonly BY_LAWS = '/byLaws';
  static readonly BY_LAW = '/byLaws/';
  static readonly ADD_BY_LAWS = '/addByLaws';
  static readonly EDIT_BY_LAW = '/editByLaws';
  static readonly HOME = '/home';
  static readonly FORGET_PASSWORD = '/forgetPassword';
  static readonly GET_USER_PROFILE = "/get_user_profile";
  static readonly EDIT_USER_PROFILE = "/edit-profile";
  static readonly GET_REQUEST_REPORT = "/getrequestreport";
  static readonly LIST_MR_REVIEW = "/listMRReview/";
  // code added by aishwaryaa for hb-921
  static readonly HOME_OWNERS_LIST="/homeOwnerslist";
  // code end
  ////////////////////////////////added by aishwaryaa for hb-563 12/08/2021
  static readonly DELETEHOMES = "/deletehomes";
  static readonly RESEND_EMAIL_TO_HO = "/resendemailtoho";

  ////////////////////////////////////code end
  // added by gopal for HB-563 on 30/12/2021
  static readonly EDIT_USER_HOMES_PROFILE = "/editHomesProfile";


  // Router Authentication
  static readonly WHITE_LISTED_DOMAINS = 'http://localhost:9090/group-portal';
  static readonly BLACK_LISTED_ROUTERS = 'http://localhost:9090/group-portal/signin';

  static readonly USER_REGISTRATION = '/addUser';
  static readonly GROUP_LIST = '/groupList';

  static readonly GROUP_CONFIG = '/groupConfig';
  static readonly GROUP_ADD_GROUP_CONFIG = '/addGroupConfig';

  static readonly ADD_GROUP_DUE = '/addGroupDue';
  static readonly EDIT_GROUP_DUE = '/editGroupDue';
  static readonly FAQ = '/faq';
  static readonly SAVE_FAQ = '/addFaq';

  static readonly EDIT_ROLE = '/editRole';
  static readonly EDIT_OCCUPATION = '/editOccupation';


  static readonly TOKEN = '/tokens/';
  static readonly RESET_PASSWORD = '/resetPassword';

  static readonly ADD_HO_EXCEL = '/homesRegister';
  static readonly ASSIGN_HO = '/assignHome';
  static readonly HOMES = '/homes';

  static readonly GROUP_CONFIG_UPLOAD_LOGO = '/uploadLogo';
  static readonly DELETE_BYLAW = '/byLaws/';
  static readonly CHANGE_PASSWORD = '/changePassword/';
  static readonly MR_LIST = '/maintenanceRequests';
  static readonly MAINTENANCE_REQUEST = '/maintenanceRequest/';
  static readonly GET_FILE = '/getFile';
  static readonly GET_SD_FILE = '/getSDFile';

  static readonly VIEW_COMPLIANCE_REQUESTS = '/viewcompliancerequest';
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_label = "/getlistofcompliancerequest";
  static readonly GET_LIST_OF_COMPLIANCE_REQUEST_BY_HO = ApplicationURIConstants.GET_LIST_OF_COMPLIANCE_REQUEST_label + "?includeGroup=true";

  static readonly GET_LIST_OF_DOCUMENT_BY_GROUP_CURRENT_DATA = "/getlistofdocumentbygroup?isArchived=false";
  static readonly GET_LIST_OF_DOCUMENT_BY_GROUP_OLD_DATA = "/getlistofdocumentbygroup?isArchived=true";
  static readonly VIEW_SHARED_DOCUMENT = "/viewshareddocument/";
  static readonly LISTING_SHARED_DOCUMENT = "/shared-document-list";
  static readonly LISTING_ARCHIVED_SHARED_DOCUMENT = "/shared-document-archived-list";

  static readonly GET_ALL_HOMES = "/getallhomelistbygroup";
  static readonly GET_ALL_DUES = "/groupDues";
  static readonly GET_SINGLE_DUE = "/groupDues/";

  static readonly EXTERNAL_USERS = "/externaluser";
  static readonly VIEW_EXTERNAL_USER = "/externaluserview";
  static readonly IS_ON_RENT = "/isonrent";
  static readonly RENTER_INFORMATION_ADD_BY_GA = "/addRenterByGA";
  static readonly GET_AUDIT_LIST = "/getAuditList";
  static readonly GET_AUDIT_LIST_SD = "/getAuditListsd";
  static readonly ADD_EXTERNAL_USER = "/addExternalUser";
  static readonly ADD_OCCUPATION = "/addOccupation";
  static readonly GET_OCCUPATION = "/getOccupation";
  static readonly DELETE_OCCUPATION = "/deleteOccupation";
  static readonly DELETE_DUE = "/deleteDue/";


  static readonly GET_RENTER_INFO_HO = "/getrenterinfobyho";


  static readonly GET_DATA_MR = "/getrequireddataForMR";

  static readonly GET_ALL_REIMBURSE_DUES = "/getAllReimursedDues";

  static readonly GET_ALL_HOME_LIST_BY_GROUP = "/getallhomelistbygroup";

  static readonly GET_LIST_ALL_USERDUES = "/getListOfAllUserDues";
  static readonly APPLY_REIMBURSEMENT = "/applyReimbursement";
  static readonly EDIT_REIMBURSEMENT = "/editReimbursement";

  static readonly DELETE_EXTERNAL_USER = "/deleteexternaluser";

  static readonly GET_UNASSIGNED_HOME_LIST = "/getunassignedhomelist";

  static readonly CHECK_ADDRESS_ASSIGNED_OR_NOT = "/checkaddressassignedornot";
  static readonly EDIT_EXTERNAL_USER = "/editExternalUser";

  static readonly GET_SINGLE_REIMBURSE_DUE = "/reimbursedDues/";

  static readonly GET_GROUP_SUBSCRIPTION_DETAILS = "/getGroupSubscriptionDetails";

  static readonly ADD_PAYMENT_CARD = "/addpaymentcard";
  static readonly ADD_PAYMENT_CARDS = "/addpaymentcards";
  static readonly DELETE_CARDS = "/deletecard";



  static readonly ADD_PAYMENT_TYPE = "/addpaymenttype";

  static readonly MAKE_PAYMENT = "/makepayment";

  static readonly GROUP_SUBSCRIPTION = "/subscription";

  static readonly GET_OTP = "/getotp";
  static readonly VERIFY_OTP = "/verifyotp";

  static readonly GET_GROUP_SUBSCRIPTION_HISTORY = "/getGroupSubscriptionHistory";

  static readonly ADD_PAYMENT_GATEWAY ="/addPaymentGateway";



  static readonly UPDATE_GROUP_GENERAL_COMMENT = "/updategroupGeneralComment";
  static readonly DELETE_GROUP_GENERAL_COMMENT = "/deletegroupGeneralComment";
  static readonly ADD_GROUP_GENERAL_COMMENT = "/addgroupgeneralcomment";
  static readonly GET_GROUP_GENERAL_COMMENT = "/getgroupgeneralcomment";



  static readonly ADD_BYLAW_EXCEL = '/addbylawexcel';

  static readonly VIEW_EMAIL_TOKEN_GA ='/verifytokens/'

}

