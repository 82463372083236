import { Component, OnInit } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Router } from '@angular/router';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { ApplicationURIConstants } from 'projects/group-portal/src/app/utils/ApplicationURIConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-external-user-list',
  templateUrl: './external-user-list.component.html',
  styleUrls: ['./external-user-list.component.css'],
  providers: [NgxSpinnerService]
})
export class ExternalUserListComponent implements OnInit {
  [x: string]: any;

  dataTable: any;
  resData: AppResponse;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  tempSelectedItems = [];
  ddl = [];
  editRoleForm: FormGroup;
  selectedEmail = String;
  deleteExternalUserFormGroup: FormGroup;

  constructor(private httpService: HttpTransactionService,
     private router: Router, 
     private tokenService: TokenStorageService, 
     private spinner: NgxSpinnerService,
     private commonService: CommonService,
     private formBuilder: FormBuilder) {
  }


  ngOnInit() {
    this.spinner.show();
    this.deleteExternalUserFormGroup = this.formBuilder.group({
      'userProfileInfoId': ['', [Validators.required]],
    });

  }

  
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.EXTERNAL_USERS).subscribe(res => {
      
      if (res.status === 200) {
        this.resData = new AppResponse(res);
        // console.log("externaluserList"+JSON.stringify(this.resData));

        setTimeout(() => {
          $('#externaluser').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            stateSave: true,//this line added by aishwaryaa for hb-542
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 4,
              orderable: false
            },
            ]
          });

          this.spinner.hide();
        }, 200);

      }else if (res.status === 401) {
        //401 token related issue
        this.spinner.hide();
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.spinner.hide();
        this.router.navigate(['home']);
      }
    });
  }

  fillFormGroupData( data ){
    // console.log(data);

    this.deleteExternalUserFormGroup = this.formBuilder.group({
      'userProfileInfoId': [data.userProfileInfoId, [Validators.required]],
    });
  }

  onSubmit() {
    // console.log(JSON.stringify(this.deleteExternalUserFormGroup.value));
    this.spinner.show();
    if (this.deleteExternalUserFormGroup.invalid) {
      console.log("invalid")
      this.spinner.hide();
      return;
    } else {
      $('.close').click();
      this.httpService.save(this.deleteExternalUserFormGroup.value, environment.BASE_URL + ApplicationURIConstants.DELETE_EXTERNAL_USER )
        .subscribe((res) => {
          if (res != undefined) {
           // this.resData = new AppResponse(res);
            // console.log("Response ->>" + JSON.stringify(res));
            if (res.status == 200) {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${res.message}`);
             
              $('#externaluser').dataTable().fnDestroy(); 
              setTimeout(() => {
                this.ngAfterViewInit();
              },200);  //2s
            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.MAXIMUM_FILE_SIZE);
          }
        });
    }


  }

}
