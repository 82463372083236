import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationConstants } from "src/app/utils/ApplicationConstants";

import { AppRegExConstants } from "../../utils/AppRegExConstants";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "projects/group-portal/src/environments/environment";

declare var $: any;

@Component({
  selector: "app-edit-allocated-home",
  templateUrl: "./edit-allocated-home.component.html",
  styleUrls: ["./edit-allocated-home.component.css"],
  providers: [NgxSpinnerService],
})
export class EditAllocatedHomeComponent implements OnInit {
  homeProfileId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  userProfileInfoId: string;
  responseData: AppResponse;
  requestData: AppResponse;
  userEditForm: FormGroup;
  isFlag: string;
  isGULoggedIn: String;
  tokenData: String[] = [];
  fullName: string;
  show: boolean;

  enableAddress: boolean;
  isExternalUser: Boolean = false;
  intlObj: any;
  contactNum: any;
  isError: boolean = false;
  countryCode: any;
  countryCodeLength: any;
  IsFlag: boolean = true;
  flag: boolean = false;
  resetFlag: boolean = false;
  mess;

  userProfileInfoIdURL =
    environment.BASE_URL + ApplicationURIConstants.EDIT_USER_HOMES_PROFILE;

  @ViewChild("phoneInpt2", { static: false }) phoneInpt2: ElementRef;
  @ViewChild("myInput", { static: true }) myInput: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private router: Router,
    private tokenService: TokenStorageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.userProfileInfoId = params.id;
    });
    this.enableAddress = false;
    this.userEditForm = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      addressLine1: ["", Validators.required],
      city: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      state: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE),
        ],
      ],
      zipCode: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(5),
          Validators.maxLength(6),
        ],
      ],
      email: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      userProfileInfoId: [this.userProfileInfoId, [Validators.required]],
    });

    // console.log(this.findInvalidControls());

    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.HOME_OWNER +
          this.userProfileInfoId
      )
      .subscribe((data) => {
        this.appResponse = new AppResponse(data);
        // console.log(this.appResponse);

        // console.log(this.appResponse.data.firstName);
        // console.log(this.appResponse.data.email);
        // console.log(this.appResponse.data.contact);
        this.userEditForm.controls["firstName"].setValue(
          this.appResponse.data.firstName
        );
        this.userEditForm.controls["email"].setValue(
          this.appResponse.data.email
        );
        this.userEditForm.controls["lastName"].setValue(
          this.appResponse.data.lastName
        );
        this.userEditForm.controls["addressLine1"].setValue(
          this.appResponse.data.addressLine1
        );
        this.userEditForm.controls["city"].setValue(this.appResponse.data.city);
        this.userEditForm.controls["zipCode"].setValue(
          this.appResponse.data.zipCode
        );
        this.userEditForm.controls["state"].setValue(
          this.appResponse.data.state
        );
        this.contactNum = this.appResponse.data.contact;
        // console.log(this.contactNum);

        this.intlObj.setNumber(this.contactNum);
        $("#phoneInput").focus().val(this.contactNum);
        this.myInput.nativeElement.blur();
        this.resetFlag = true;
        this.isError = false;
        this.hasError(Object);

        this.userEditForm.controls["userProfIleInfoId"].setValue(
          this.appResponse.data.userProfIleInfoId
        );

        if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });
  }

  onSubmit() {
    this.intlObj.getNumber();
    //   this.spinner.show();

    //     console.log("----->> EditUser Valid <<------")

    var formData = new FormData();
    formData.append("contact", this.intlObj.getNumber());
    // formData.append("contact",'9787676032');
    formData.append(
      "userProfileInfoId",
      this.userEditForm.controls["userProfileInfoId"].value
    );
    formData.append("firstName", this.userEditForm.controls["firstName"].value);
    formData.append("lastName", this.userEditForm.controls["lastName"].value);
    formData.append(
      "addressLine1",
      this.userEditForm.controls["addressLine1"].value
    );
    formData.append(
      "addressForCommunication",
      this.appResponse.data.addressForCommunication
    );
    formData.append("city", this.userEditForm.controls["city"].value);
    formData.append("zipCode", this.userEditForm.controls["zipCode"].value);
    formData.append("state", this.userEditForm.controls["state"].value);
    formData.append("gender", this.appResponse.data.gender);
    formData.append("IsFlag", "1");

    //     console.log("----->> EditUser Valid <<------")
    // console.log(formData.get('firstName'));

    if (
      (this.isGULoggedIn = localStorage.getItem("isGULoggedIn")) ||
      this.isExternalUser
    ) {
      // console.log(JSON.stringify(this.userEditForm.value));
      // console.log("===2If")
      // console.log(formData);
      this.userEditForm.controls["isExternalUser"].setValue(1);
      this.httpService
        .save(formData, this.userProfileInfoIdURL)
        .subscribe((resp) => {
          // console.log(resp)
          this.requestData = new AppResponse(resp);

          if (this.requestData.status == 200) {
            this.mess = "Edited Successfully!";
            this.tokenData = JSON.parse(
              localStorage.getItem(ApplicationConstants.USER_PORTAL)
            );
            var saPortal = {
              session: [],
            };

            saPortal.session.push({
              [ApplicationConstants.TOKEN_KEY]:
                this.tokenData[0][ApplicationConstants.TOKEN_KEY],
              [ApplicationConstants.USER_PROFILE_INFO_ID_KEY]:
                this.tokenData[0][
                  ApplicationConstants.USER_PROFILE_INFO_ID_KEY
                ],
              [ApplicationConstants.EMAIL_KEY]:
                this.tokenData[0][ApplicationConstants.EMAIL_KEY],
              [ApplicationConstants.FULL_NAME_KEY]:
                this.userEditForm.controls["firstName"].value +
                " " +
                this.userEditForm.controls["lastName"].value,
              [ApplicationConstants.GROUP_ID_KEY]:
                this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
              [ApplicationConstants.URL_TOKENS_LIST_KEY]:
                this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
              [ApplicationConstants.ROLES]:
                this.tokenData[0][ApplicationConstants.ROLES],
              [ApplicationConstants.GROUP_NAME]:
                this.tokenData[0][ApplicationConstants.GROUP_NAME],
              [ApplicationConstants.ADDRESS]:
                this.tokenData[0][ApplicationConstants.ADDRESS],
              [ApplicationConstants.GROUP_CONFIGS_KEY]:
                this.tokenData[0][ApplicationConstants.GROUP_CONFIGS_KEY],
            });

            window.localStorage.setItem(
              ApplicationConstants.USER_PORTAL,
              JSON.stringify(saPortal.session)
            );

            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.SUCCESS_LABLE,
              this.mess
            );
            setTimeout(() => {
              // console.log("<<>>>" + (this.isGULoggedIn == localStorage.getItem("isGULoggedIn")));
              // console.log("<<>>>" + (this.isGULoggedIn != localStorage.getItem("isGULoggedIn")));
              if (
                !(this.isGULoggedIn = localStorage.getItem("isGULoggedIn")) &&
                this.isExternalUser
              ) {
                this.router.navigate([
                  localStorage.getItem(
                    ApplicationConstants.DASHBOARD_URL_LABEL
                  ),
                ]);
              } else {
                this.router.navigate(["dashboardgu"]);
              }
            }, 5000);
          } else {
            console.log(this.requestData.errors);
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.requestData.errors}`
            );
          }
        });
    } else {
      // console.log("===2Else")
      // console.log("isGULoggedIn" + this.isGULoggedIn)
      // console.log(formData)
      // console.log(this.userProfileInfoIdURL);

      this.httpService
        .save(formData, this.userProfileInfoIdURL)
        .subscribe((resp) => {
          // console.log(resp);

          this.requestData = new AppResponse(resp);
          // console.log(this.requestData)

          if (this.requestData.status == 200) {
            this.requestData.message =
              "Home profile has been edited Successfully";
            // console.log(this.requestData.message);

            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.SUCCESS_LABLE,
              this.requestData.message
            );
            // console.log(this.requestData.message);
            // this.requestData.message="Home profile has been edited Successfully"
            // console.log(this.requestData.message);

            // setTimeout(() => {
            //   this.spinner;
            // this.ngOnInit();
            // this.router.navigate(['homes']);
            // }, 2000);

            this.tokenData = JSON.parse(
              localStorage.getItem(ApplicationConstants.USER_PORTAL)
            );
            var saPortal = {
              session: [],
            };
            saPortal.session.push({
              [ApplicationConstants.TOKEN_KEY]:
                this.tokenData[0][ApplicationConstants.TOKEN_KEY],
              [ApplicationConstants.USER_PROFILE_INFO_ID_KEY]:
                this.tokenData[0][
                  ApplicationConstants.USER_PROFILE_INFO_ID_KEY
                ],
              [ApplicationConstants.EMAIL_KEY]:
                this.tokenData[0][ApplicationConstants.EMAIL_KEY],
              [ApplicationConstants.FULL_NAME_KEY]:
                this.userEditForm.controls["firstName"].value +
                " " +
                this.userEditForm.controls["lastName"].value,
              [ApplicationConstants.GROUP_ID_KEY]:
                this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
              [ApplicationConstants.URL_TOKENS_LIST_KEY]:
                this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
              [ApplicationConstants.ROLES]:
                this.tokenData[0][ApplicationConstants.ROLES],
              [ApplicationConstants.GROUP_NAME]:
                this.tokenData[0][ApplicationConstants.GROUP_NAME],
              [ApplicationConstants.ADDRESS]:
                this.tokenData[0][ApplicationConstants.ADDRESS],
              [ApplicationConstants.GROUP_CONFIGS_KEY]:
                this.tokenData[0][ApplicationConstants.GROUP_CONFIGS_KEY],
            });
            window.localStorage.setItem(
              ApplicationConstants.USER_PORTAL,
              JSON.stringify(saPortal.session)
            );

            setTimeout(() => {
              this.router.navigate([
                localStorage.getItem(ApplicationConstants.DASHBOARD_URL_LABEL),
              ]);
            }, 5000);
          } else {
            console.log(this.requestData.errors);
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.requestData.errors}`
            );
          }
        });
    }
  }

  get f() {
    return this.userEditForm.controls;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.userEditForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  telInputObject(obj) {
    console.log("telinout object : " + obj);

    this.intlObj = obj;
    // console.log("-------"+JSON.stringify(obj));
    this.flag = true;
    this.countryCode = obj.dialCode;
    console.log("telinout object2 : " + this.countryCode);
    // obj.setNumber(this.responseData.data.contact);
  }

  onCountryChange(country: any) {
    // console.log("=======" + country.dialCode);
    this.countryCode = country.dialCode;
    if (this.flag) {
    }
    // this.intlObj.setNumber('');
    else this.flag = true;
  }

  hasError(obj) {
    this.isError = obj;
    console.log("hasError: ", obj);
  }

  getNumber(obj) {
    // console.log("*******" + JSON.stringify(obj));
  }

  test(event: any) {
    // console.log("----ttttt");
  }

  checkForError(obj) {
    console.log("check for error : " + JSON.stringify(obj));
    this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021
    console.log("check for error2 : " + this.resetFlag);
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  numberOnly(event): boolean {
    console.log(event);
    console.log(event.which ? event.which : event.keyCode);

    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) || // Excludes numbers
      charCode == 43 // Includes the plus sign
    ) {
      if (
        charCode == 40 ||
        charCode == 41 ||
        charCode == 45 ||
        charCode == 32 ||
        charCode == 43
      )
        return true;
      else return false;
    }
    return true;
  }
}
