import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AppResponse } from 'src/app/model/AppResponse';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Validators,FormBuilder,FormGroup } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-group-mr-view',
  templateUrl: './group-mr-view.component.html',
  styleUrls: ['./group-mr-view.component.css']
})
export class GroupMrViewComponent implements OnInit {

  maintenanceRequestId: string;
  // maintenanceRequest: MaintenanceRequest;
  appResponse: AppResponse;
  resData: AppResponse;
  requestDocumentId: string;
  documentName: string;
  fileNameContentType: string;
  fileNameExtension: string;
  rdAppResponse: AppResponse;
  detailedOriginalDoc: any = [];
  map = new Map();
  updategeneralCommentForm: any;
  generalCommentForm: any;
  refId: any;
  resData2: any;
  generalCommentList: any;
  userprofileInfoId: string;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private http: HttpClient, private router: Router, private tokenService: TokenStorageService, private commonService: CommonService,private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.maintenanceRequestId = params.id;
      this.refId=this.maintenanceRequestId;
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.MAINTENANCE_REQUEST + this.maintenanceRequestId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      // console.log(this.appResponse);


      for (var i = 0; i < this.appResponse.data.documentName.length; i++) {
        // console.log("docName:" + this.appResponse.data.documentName[i])
        this.map.set(this.appResponse.data.documentName[i].substr(14), this.appResponse.data.documentName[i]);
      }
      if (this.appResponse.data.originalDoc) {

        for (let i = 0; i < this.appResponse.data.originalDoc.length; i++) {
          // console.log(this.appResponse.data.createDateTime);

          this.detailedOriginalDoc.push({
            "docUploadedBy": this.appResponse.data.docUploadedBy[i],
            "originalDoc": this.appResponse.data.originalDoc[i],
            "createdOn": this.appResponse.data.createDateTime,
          });

        }
        // console.log(this.detailedOriginalDoc);
      }



      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
    this.userprofileInfoId = this.tokenService.getuserProfileId();
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
    });
    this.getComment();

  }
  
  getComment() {
    this.httpService
      .save(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.GET_GROUP_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            this.resData2 = new AppResponse(res);
            // console.log("======="+this.resData2.data);
            this.generalCommentList = this.resData2.data;
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData2.errors}`
            );
          }
        }
      });
    this.generalCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["MR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() {
    return this.generalCommentForm.controls;
  }

  onSubmit() {
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      // console.log(JSON.stringify(this.generalCommentForm.value));

      this.httpService
        .save(
          this.generalCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.ADD_GROUP_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  
  textvisible(generalCommentId){
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editComment = 1;
      }
    }

  }

  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GROUP_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
  }

  deleteCommentById(generalComment){


    this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if(confirm("Are you sure you want to delete comment")){
      this.httpService
      .update(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.DELETE_GROUP_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });

    }
}

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + this.map.get(documentName)).subscribe(data => {
        this.resData = new AppResponse(data);

        //Start the code for Base64 to byte[]
        const byteCharacters = atob(this.resData.data);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(this.resData.data);

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(this.resData.data, this.fileNameContentType);
        //End the code for Base64 to byte[]

        //Save the file
        FileSaver.saveAs(blob, documentName);
      });
  }

}
