import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-compliance-request-list',
  templateUrl: './compliance-request-list.component.html',
  styleUrls: ['./compliance-request-list.component.css'],
  providers: [NgxSpinnerService]
})
export class ComplianceRequestListComponent implements OnInit {

  resData: AppResponse;
  constructor(private httpService: HttpTransactionService,
     private router: Router,
     private commonService: CommonService, 
     private tokenService: TokenStorageService,
     private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.spinner.show()
  }

  ngAfterViewInit(): void {
    
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_LIST_OF_COMPLIANCE_REQUEST_BY_HO).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.spinner.hide();
      if(res == undefined){       
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM); 
      }else{
        this.resData = new AppResponse(res);
        // console.log(JSON.stringify(this.resData.data));
        setTimeout(() => {
          $('#complianceRequestTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            stateSave: true,//this line added by aishwaryaa for hb-542
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],// this line modified by aishwaryaa for hb-542
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 4,
              orderable: false
            },
          ]
          });
        }, 200);
        if (res.status === 401) {
          // console.log("401");
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          // console.log("403");
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          this.router.navigate(['home']);
        }
      }
     
    });

  }

}
