import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'projects/group-portal/src/environments/environment';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-homeowner-add',
  templateUrl: './homeowner-add.component.html',
  styleUrls: ['./homeowner-add.component.css'],
  providers: [NgxSpinnerService]
})
export class HomeownerAddComponent implements OnInit {

  addHoForm: FormGroup;
  submitted = false;
  addHoExcelForm: FormGroup;
  isExternalUser: boolean;
  isChecked: false;
  filteredHomes: Observable<String[]>;
  homeAddress = new FormControl('', [Validators.required]);
  rongHo: boolean;
  responseData: AppResponse;
  addressAssinged = true;
  flag: boolean = true;
  // added by gopal for HB-449 
  excelfilename: any;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private spinner: NgxSpinnerService) { }


  groupName = ['Parking (arc)', 'Design Guideline (bylaw)', 'Temporary Structures (covenent)', 'Mailboxes (bylaw)', 'Limitation on Size and Location of Structures (arc)'];
  homeAddressList = [];
  filteredOptions: Observable<String[]>;
  // myControl = new FormControl();
  isSingleUser: boolean;
  myControl = new FormControl('', [Validators.required]);
  fileUploadList: string[] = [];
  private hoAddExcelUrl = environment.BASE_URL + ApplicationURIConstants.ADD_HO_EXCEL;
  private assignHOUrl = environment.BASE_URL + ApplicationURIConstants.ASSIGN_HO;

  resData: AppResponse;

  ngOnInit() {

    this.spinner.show();
    this.addHoForm = this.formBuilder.group({
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "addressLine1": ['', [Validators.required]],
      // "isExternalUser": [''],
    });

    this.addHoExcelForm = this.formBuilder.group({
      "file": ['', [Validators.required]],
    });

    this.isSingleUser = false;
    this.isExternalUser = false;
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value) : [])
      );

    this.filteredHomes = this.homeAddress.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filterForHomes(value) : [])
      );
    // $( "#isSingleUser" ).prop( "checked", true );


    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_UNASSIGNED_HOME_LIST).subscribe(res => {
      //console.log(JSON.stringify(res));
      this.responseData = new AppResponse(res);
      // console.log(this.responseData)
      if (res == undefined) {
        this.spinner.hide();
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        if (res.status == 200) {

          this.homeAddressList = this.responseData.data;

        } else if (res.status === 401) {
          //401 token related issue
          this.spinner.hide();
          this.router.navigate(['login']);
        } else if (res.status == 403) {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`);
          this.router.navigate(['home']);
        } else {
          this.spinner.hide();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
        }
      }
    });
    this.spinner.hide();
  }

  get addHoform() { return this.addHoForm.controls; }

  get addHoExcelform() { return this.addHoExcelForm.controls; }

  // getDocument(){
  //   this.httpService.downloadtemplate().subscribe((response: ArrayBuffer) => this.download(response, 'application/pdf', 'SampleTemplate.pdf'))
  // }
  TableInitialiseexport() {
    $('#reportTable').DataTable({
      "bPaginate": false,
      "bLengthChange": false,
      "bFilter": false,
      "bInfo": false,
      "bAutoWidth": false

    });
  }

  fileName = 'SampleTemplate.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    $('#reportTable').dataTable().fnDestroy();
    setTimeout(() => {
      this.TableInitialiseexport();
      this.spinner.hide();
    }, 2000);
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'][5] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
   download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], {type: fileType});
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.groupName.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterForHomes(value: string): String[] {
    const filterValue = value.toLowerCase();
    return this.homeAddressList.filter(option => option.addressLine1.toLowerCase().includes(filterValue));
  }

  displayFn(home: any): string {
    if (home != null) {
      return home.addressLine1;
    }
    return null;
  }

  changeFunction() {
    if ($("#isSingleUser").is(":checked")) {
      this.isSingleUser = true;
    } else {
      this.isSingleUser = false;
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;
      this.homeAddress.setErrors({ 'required': true });
    } else {
      this.rongHo = false;
      for (var i = 0; i < this.homeAddressList.length; i++) {
        if (this.homeAddressList[i].addressLine1 == event.target.value) {
          this.homeAddress.setErrors(null);
          this.addHoForm.controls['addressLine1'].setValue(this.homeAddressList[i].addressLine1);
          this.rongHo = false;
          break;
        } else {
          this.addHoForm.controls['addressLine1'].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;
        }
      }
      // console.log(this.addHoForm.value)
    }
  };


  selectHoAddressChangeHandler(selectedHome) {
    this.addHoForm.controls['addressLine1'].setValue(selectedHome.addressLine1);
  }

  // file upload data manupulated in on change evenet
  onFileSelect(event) {
    // this.addHoExcelForm.controls['file'].setValue(null);

    this.flag = true;
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
//added by gopal for HB-449 on 14/12/2021
    if (event.target.files.length > 0) {
      this.excelfilename = event.target.files[0].name;
      // console.log(event.target.files[0].name);
    }
  }
  //code end 

  onSubmit(formData: any, formDirective: FormGroupDirective): void {

    this.submitted = true;
    if (this.addHoForm.invalid) {
      this.spinner.hide();
      console.log('invalid');
      return;
    } else {

      this.httpService.save(this.addHoForm.value, environment.BASE_URL + ApplicationURIConstants.CHECK_ADDRESS_ASSIGNED_OR_NOT)
        .subscribe((response) => {
          // console.log(response)
          this.spinner.hide();
          if (response.status == 200) {
            if (response.data == true) {
              this.addressAssinged = true;
              $('#confirmationModal').modal('show');
            } else {
              this.addressAssinged = false;
              this.addSingleHomeOwner();
            }
          } else if (response.status == 401) {
            this.spinner.hide();
            this.router.navigate(['logout']);
          }
        });
    }
  }

  modalClick() {
    $('#confirmationModal').modal('hide');
    this.addressAssinged = false;
    this.addSingleHomeOwner();
  }

  addSingleHomeOwner() {
    if (!this.addressAssinged) {
      this.spinner.show();
      this.httpService.save(this.addHoForm.value, this.assignHOUrl)
        .subscribe((res) => {
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              this.spinner.hide();
              setTimeout(() => {
                this.router.navigate(['homes']);
              }, 700);  //2s

            } else if (res.status == 401) {
              this.spinner.hide();
              this.router.navigate(['logout']);
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.spinner.hide();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
          }
        });
    }
  }

  onExcelSubmit() {
    this.submitted = true;
    if (this.addHoExcelForm.invalid) {
      console.log('invalid');
      return;
    } else {

      this.spinner.show();
      var formData = new FormData();
      // console.log("-------")
      // Add multiple file in formData
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("file", this.fileUploadList[i]);
        // console.log("===" + this.fileUploadList[i])
      }
      // console.log("-=======----")
      // http service method call
      this.httpService.save(formData, this.hoAddExcelUrl)
        .subscribe((res) => {
          // console.log(";;;;;")
          if (res != undefined) {
            // console.log("++++")
            this.spinner.hide();
            this.resData = new AppResponse(res);
            // console.log('resData : ' + JSON.stringify(this.resData));
            if (res.status == 200) {
              this.submitted = false;
              this.addHoForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['homes']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.addHoExcelForm.controls['file'].setValue(null);
              this.flag = false;
              this.router.navigate(['homeowner-add']);
              // code added by aishwaryaa for hb-471 start
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.errors}`);
              // code end 
            }
          } else {
            this.addHoExcelForm.controls['file'].setValue(null);
            this.flag = false;
            this.router.navigate(['homeowner-add']);
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
          }
        });
    }
  }


}
