import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { AppRegExConstants } from '../../utils/AppRegExConstants';


@Component({
  selector: 'app-group-email-verification',
  templateUrl: './group-email-verification.component.html',
  styleUrls: ['./group-email-verification.component.css']
})
export class GroupEmailVerificationComponent implements OnInit {

  token: String;
  appResponse: AppResponse;
  resData: AppResponse;
  resetPassword : Boolean;
  emailVerificationStatus : String;
  belowContent: String;
  iconVerify: Boolean;
  wrongIconVerify: Boolean;
  

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private applicationUtils : ApplicationUtils, private formBuilder: FormBuilder, private commonService: CommonService) { }
  resetPwdForm: FormGroup;
  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.token = params.token;
    });

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_EMAIL_TOKEN_GA + this.token).subscribe(data => {
      console.log("dataaaaaaaaaa"+ JSON.stringify(data) );
      if(data.message == 'success'){
        this.emailVerificationStatus = "Your Email verified Successfully";
        this.belowContent="You're now able to login";
        this.iconVerify = true;
        console.log("iffffffffffffffffffffffffff" + this.iconVerify);
        
      }
      else{
        this.emailVerificationStatus = "Your Email verified Link Expired !";
        this.belowContent="You're not able to login";
        this.wrongIconVerify = true;
        
      }

    });
  }

}
