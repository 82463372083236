import { Component, OnInit } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';

@Component({
  selector: 'app-group-due-view',
  templateUrl: './group-due-view.component.html',
  styleUrls: ['./group-due-view.component.css']
})
export class GroupDueViewComponent implements OnInit {

  groupDueId : string;n
  appResponse: AppResponse;
  resData: AppResponse;
  isEditable:Boolean;
  editText:String;


  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {}

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.groupDueId = params.id;
    });
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_SINGLE_DUE + this.groupDueId).subscribe(data => {
      if(data.status === 200){
        this.appResponse = new AppResponse(data);
        console.log(this.appResponse);
        
        this.isEditable=!this.appResponse.data.editable;
        if(this.isEditable){
          this.editText="Due has already been paid by a homeowner. Editing is not allowed for this particular due "
        }else{
          this.editText="Edit"
        }

      }
      
      if (data.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });
  }


}
