import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-audit-list-sd',
  templateUrl: './audit-list-sd.component.html',
  styleUrls: ['./audit-list-sd.component.css'],
  providers: [NgxSpinnerService]

})
export class AuditListSdComponent implements OnInit {

  reqData: any;
  auditForm: FormGroup;
  resData: AppResponse;
  refId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.activatedRoute.params.subscribe((params) => {
      this.refId = params.id;
    });
    // console.log("REFID:" + this.refId);
    this.auditForm = this.formBuilder.group({
      refid: [this.refId, [Validators.required]],
      reftable: ["SD", [Validators.required]],
    });
    this.getAuditList();
  }

  getAuditList() {
    this.httpService
      .save(
        this.auditForm.value,
        environment.BASE_URL + ApplicationURIConstants.GET_AUDIT_LIST_SD
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));
          // console.log("========"+JSON.stringify(res.data));

          if (res.status == 200) {
            this.resData = new AppResponse(res);
            setTimeout(() => {
              $('#auditDataTable').DataTable({
                responsive: true,
                autoWidth: true,
                searching: true,
                /*"ordering": true,*/
                order: [],
                language: {
                  zeroRecords: 'No records found.',
                },
                lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
                pageLength: 5,
                pagingType: $(window).width() < 768 ? "full" : "full_numbers",
                processing: true,
                columnDefs: [{
                  targets: 3,
                  orderable: false
                },
              ]
              });
            }, 200);
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });
      this.auditForm = this.formBuilder.group({
        refId: [this.refId, [Validators.required]],
        refTable: ["SD", [Validators.required]],
      });
  }

}
