import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { AppResponse } from 'src/app/model/AppResponse';
import { environment } from 'projects/group-portal/src/environments/environment';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Component({
  selector: 'app-group-due-list',
  templateUrl: './group-due-list.component.html',
  styleUrls: ['./group-due-list.component.css']
})
export class GroupDueListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  appResponse:AppResponse;
  trail:number;
  showpaybtn:boolean=false;
  deleteResData: AppResponse;


  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private router: Router, private commonService: CommonService, private tokenService: TokenStorageService) {
  }

  ngOnInit() {

    // code added by aishwaryaa for hb-973
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_GROUP_SUBSCRIPTION_DETAILS).subscribe(data => {

     
      // console.log(data);
      // this.resData = new AppResponse(data);
      // console.log(this.resData);

      if (data.status === 401) {
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (data.status === 403) {
        //  this.router.navigate(['home']);
      } else if (data.status === 200) {

        this.appResponse = new AppResponse(data);
        this.trail=this.appResponse.data[0].isTrail;
        console.log(this.appResponse.data[0].isTrail);
        if(this.trail==1){

          this.showpaybtn=true;
          console.log(this.showpaybtn);
          
  
        }else{
          this.showpaybtn=false;
          console.log(this.showpaybtn);
          
        }
     
      }

     
      
    });


  }


  ngAfterViewInit(): void {
    // console.log("1");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_DUES).subscribe(res => {
      // console.log("2");
      // console.log(JSON.stringify(res));
      this.resData = new AppResponse(res);
      // console.log(JSON.stringify(this.resData.data));
      // console.log(this.resData);
      console.log(this.resData.data);
      // added by gopal for HB-701 on 28/12/2021
      setTimeout(() => {
        $('#GroupDueDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          stateSave: true,//this line added by aishwaryaa for hb-542
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],//this line modified by aishwaryaa for hb-542
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: false,
          columnDefs: [{
            targets: 5,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        // console.log("401");
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        // console.log("403");
        this.router.navigate(['home']);
      }
    });

  }

  private deleteDue= environment.BASE_URL + ApplicationURIConstants.DELETE_DUE;
  public deleteDueData(data: any) {
    console.log("DATA:" + data);
    if(confirm("Are you sure you want to delete this Due ?")){
      this.httpService.update(data.groupDueId,this.deleteDue + data.groupDueId)

 
      
        .subscribe((data) => {
          this.deleteResData = new AppResponse(data);
          console.log(data);
          if (this.deleteResData.status == 200) {
            //this.submitted = false;
            $('#GroupDueDataTable').dataTable().fnDestroy();

            $('.close').click();
            // console.log("MSG:" + this.deleteResData.message)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, "Due has been deleted successfully");

            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);

          } else {

            console.log("ERROR");
            
            // console.log(this.deleteResData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${"ERROR"}`);
          }
        });
    }

  }

  }



