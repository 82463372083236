import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import * as $ from 'jquery';
import { environment } from 'projects/group-portal/src/environments/environment';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-by-laws-add',
  templateUrl: './by-laws-add.component.html',
  styleUrls: ['./by-laws-add.component.css']
})

export class ByLawsAddComponent implements OnInit {

  byLawsForm: FormGroup;
  resData: AppResponse;
  submitted = false;
  private addByLaws = environment.BASE_URL + ApplicationURIConstants.ADD_BY_LAWS;
  multiplebylaw: boolean=false;
  BylawExcelForm: FormGroup;
  flag: boolean;
  fileUploadList: string[] = [];
  excelfilename: any;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private spinner: NgxSpinnerService) { }

  private BylawExcelUrl = environment.BASE_URL + ApplicationURIConstants.ADD_BYLAW_EXCEL;
  ngOnInit() {

    this.byLawsForm = this.formBuilder.group({
      "multiple":[''],
      "byLawsTopic": ['', Validators.required],
      "description": ['', Validators.required],
      "byLawsType": ['', Validators.required],
      "sendbylaw":[false]
    });

    this.BylawExcelForm = this.formBuilder.group({
      "file": ['', [Validators.required]],
    });

  }

  get bylaws() { return this.byLawsForm.controls; }
  get BylawExcelForms() { return this.BylawExcelForm.controls; }

  onSubmit() {
    this.submitted = true;
   
      // console.log("BYLAWSFORM:" + JSON.stringify(this.byLawsForm.value));
      if (this.byLawsForm.invalid) {

        // console.log("lohlasd.asnflnalskfohloh");
        return;
      } else {

        console.log("lohlohloh");
        
      // http service method call
      this.httpService.save(this.byLawsForm.value, this.addByLaws)
        .subscribe((res) => {
          // console.log("INSIDE THE FIRST");
          if (res != undefined) {
            this.resData = new AppResponse(res);
            // console.log("RESPONSE:" + JSON.stringify(this.resData));
            if (res.status == 200) {
              // console.log("INSIDE THE SECOND");
              this.submitted = false;
              this.byLawsForm.reset();
              $("#category").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['byLaws-list']);
              }, 2000);  //15s
            } else if (res.status == 401) {
              // console.log("INSIDE THE THIRD");
              this.router.navigate(['login']);
            } else {
              // console.log("INSIDE THE FOURTH");
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  onFileSelect(event) {
    console.log("hi hello ");
    console.log(event.target.files.length);
    console.log(event.target.files);
    
    
    
    // this.addHoExcelForm.controls['file'].setValue(null);

    this.flag = true;
    this.fileUploadList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.fileUploadList.push(event.target.files[i]);
    }
//added by gopal for HB-449 on 14/12/2021
    if (event.target.files.length > 0) {
      this.excelfilename = event.target.files[0].name;
      // console.log(event.target.files[0].name);
    }
  }


  sendbylaw(){
console.log(this.byLawsForm.controls['sendbylaw'].value);

    
  }

  singleandmultiple(){
    console.log(this.byLawsForm.controls['multiple'].value);
    
    if(this.byLawsForm.controls['multiple'].value=='SINGLE'){
      this.multiplebylaw=false;
      console.log(this.multiplebylaw);
      
    }else if(this.byLawsForm.controls['multiple'].value=='MULTIPLE'){
      this.multiplebylaw=true;
      console.log(this.multiplebylaw);
    }else{
      this.multiplebylaw=false;
      console.log(this.multiplebylaw);
      
    }
    
  }
  TableInitialiseexport() {
    $('#reportTable').DataTable({
      "bPaginate": false,
      "bLengthChange": false,
      "bFilter": false,
      "bInfo": false,
      "bAutoWidth": false

    });
  }
  
  fileName = 'SampleTemplate.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    $('#reportTable').dataTable().fnDestroy();
    setTimeout(() => {
      this.TableInitialiseexport();
      this.spinner.hide();
    }, 2000);
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'][5] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
   download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], {type: fileType});
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  }
  onExcelSubmit() {

    console.log("inside Excel submit");
    
    this.submitted = true;
    if (this.BylawExcelForm.invalid) {
      console.log('invalid');
      return;
    } else {
      console.log("inside else in excel submit ");
      

      this.spinner.show();
      var formData = new FormData();
      for (var i = 0; i < this.fileUploadList.length; i++) {
        formData.append("file", this.fileUploadList[i]);
        
      }
      this.httpService.save(formData, this.BylawExcelUrl)
        .subscribe((res) => {
          console.log(res)
          if (res != undefined) {
            // console.log("++++")
            this.spinner.hide();
            this.resData = new AppResponse(res);
            // console.log('resData : ' + JSON.stringify(this.resData));
            if (res.status == 200) {
              this.submitted = false;
              
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['byLaws-list']);
              }, 5000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['logout']);
            } else {
              this.BylawExcelForm.controls['file'].setValue(null);
              this.flag = false;
              this.router.navigate(['bylaws-add']);
   
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.errors}`);
            
            }
          } else {
            this.BylawExcelForm.controls['file'].setValue(null);
            this.flag = false;
            this.router.navigate(['bylaws-add']);
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
          }
        });
    }
  }


}