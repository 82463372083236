import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-group-cr-view',
  templateUrl: './group-cr-view.component.html',
  styleUrls: ['./group-cr-view.component.css'],
  providers: [NgxSpinnerService]
})
export class GroupCrViewComponent implements OnInit {

  complianceRequestId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  generalCommentForm: FormGroup;
  // formBuilder: any;
  refId: any;
  generalCommentList: any;
  userprofileInfoId:any;
   updategeneralCommentForm: FormGroup;
  

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService,
    private commonService: CommonService,
    private router: Router, 
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.complianceRequestId = params.id;
      this.refId=this.complianceRequestId;
      // console.log(this.refId);
      
    });
    // tslint:disable-next-line: max-line-length
    // console.log("complianceRequestId -> " + this.complianceRequestId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_COMPLIANCE_REQUESTS + "/" + this.complianceRequestId).subscribe(data => {
      console.log(JSON.stringify(data));
      this.spinner.hide();
      if (data.status === 200) {
        this.appResponse = new AppResponse(data);
        console.log();
        
      }else if (data.status === 401) {
        //401 token related issue
       this.tokenService.clearSession();
       this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
         this.router.navigate(['home']);
      }
    });
    this.userprofileInfoId = this.tokenService.getuserProfileId();
      this.generalCommentForm = this.formBuilder.group({
        refId: [this.refId, [Validators.required]],
        refTable: ["CR", [Validators.required]],
        comment: ["", [Validators.required]],
      });
    this.getComment();
  }

  getComment() {
    this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.GET_GROUP_GENERAL_COMMENT).subscribe(res => {
      this.spinner.hide();
      if (res == undefined) {
        this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
      } else {
        // console.log(JSON.stringify(res));

        if (res.status == 200) {
          this.resData = new AppResponse(res);
          this.generalCommentList = this.resData.data;
          console.log(this.resData.data);
          console.log(typeof(this.resData.data[0].editComment)+"edit comment");
          console.log(typeof(this.resData.data[0].createdBy)+"created by ");
          console.log(typeof(this.resData.data[0].isEditApplicable)+"edit applicable");
          
          
          

          
        } else if (res.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      }
    });
    this.generalCommentForm = this.formBuilder.group({
      'refId': [this.refId, [Validators.required]],
      'refTable': ['CR', [Validators.required]],
      'comment': ['', [Validators.required]],
      'generalCommentId':["",]
    });

    this.updategeneralCommentForm = this.formBuilder.group({
      refId: [this.refId, [Validators.required]],
      refTable: ["CR", [Validators.required]],
      comment: ["", [Validators.required]],
      editComment:["",],
      generalCommentId:["",]
    });
  }

  get f() { return this.generalCommentForm.controls; }
  
  onSubmitcomment() {


    console.log(environment.BASE_URL);
    console.log(ApplicationURIConstants.ADD_GROUP_GENERAL_COMMENT);
    console.log("passssss");
    console.log(this.generalCommentForm.value);
    // return
    
    this.spinner.show();
    console.log(JSON.stringify(this.generalCommentForm.value));
    if (this.generalCommentForm.invalid) {
      console.log("invalid")
      return;
    } else {
      console.log(JSON.stringify(this.generalCommentForm.value));
      this.httpService.save(this.generalCommentForm.value, environment.BASE_URL + ApplicationURIConstants.ADD_GROUP_GENERAL_COMMENT).subscribe(res => {
        this.spinner.hide();
        if (res == undefined) {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.SERVER_CONNECTION_PROBLEM);
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {

            console.log("vickyyyyyyyyyyyyyyyyyyyyyyyyy");
            
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(['logout']);
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        }
      });
    }

  }
    textvisible(generalCommentId){
      console.log("HIHIHIHIHihIHIHIHHJI");
      
    for(var i=0; i<this.generalCommentList.length; i++){
      if(this.generalCommentList[i].generalCommentId == generalCommentId){
        this.generalCommentList[i].editComment = 1;
      }
    }

  }

  saveComment(generalComment){

    this.updategeneralCommentForm.controls['comment'].setValue($("#editedComment").val());
    this.updategeneralCommentForm.controls['editComment'].setValue(false);
    this.updategeneralCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);

    // console.log("pppppppp"+JSON.stringify(this.updategeneralCommentForm.value));
    if (this.updategeneralCommentForm.invalid) {
      console.log("invalid");
      return;
    } else {
      // console.log(JSON.stringify(this.updategeneralCommentForm.value));

      this.httpService
        .update(
          this.updategeneralCommentForm.value,
          environment.BASE_URL + ApplicationURIConstants.UPDATE_GROUP_GENERAL_COMMENT
        )
        .subscribe((res) => {
          if (res == undefined) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              ApplicationConstants.SERVER_CONNECTION_PROBLEM
            );
          } else {
            // console.log(JSON.stringify(res));

            if (res.status == 200) {
              // this.resData = new AppResponse(res);
              this.getComment();
            } else if (res.status == 401) {
              this.router.navigate(["logout"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          }
        });
    }
    
  }
  deleteCommentById(generalComment){


    this.generalCommentForm.controls['generalCommentId'].setValue(generalComment.generalCommentId);
    // console.log(JSON.stringify(this.generalCommentForm.value));
    if(confirm("Are you sure you want to delete comment")){
      this.httpService
      .update(
        this.generalCommentForm.value,
        environment.BASE_URL + ApplicationURIConstants.DELETE_GROUP_GENERAL_COMMENT
      )
      .subscribe((res) => {
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          // console.log(JSON.stringify(res));

          if (res.status == 200) {
            // this.resData = new AppResponse(res);
            this.getComment();
          } else if (res.status == 401) {
            this.router.navigate(["logout"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        }
      });

    }
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }
}
