import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationUtils } from 'src/app/utils/ApplicationUtils';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/group-portal/src/environments/environment';
import { Crisp } from "crisp-sdk-web";
declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 constructor(private router: Router, private formBuilder: FormBuilder, private applicationUtils : ApplicationUtils, private authService: AuthService, private tokenService: TokenStorageService, private route: ActivatedRoute ,private http:HttpClient) {
  if (this.tokenService.getToken()) {
      this.router.navigate(['phoneverify']);
    }
  }

  get f() { return this.loginForm.controls; }

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  env=environment.env;
  captchaResponse: string;
  flag : boolean = false;
  loginCount: number = 1 ;

  // URL
  private signInUrl = environment.BASE_URL + ApplicationURIConstants.SIGN_IN;

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  loginForm: FormGroup;

  submitted = false;

  ngOnInit() {
 
    $(document).ready(() => {
      $('body').css('background-color', '#eee');
    });
    this.loginForm = this.formBuilder.group({
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "password": ['', [Validators.required, Validators.pattern(AppRegExConstants.PASSWORD)]],
    });
    //this.addRecaptchaScript();
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      if ( this.loginCount > 3 && this.captchaResponse == null ){
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Please Fill all the details.");
      }

      if(this.flag){
        return this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "Captcha has expired. Please check the checkbox again.");
      }
      this.authService.signIn(this.signInUrl, this.loginForm.value)
        .subscribe((appResponse) => {
          if (appResponse.status == 200 && appResponse.message == ApplicationConstants.SUCCESS_LABLE) {

            console.log(appResponse.data.fullName);

            // crisp.push(["set", "user:email", "user@gmail.com"]);
            Crisp.configure("b0f63b1d-a465-4484-8ec0-9394f4c0ad63", {
              autoload: false
            });
            Crisp.load()
            Crisp.chat.show()

            Crisp.user.setEmail(appResponse.data.email);
            Crisp.user.setNickname(appResponse.data.fullName);
            setTimeout(() => {
              this.tokenService.saveUserTokenData(appResponse.data);
              // console.log(appResponse.data.isphoneverified);

              if(appResponse.data.isphoneverified){
                this.router.navigate(['home']);
              }else{

                this.router.navigate(['phoneverify']);

              }

            }, 1000);
          } else {
            // tslint:disable-next-line: max-line-length
            this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, appResponse.errors);

            this.loginCount = this.loginCount + 1;
            if( this.loginCount === 4 ){
              this.addRecaptchaScript();
            }
          }
        }, error => {
            // tslint:disable-next-line: max-line-length
            this.applicationUtils.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
            this.errorMessage = error.error.message;
            this.isLoginFailed = true;
        });
    }
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : environment.ReCaptcha,
      'callback': (response) => {
          this.flag = false;
          this.captchaResponse = response;
      },
      'expired-callback': () => {
        this.flag = true;
       }
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }
togglePassword(id, passId)
  {
          var passwordInput = document.getElementById(passId);
          var passid = document.getElementById(id);

          // console.log("pass"+passwordInput);

              if (passwordInput.getAttribute('type')  == 'password')
              {
                  passwordInput.setAttribute('type','text');
                  $(passid).toggleClass("fa-eye fa-eye-slash");
              }
              else
              {
                passwordInput.setAttribute('type','password');
                $(passid).toggleClass("fa-eye fa-eye-slash");

              }


  }
}
