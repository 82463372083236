import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppResponse } from 'src/app/model/AppResponse';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import * as FileSaver from 'file-saver';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/group-portal/src/environments/environment';
import { CommonService } from 'projects/user-portal/src/app/utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sd-view',
  templateUrl: './sd-view.component.html',
  styleUrls: ['./sd-view.component.css'],
  providers: [NgxSpinnerService]
})
export class SdViewComponent implements OnInit {

  sharedDocumentId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  fileNameContentType: string;
  listingPageUrl: String;

  constructor(private activatedRoute: ActivatedRoute, 
    private httpService: HttpTransactionService,
    private commonService: CommonService, 
    private router: Router,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.activatedRoute.params.subscribe(params => {
      // console.log("activatedRoute -> " + params.id);
      this.sharedDocumentId = params.id;
    });
    // tslint:disable-next-line: max-line-length
    // console.log("sharedDocumentId -> " + this.sharedDocumentId);
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.VIEW_SHARED_DOCUMENT+ this.sharedDocumentId).subscribe(data => {
      // console.log(JSON.stringify(data));
      this.spinner.hide();
      if (data.status === 200) {
        this.appResponse = new AppResponse(data);

        if(this.appResponse.data.isArchived == 'yes'){
            this.listingPageUrl=ApplicationURIConstants.LISTING_ARCHIVED_SHARED_DOCUMENT;          
        } else{
          this.listingPageUrl=ApplicationURIConstants.LISTING_SHARED_DOCUMENT;  
        } 
      }else if (data.status === 401) {
        //401 token related issue
       this.tokenService.clearSession();
       this.router.navigate(['login']);
      } else if (data.status === 403) {
        //403 URL not accessible
         this.router.navigate(['home']);
      }
    });
  }

  // get the document of byte array.
  getDocument(documentName: string) {
    this.httpService.get(environment.BASE_URL +
      ApplicationURIConstants.GET_SD_FILE + '?documentName=' + documentName).subscribe(data => {
        this.resData = new AppResponse(data);
        // console.log(JSON.stringify(data));
        if (this.resData.status == 200) {
          //Start the code for Base64 to byte[]
          const byteCharacters = atob(this.resData.data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(this.resData.data);

          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          const blob = b64toBlob(this.resData.data, this.fileNameContentType);
          //End the code for Base64 to byte[]
          //Save the file
          FileSaver.saveAs(blob, documentName.substr(14));
        } else if (this.resData.status == 401) {
          this.router.navigate(['logout']);
        } else {
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
        }
      });
  }

}
