import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "projects/system-portal/src/app/utils/common-service";
import * as $ from "jquery";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import { environment } from "projects/group-portal/src/environments/environment";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TokenStorageService } from "../../auth-token/services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-edit-payment-config",
  templateUrl: "./edit-payment-config.component.html",
  styleUrls: ["./edit-payment-config.component.css"],
  providers: [NgxSpinnerService],
})
export class EditPaymentConfigComponent implements OnInit {
  groupDueId: string;
  dueRequestForm: FormGroup;
  dueRequestFormForSingleUSer: FormGroup;
  dueRequestFormCustomType: FormGroup;
  resData: AppResponse;
  appResponse: AppResponse;
  submitted = false;
  customDueTypeEnabled: Boolean = false;
  private editGroupDue =
    environment.BASE_URL + ApplicationURIConstants.EDIT_GROUP_DUE;

  flag: boolean;
  rongHo: boolean;
  fileUploadList: string[] = [];
  responseData: AppResponse;
  public min = new Date();

  ByLawsList: any[] = [];
  homeAddressList = [];
  homesList: any[] = [];
  isChecked: Boolean;
  isSingleUserVisible: Boolean=false;
   isAllUserVisible: Boolean=false;

  filteredOptions: Observable<String[]>;
  filteredHomes: Observable<String[]>;

  byLawsTopic = new FormControl("");
  homeAddress = new FormControl("", [Validators.required]);
  showamounterr: boolean;

  @ViewChild('auto', { static: true }) auto: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService
  ) {}

  ngOnInit() {
    var date = new Date();
    var datetemp = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.min = datetemp;
    this.spinner.show();

    

  //Forms
    this.dueRequestForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      amount: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(AppRegExConstants.DUE_AMOUNT),
        ],
      ],
      homeProfileId: [""],
      dueDate: ["", [Validators.required]],
      dueFrequency: ["", [Validators.required]],
      dueType: ["", [Validators.required]],
      description: [],
      isForSingleUser: [""],
      userProfileInfoId: [""],
      customDueTypeName: [""],
      iseditable:[true]
    });

    this.dueRequestFormForSingleUSer = this.formBuilder.group({
      homeAddress: ["", [Validators.required]],
      HoDetails: [""],
    });

    this.dueRequestFormCustomType = this.formBuilder.group({
      customDueTypeNameInput: ["", [Validators.required]],
    });
  
 

    $("#amount").bind("cut copy paste", function (e) {
      e.preventDefault();
    });

   
  }

  get dues() {
    return this.dueRequestForm.controls;
  }

  get duesForSingleUSer() {
    return this.dueRequestFormForSingleUSer.controls;
  }

  get duesForCustomDueType() {
    return this.dueRequestFormCustomType.controls;
  }

  onSubmit() {
    console.log(this.dueRequestForm.value);


    if(!this.dueRequestForm.controls["homeProfileId"].value && (this.dueRequestForm.controls["dueType"].value =="CUSTOMDUE" || this.dueRequestForm.controls["dueType"].value =="PASTDUES")){
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        "Please Provide Home Address"
      );
      // this.ngAfterViewInit();
      this.gethomes();
return;
    }

    // if(!this.dueRequestForm.controls["homeProfileId"].value && this.dueRequestForm.controls["dueType"].value =="SPECIALASSESMENT" && this.dueRequestForm.controls["isForSingleUser"].value){
      if(!this.dueRequestForm.controls["homeProfileId"].value && this.dueRequestForm.controls["dueType"].value =="SPECIALASSESMENT" && this.dueRequestForm.controls["isForSingleUser"].value =="SPECIALASSESMENT" &&this.dueRequestForm.controls["isForAllUser"].value){
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        "Please Provide Home Address"
      );  
      
      // this.ngAfterViewInit();
      this.gethomes();
return;
    }
    
    
    if (this.dueRequestForm.controls["amount"].value.length > 8 ) {
      console.log("false");
      this.showamounterr = true;
      return;
    } else {
      this.showamounterr = false;
    }
    this.spinner.show();
    this.submitted = true;
    if (this.dueRequestForm.invalid || this.showamounterr ) {
      this.spinner.hide();
      return;
    } else {
      //      // http service method call
      if (this.dueRequestForm.controls["dueType"].value == "CUSTOMDUE") {
        this.dueRequestForm.controls["customDueTypeName"].setValue(
          this.dueRequestFormCustomType.controls["customDueTypeNameInput"].value
        );
      }

      console.log(this.dueRequestForm.value);

this.dueRequestForm.controls["iseditable"].setValue(true)
      this.httpService
        .update(
          this.dueRequestForm.value,
          this.editGroupDue + "/" + this.groupDueId
        )
        .subscribe((res) => {
          this.spinner.hide();
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.spinner.hide();
              this.submitted = false;
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                `${this.resData.message}`
              );
              setTimeout(() => {
                this.router.navigate(["groupDues"]);
              }, 3000);
            } else if (res.status == 401) {
              this.router.navigate(["login"]);
            } else {
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.DANGER_LABLE,
                `${this.resData.errors}`
              );
            }
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        });
    }
  }

  selectHoAddressChangeHandler(selectedHome) {
    for (var i = 0; i < this.homesList.length; i++) {
      if (this.homesList[i].homeprofileid == selectedHome.homeprofileid) {
        this.dueRequestForm.controls["homeProfileId"].setValue(
          this.homesList[i].homeprofileid
        );
        this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(
          this.homesList[i].firstname + " " + this.homesList[i].lastname
        );
        this.dueRequestForm.controls["userProfileInfoId"].setValue(
          this.homesList[i].userProfileInfoId
        );
      }
    }
  }

  
  private _filterForHomes(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.homesList.filter((option, index, self) => 
      index === self.findIndex((t) => (
        t.homeprofileid === option.homeprofileid && t.addressline1 === option.addressline1
      )) && option.addressline1.toLowerCase().includes(filterValue)
    );
  }
  displayFn(home: any): string {
    if (home.homeprofileid == undefined) {
      return;
    } else {
      return home.addressline1;
    }
  }

  onKeyUpForHomeAddress(event: any) {
    // console.log(event.target.value);
    if (event.target.value.length <= 0) {
      this.rongHo = false;
      this.homeAddress.setErrors({ required: true });
    } else {
      this.rongHo = false;
      for (var i = 0; i < this.homesList.length; i++) {
        if (this.homesList[i].addressline1 == event.target.value) {
          this.dueRequestForm.controls["homeProfileId"].setValue(
            this.homesList[i].homeprofileid
          );
          this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(
            this.homesList[i].firstname + " " + this.homesList[i].lastname
          );
          this.dueRequestForm.controls["userProfileInfoId"].setValue(
            this.homesList[i].userProfileInfoId
          );
          this.homeAddress.setErrors(null);
          this.rongHo = false;
          break;
        } else {
          this.dueRequestForm.controls["homeProfileId"].setValue(null);
          this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(null);
          this.dueRequestForm.controls["userProfileInfoId"].setValue(null);
          this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;
        }
      }
    }
  }

  

  changeType(event: any) {
    // this.ngAfterViewInit();
    this.gethomes();
    this.customDueTypeEnabled = false;
    this.isChecked = false;
    this.isSingleUserVisible = false;
    this.dueRequestForm.controls["isForSingleUser"].setValue(false);

    // this.dueRequestForm.controls["userProfileInfoId"].setValue(null);
    // this.dueRequestForm.controls["homeProfileId"].setValue(null);
    this.dueRequestForm.controls["customDueTypeName"].setValue(null);
    // this.homeAddress.setValue(null);
    // this.dueRequestFormForSingleUSer.controls["homeAddress"].setValue(null);
    // this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(null);
    this.dueRequestFormCustomType.controls["customDueTypeNameInput"].setValue(
      null
    );
    this.homeAddress.setErrors(null);
    this.rongHo = false;

    $("#dueFrequency").prop("disabled", false);
    $("#dueFrequency").val("");

    if (event.target.value == "PASTDUES") {
      this.isChecked = true;
      this.isSingleUserVisible = false;
      console.log(this.dueRequestFormForSingleUSer.controls['homeAddress'].value + "check" );

      // this.auto.nativeElement.value='';
      
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
   
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
    } else if (event.target.value == "CUSTOMDUE") {
      this.isChecked = true;
      this.isSingleUserVisible = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
    } else if (event.target.value == "FINE") {
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
    } else if (event.target.value == "SPECIALASSESMENT") {
      this.customDueTypeEnabled = true;
      this.isSingleUserVisible = true;
      this.dueRequestForm.controls["dueFrequency"].setValue("");
    }else if (event.target.value == "REGULARDUE") {
      
      this.dueRequestForm.controls["dueFrequency"].setValue("");
    }else{
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
    }
  }

  gethomes(){
    this.httpService
      .get(environment.BASE_URL + ApplicationURIConstants.GET_ALL_HOMES)
      .subscribe((res) => {
        this.spinner.hide();
        this.responseData = new AppResponse(res);
        if (res == undefined) {
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            ApplicationConstants.SERVER_CONNECTION_PROBLEM
          );
        } else {
          if (res.status == 200) {
            for (
              let index = 0;
              index < this.responseData.data.length;
              index++
            ) {
              this.homesList.push(this.responseData.data[index]);
              this.homeAddressList.push(
                this.responseData.data[index].addressline1
              );
            }
            //  console.log("homesList:"+JSON.stringify(this.homesList));
            //  console.log("homeAddressList:"+JSON.stringify(this.homeAddressList));
          } else if (res.status === 401) {
            this.router.navigate(["login"]);
          } else if (res.status == 403) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${res.errors}`
            );
            this.router.navigate(["home"]);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.responseData.errors}`
            );
          }
        }
      });

      this.filteredHomes = this.homeAddress.valueChanges.pipe(
        startWith(""),
        map((value) => (value.length >= 3 ? this._filterForHomes(value) : []))
      );
  }
  ngAfterViewInit() {

    this.gethomes();
    //get groupdue details
    this.activatedRoute.params.subscribe((params) => {
      this.groupDueId = params.id;
    });
    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.GET_SINGLE_DUE +
          this.groupDueId
      )
      .subscribe((data) => {
        this.appResponse = new AppResponse(data);

        if (data.status === 200) {
          this.fillTextData();
        } else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });
  }

  amountchange($event) {
    console.log($event.target.value.length);
    if ($event.target.value == 0) {
      this.showamounterr = true;
    }
    if ($event.target.value.length == 0) {
      this.showamounterr = false;
    }
  }

  


  changeFunction(value:any) {
    console.log(value);
     if ($("#isSingleUser").is(":checked")) {
       this.isChecked = true;
         this.dueRequestForm.controls["isForSingleUser"].setValue(true);
     }
     else{
       
       this.isChecked = false;
         this.dueRequestForm.controls["isForSingleUser"].setValue(false);
     }
    }

     // changeFunction() {
  //   if ($("#isSingleUser").is(":checked")) {
  //     this.isChecked = true;
  //     this.dueRequestForm.controls["isForSingleUser"].setValue(true);
  //   } else {
  //     this.isChecked = false;
  //     this.dueRequestForm.controls["isForSingleUser"].setValue(false);
  //   }
  // }

  fillTextData() {
    this.dueRequestForm.controls["title"].setValue(this.appResponse.data.title);
    this.dueRequestForm.controls["amount"].setValue(
      this.appResponse.data.amount
    );
    this.dueRequestForm.controls["dueDate"].setValue(
      new Date(this.appResponse.data.dueDate)
    );
    this.dueRequestForm.controls["dueFrequency"].setValue(
      this.appResponse.data.dueFrequency
    );
    this.dueRequestForm.controls["dueType"].setValue(
      this.appResponse.data.dueType
    );
    this.dueRequestForm.controls["description"].setValue(
      this.appResponse.data.description
    );
    this.dueRequestForm.controls["isForSingleUser"].setValue(
      this.appResponse.data.isForSingleUser
    );
  

  
    

    // if (
    //   this.appResponse.data.homeAddress != "" &&
    //   this.appResponse.data.isForSingleUser == true
    // ) 
    if (this.appResponse.data.homeAddress != "" &&
      this.appResponse.data.isForSingleUser == true) {
      for (var i = 0; i < this.homesList.length; i++) {
        if (
          this.homesList[i].addressline1 == this.appResponse.data.homeAddress
        ) {
          this.dueRequestForm.controls["userProfileInfoId"].setValue(
            this.homesList[i].userProfileInfoId
          );
          this.dueRequestForm.controls["homeProfileId"].setValue(
            this.homesList[i].homeprofileid
          );
          this.homeAddress.setValue(this.homesList[i]);
          this.dueRequestFormForSingleUSer.controls["homeAddress"].setValue(
            this.homesList[i].addressline1
          );
          this.dueRequestFormForSingleUSer.controls["HoDetails"].setValue(
            this.homesList[i].firstname + " " + this.homesList[i].lastname
          );
          this.homeAddress.setErrors(null);
          this.rongHo = false;
        }
      }
    }
 
   

    if (this.appResponse.data.dueType == "PASTDUES") {
      this.isChecked = true;
      this.isSingleUserVisible = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
    } else if (this.appResponse.data.dueType == "CUSTOMDUE") {
      this.isSingleUserVisible = false;
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
      if (this.appResponse.data.isForSingleUser == true) {
        this.isChecked = true;
        this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      } else {
        this.isChecked = false;
        this.dueRequestForm.controls["isForSingleUser"].setValue(false);
      }
    } else if (this.appResponse.data.dueType == "FINE") {
      $("#dueFrequency").val("ONETIME");
      $("#dueFrequency").prop("disabled", true);
      this.dueRequestForm.controls["dueFrequency"].setValue("ONETIME");
    } else if (this.appResponse.data.dueType == "SPECIALASSESMENT") {
      this.customDueTypeEnabled = true;
      this.isSingleUserVisible = true;
      if (this.appResponse.data.isForSingleUser == true) {
        this.isChecked = true;
        this.dueRequestForm.controls["isForSingleUser"].setValue(true);
      } else {
        this.isChecked = false;
        this.dueRequestForm.controls["isForSingleUser"].setValue(false);
      }
      this.dueRequestFormCustomType.controls["customDueTypeNameInput"].setValue(
        this.appResponse.data.customDueTypeName
      );
    } else {
      this.isChecked = false;
      this.isSingleUserVisible = false;
      this.dueRequestForm.controls["isForSingleUser"].setValue(false);
      this.customDueTypeEnabled = false;
    }

    /*const controls = this.dueRequestForm.controls;
          for (const name in controls) {
            if (controls[name].invalid) {
               console.log("Invalid" +name);
            }
          }*/
  }
}
